/*global window document console setTimeout setInterval clearInterval BABYLON scene canvas engine $ singleVideo singleVideoCues singleVideoWindows singleVideoPaths */
'use strict';

var camera,
    cameraTargets,
    cameraTarget,
    cameraBlur,
    lensEffect,
    currentPathId,
    previousPathId,
    currentVideo,
//    domeCanvas,
    domeCanvasContext,
    domeMat,
    domeMesh,
    videoIndex = 0,
    map = {},
    keyup = [],
    keydown = [],
    isIE11,
    isFirefox,
    isChrome,
    isMobile,
    isAndroid,
    isModernWebGL,
    isLowPerformance,
    isLowestPerformance,
    performanceTesting = false,
    useCanvasVideo,
    setEpisode,
    xmpLoaded = false,
    cuesReady = false,
    extraCues = {},
    steeringWheelMesh,
    clickables = [],
    pickLocation,
    directionalOverlay,
    currentImpediment,
    actionProgress,
    actionProgressBar,
    actionProgressText,
    timerEnabled = true,
    controlsEnabled = false,
    mainControlsEnabled = true,
    currentPoints = 0,
    playMode = 'normal',
    touchUIMesh,
    touchUIBGMesh,
    mirrorBlinkLeft,
    mirrorBlinkRight,
    wheelGraphicBlue,
    wheelVibrateFrameTextures,
    wheelVibrateGraphicMat,
    wheelVibrateGraphicLeft,
    wheelVibrateGraphicLeftContainer,
    wheelVibrateGraphicRight,
    wheelVibrateGraphicRightContainer,
    wheelSpinGraphic,
    wheelSpinGraphicParent;

var dev = {
    debugMode: false,
    skipIntro: function() {
        $('.overlay').addClass('hidden');
        $('.intro-overlay').hide();
        $('.control-overlay').removeClass('hidden');
        resetEpisode();
        startGame();
        startRenderLoop();
    },
    seekTo: function(path) {
//        currentVideo.pause();
        cueManager._performAction({
            type: 'seekNow',
            toPath: path
        });
    },
    log: function(input) {
        if(this.debugMode) {
//            console.log('debug ->', input);
            console.log(input);
        }
    },
    warn: function(input) {
//        if(this.debugMode) {
//            console.warn('debug ->', input);
            console.warn(input);
//        }
    },
    enableVideoControls: function() {
        $('body')
            .append(
                $('<div style="position:absolute;width:3rem;height:2rem;left:50%;bottom:0;z-index:1;background:blue;color:white;">step</div>"')
                    .click(function(){ currentVideo.play(); setTimeout(function() { currentVideo.pause(); }, 1000/30); }))
            .append(
                $('<div style="position:absolute;width:3rem;height:2rem;left:50%;margin-left:4rem;bottom:0;z-index:1;background:green;color:white">play</div>"')
                    .click(function(){ currentVideo.play(); }));
    },
    printPathObjectsFromCues: function() {
        var pathObjStr = '';
        for(var k = 0; k < singleVideoCues.length; k++) {
            var _cueName = singleVideoCues[k].name;
            var _cueNum = _cueName.substring(1);
            if(_cueName.indexOf('p') === 0) {
                pathObjStr +=
                    _cueName + ': {\n' +
                    '    onEnterCustom: function() {},\n' +
                    '    cueWatch: [ \'w' + _cueNum + '\' ]\n' +
                    '},\n' +
                    'w' + _cueNum + ': {\n' +
                    '    //' + singleVideoWindows['w' + _cueNum].triggers[0].inputs.toString() +  '\n' +
                    '},\n'
            }
        }
        this.log(pathObjStr);
    }
};

var pageUrl = window.location.href,
    setUseCanvas,
    setWebm,
    setLow,
    setLowest,
    allowStreaming,
    isLMS = window.parent.WBTApp ? true : false;

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

dev.debugMode = getUrlParameter('debug') === 'true';

dev.log('Checking query parameters');

setUseCanvas = getUrlParameter('canvas');
setWebm = getUrlParameter('webm');
setLow = getUrlParameter('low');
setLowest = getUrlParameter('lowest');
setEpisode = getUrlParameter('ep');
allowStreaming = getUrlParameter('stream') === 'true';

if(dev.debugMode) {
    dev.log('Debug mode enabled');
    $('body').addClass('debug');
    $.getScript('scripts/consoleLogger.js');
    $('.video-progress').click(function(event) {
        var toTime = currentVideo.duration * event.pageX / $(this).width();
        for(var c = 0; c < singleVideoCues.length; c++) {
            if(singleVideoCues[c].startTime <= toTime && toTime <= singleVideoCues[c].endTime) {
                dev.log('DEV: seeking to ' + toTime + ', in path ' + singleVideoCues[c].name);
                cueManager._updateCueWatch(singleVideoCues[c].name);
                cueManager._onSeekToTime(toTime);
                break;
            }
        }
    });
}

if(!allowStreaming) {
    dev.log('Streaming disabled');
    singleVideo.fileBase = singleVideo.fileBaseLocal;
}


cameraTargets = {
    center:  {      alpha: -Math.PI, beta: Math.PI / 2,  radius: 5  },
    shifter: {      alpha: -3.87,    beta: 0.8,          radius: 3  },
    touchscreen: {  alpha: -3.5115,  beta: 1.4011,       radius: .1 },
    steering: {     alpha: -3.2298,  beta: 1.1282,       radius: 3 },
    right: {        alpha: -3.9682,  beta: 1.5164,       radius: 5 },
    touchscreenRight: { alpha: -3.6923, beta: 1.3276, radius: .2 },
    touchscreenLeft: { alpha: -3.0605, beta: 1.3243, radius: .2 },
    mirrorRight: { alpha: -3.6923, beta: Math.PI / 2, radius: 3 },
    mirrorLeft: { alpha: -3.0605, beta: Math.PI / 2, radius: 3 },
    mirrorRear: { alpha: -3.71, beta: 1.75, radius: .1 }
//        touchscreen: {  alpha: -3.51,    beta: 1.338,        radius: .1 }
};

var MSBeforeSceneReady = function() {
    dev.log('Initializing meshes, before scene load');
    
    if(allowStreaming) BABYLON.Tools.CorsBehavior = 'anonymous';
    
//    var carSphere = scene.getMeshByName('Sphere');
//    carSphere.material.albedoTexture.updateURL('textures/Equirect_lowpoly.jpg');
//    carSphere.material.emissiveTexture.updateURL('scenes/Equirect_lowpoly.jpg');
//    carSphere.material.opacityTexture = new BABYLON.Texture('scenes/Equirect_lowpoly_opacity.png', scene);
//    carSphere.material.opacityTexture.updateURL('textures/Equirect_lowpoly_opacity.png');
    
    // Two meshes with alpha will overlap because of back-to-front rendering. Place in separate rendering groups
    steeringWheelMesh = scene.getMeshByName('Steering');
    steeringWheelMesh.renderingGroupId = 1;
    
    touchUIMesh = scene.getMeshByName('TouchUI');
    touchUIMesh.renderingGroupId = 3;
//    [-0.5, -0.5, 0, 0.8, -0.5, 0, 0.52, 0.32, 0, -1, 0.5, 0]
    touchUIBGMesh = BABYLON.MeshBuilder.CreatePlane('TouchUIBG', { width: 1.04, height: .64 }, scene);
    touchUIBGMesh.position = new BABYLON.Vector3(0, -.5, 2);
    var touchUIBGMeshMat = new BABYLON.StandardMaterial('TouchUIBGMat', scene);
    touchUIBGMeshMat.emissiveColor = new BABYLON.Color3(1, 1, 1);
    touchUIBGMesh.material = touchUIBGMeshMat;
    touchUIBGMesh.visibility = 0;
    touchUIBGMesh.renderingGroupId = 2;
    
    touchUIMesh.parent = touchUIBGMesh;
    
    touchUIBGMesh.setEnabled(false);
    
    touchUIMesh.actionManager = new BABYLON.ActionManager(scene);

    var touchUIMeshOffAction = new BABYLON.CombineAction(
        {
            trigger: BABYLON.ActionManager.NothingTrigger
        },
        [
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh, "visibility", 0, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.position, "y", -1, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIMesh, "visibility", 0, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.scaling, "x", 1, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.scaling, "y", 1, 400),
        ]
    );
    var touchUIMeshOnAction = new BABYLON.CombineAction(
        {
            trigger: BABYLON.ActionManager.NothingTrigger
        },
        [
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh, "visibility", .5, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.position, "y", -.5, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIMesh, "visibility", 1, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.scaling, "x", 1, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.scaling, "y", 1, 400),
        ]
    );
    var touchUIMeshOffCompactAction = new BABYLON.CombineAction(
        {
            trigger: BABYLON.ActionManager.NothingTrigger
        },
        [
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh, "visibility", 0, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.position, "y", -1, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIMesh, "visibility", 0, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.scaling, "x", .8, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.scaling, "y", .8, 400),
        ]
    );
    var touchUIMeshOnCompactAction = new BABYLON.CombineAction(
        {
            trigger: BABYLON.ActionManager.NothingTrigger
        },
        [
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh, "visibility", .5, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.position, "y", -.55, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIMesh, "visibility", 1, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.scaling, "x", .8, 400),
            new BABYLON.InterpolateValueAction(BABYLON.ActionManager.NothingTrigger, touchUIBGMesh.scaling, "y", .8, 400),
        ]
    );

    touchUIMesh.actionManager.registerAction(touchUIMeshOffAction);
    touchUIMesh.actionManager.registerAction(touchUIMeshOnAction);
    touchUIMesh.actionManager.registerAction(touchUIMeshOffCompactAction);
    touchUIMesh.actionManager.registerAction(touchUIMeshOnCompactAction);

    
    mirrorBlinkLeft = scene.getMeshByName('MirrorBlinkLeft');
    mirrorBlinkRight = scene.getMeshByName('MirrorBlinkRight');
    mirrorBlinkLeft.renderingGroupId = 1;
    mirrorBlinkRight.renderingGroupId = 1;
    mirrorBlinkLeft.visibility = 0;
    mirrorBlinkRight.visibility = 0;
    
    wheelVibrateGraphicLeft = scene.getMeshByName('WheelVibrateGraphicLeft');
    wheelVibrateGraphicLeftContainer = scene.getMeshByName('WheelVibrateGraphicLeftContainer');
    wheelVibrateGraphicRight = scene.getMeshByName('WheelVibrateGraphicRight');
    wheelVibrateGraphicRightContainer = scene.getMeshByName('WheelVibrateGraphicRightContainer');
    wheelSpinGraphic = scene.getMeshByName('WheelSpinGraphic');
    wheelSpinGraphicParent = scene.getMeshByName('WheelSpinGraphicParent');
    
    wheelGraphicBlue = new BABYLON.Color3(90/255,110/255,255/255);
    
    wheelVibrateGraphicLeft.renderingGroupId = 1;
    wheelVibrateGraphicRight.renderingGroupId = 1;
    wheelSpinGraphic.renderingGroupId = 1;
    
    wheelVibrateGraphicLeft.visibility = 0;
    wheelVibrateGraphicRight.visibility = 0;
    wheelSpinGraphic.visibility = 0;
    
    wheelVibrateGraphicLeft.isPickable = false;
    wheelVibrateGraphicRight.isPickable = false;
    wheelSpinGraphic.isPickable = false;
    
    var wheelSpinMat = new BABYLON.StandardMaterial('WheelVibrateGraphicMat', scene);
    wheelSpinMat.emissiveColor = wheelGraphicBlue;
    wheelSpinMat.backFaceCulling = false;
    
    var wheelSpinTex = new BABYLON.Texture('scenes/wheel-arrow.png', scene);
    wheelSpinTex.hasAlpha = true;
    wheelSpinTex.getAlphaFromRGB = true;
    wheelSpinMat.opacityTexture = wheelSpinTex;
    
    wheelSpinGraphic.material = wheelSpinMat;
    
    wheelVibrateGraphicMat = new BABYLON.StandardMaterial('WheelVibrateGraphicMat', scene);
    wheelVibrateGraphicMat.emissiveColor = wheelGraphicBlue;
    
    var wheelVibrateFrameTexture00 = new BABYLON.Texture('scenes/wheel-vibrate-00.png', scene);
    wheelVibrateFrameTexture00.hasAlpha = true;
    wheelVibrateFrameTexture00.getAlphaFromRGB = true;
    var wheelVibrateFrameTexture01 = new BABYLON.Texture('scenes/wheel-vibrate-01.png', scene);
    wheelVibrateFrameTexture01.hasAlpha = true;
    wheelVibrateFrameTexture01.getAlphaFromRGB = true;
    var wheelVibrateFrameTexture02 = new BABYLON.Texture('scenes/wheel-vibrate-02.png', scene);
    wheelVibrateFrameTexture02.hasAlpha = true;
    wheelVibrateFrameTexture02.getAlphaFromRGB = true;
    
    wheelVibrateFrameTextures = [
        wheelVibrateFrameTexture00,
        wheelVibrateFrameTexture01,
        wheelVibrateFrameTexture02
    ];
    
    wheelVibrateGraphicMat.opacityTexture = wheelVibrateFrameTextures[0];
    
    wheelVibrateGraphicLeft.material = wheelVibrateGraphicMat;
    wheelVibrateGraphicRight.material = wheelVibrateGraphicMat;
    
//    steeringMesh.material.emissiveTexture.updateURL('scenes/steering_frontal.jpg');
//    steeringMesh.material.opacityTexture = new BABYLON.Texture('scenes/steering_frontal_opacity.png', scene);
};

var MSBeforeRender = function() {
    dev.log('Gathering browser data');
    
    var ua = navigator.userAgent;
    isIE11 = !(window.ActiveXObject) && "ActiveXObject" in window;
    isFirefox = ua.toLowerCase().indexOf('firefox') > -1;
    
    var browserMsg = isIE11 ? 'IE11' : (isFirefox ? 'Firefox' : '');
    
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        if(ua.toLowerCase().indexOf('iphone') > -1 || ua.toLowerCase().indexOf('ipad') > -1) {
            browserMsg = 'iOS';
        } else {
            browserMsg = 'Android';
            isAndroid = true;
        }
        isMobile = true;
    } else if(/Chrome/i.test(ua)) {
        isChrome = true;
        browserMsg = 'Chrome';
    }
    
    if(ga) {
        dev.log('Starting GA pageview for custom variables');
        ga('create', 'UA-20349882-16', 'auto');
        ga('set', 'dimension1', dataLayer[0][1].toJSON());
        dev.log('Set GA dimension: Timestamp = ' + dataLayer[0][1].toJSON());
    }
    
//    alert(browserMsg + ': ' + ua);
//    alert(ua);

    isModernWebGL = engine.webGLVersion >= 2;
    useCanvasVideo = setUseCanvas ? (setUseCanvas == 'true') : isIE11;
    
    if(useCanvasVideo) {
        dev.log('Using canvas method');
    }
    
    dev.log('Setting episode');

    $('#performance-test__message').text(performanceTestText);

    if(setEpisode) {
        dev.log('Input episode: "' + setEpisode + '"');
        if(setEpisode == 'Episode0') setEpisode = 'Introduction';
        setEpisodeConfig(setEpisode);
    } else {
        dev.log('No episode input. Using "' + defaultEpisode + '"');
        setEpisodeConfig(defaultEpisode);
    }
    
    $('.start-overlay').removeClass('hidden');
    $('.control-overlay').removeClass('hidden');
    
    directionalOverlay = $('#directional-overlay');

    dev.log('Adjusting camera settings');
    
    cameraTarget = cameraTargets.center;
    
    camera = new BABYLON.ArcRotateCamera("Camera", cameraTarget.alpha, cameraTarget.beta, 5, BABYLON.Vector3.Zero(), scene);
    camera.attachControl(canvas, true);
    camera.angularSensibilityX = 1000000;
    camera.angularSensibilityY = 1000000;
    camera.lowerAlphaLimit = -Math.PI * 2;
    camera.upperAlphaLimit = -.05;
    camera.inputs.attached.mousewheel.detachControl(canvas);
    camera.inputs.attached.keyboard.detachControl(canvas);
    camera.inputs.attached.pointers.multiTouchPanAndZoom = false;
    camera.inputs.attached.pointers.multiTouchPanning = false;
    camera.inputs.attached.pointers.panningSensibility = 0;
    camera.lowerRadiusLimit = camera.upperRadiusLimit = camera.radius;
    
    lensEffect = new BABYLON.LensRenderingPipeline('lens', {
		dof_focus_distance: 5,
		dof_gain: 0
	}, scene, 1.0, camera);
    
    lensEffect.setAperture(0);

    if(scene.lights.length > 0) {
        for(var li = 0; li < scene.lights.length; li++) {
            scene.lights[li].dispose();
        }
    }
    
    touchUIBGMesh.parent = camera;
    
    MSCreateClickables();
    
    MSEpisodeEngine();
};

var clickableMat;
var MSCreateClickables = function() {
    dev.log('Initializing 3D clickable objects');
    
    clickableMat = new BABYLON.StandardMaterial('ClickableMat', scene);
    clickableMat.emissiveColor = new BABYLON.Color3(166/255,214/255,73/255);
    
    switch(babylonFileName) {
        case '360_FordEdge2.babylon':
            initClickable('StalkLeft');
            initClickable('Cruise');
            initClickable('ParkAssist');
        case '360_FordEdge.babylon':
            initClickable('Shifter');
            initClickable('Touchscreen');
            initClickable('FiveWayLeft');
            initClickable('FiveWayRight');
            initClickable('Lighting');
            break;
        case '360_Nautilus.babylon':
            initClickable('Shifter');
            initClickable('Touchscreen');
            initClickable('QAM');
            initClickable('InfoDisplayControl');
            initClickable('StalkLeft');
            initClickable('Cruise');
            initClickable('ParkAssist');
            break;
        default:
            break;
    }
};

var initClickable = function(meshName) {
    var clickMesh = scene.getMeshByName(meshName);
    clickMesh.visibility = 0;
    clickMesh.material = clickableMat;
    clickMesh.renderingGroupId = 2;
    clickables.push(clickMesh);
};

var getClickable = function(meshName) {
//    var clickableCheck = false;
    for(var i = 0; i < clickables.length; i++) {
        if(clickables[i].name == meshName) {
            return meshName;
        } else if(meshName.indexOf('_Collider') > -1) {
            var baseName = meshName.slice(0, meshName.indexOf('_Collider'));
            if(clickables[i].name == baseName) {
                return baseName;
            }
        }
    }
    return false;
};

var selectClickable = function(meshName, stopBlink) {
    setClickableVisibility(meshName, .3, stopBlink);
};

var deselectClickable = function(meshName, stopBlink) {
    setClickableVisibility(meshName, 0, stopBlink);
};

var deselectClickables = function(stopBlink) {
    if(stopBlink && blinkInterval) clearInterval(blinkInterval);
    for(var i = 0; i < clickables.length; i++) {
        setClickableVisibility(clickables[i].name, 0);
    }
};

var setClickableVisibility = function(meshName, visibility, stopBlink) {
    if(stopBlink && blinkInterval) clearInterval(blinkInterval);
    
    var clickable = getClickable(meshName);
    if(clickable) {
        var clickMesh = scene.getMeshByName(clickable);
        clickMesh.visibility = visibility;
    }
};

var blinkInterval;
var blinkClickable = function(meshName) {
    if(blinkInterval) clearInterval(blinkInterval);
    
    var clickMesh = scene.getMeshByName(meshName);
    var i = 0;
    blinkInterval = setInterval(function() {
        clickMesh.visibility = (i + 1) % 2 ? .3 : 0;
        i++;
    }, 100);
};

var blinkMeshIntervals = {};
var blinkMesh = function(meshName) {
    if(blinkMeshIntervals[meshName]) clearInterval(blinkMeshIntervals[meshName]);
    
    var thisMesh = scene.getMeshByName(meshName);
    var i = 0;
    blinkMeshIntervals[meshName] = setInterval(function() {
        thisMesh.visibility = (i + 1) % 2 ? 1 : 0;
        i++;
    }, 100);
};

var unblinkMesh = function(meshName) {
    if(blinkMeshIntervals[meshName]) clearInterval(blinkMeshIntervals[meshName]);
    var thisMesh = scene.getMeshByName(meshName);
    thisMesh.visibility = 0;
};

var setMeshVisibility = function(meshName, vis) {
    var thisMesh = scene.getMeshByName(meshName);
    thisMesh.visibility = vis;
};

var setDrive = function(pedal, active, affectOther) {
    var thisBtn = pedal.toLowerCase() == 'brake' ? 'btn-brake' : 'btn-gas';
    
    if(active) {
        $('#' + thisBtn).addClass('on');
    } else {
        $('#' + thisBtn).removeClass('on');
    }
    
    if(affectOther) {
        var otherBtn = pedal.toLowerCase() == 'brake' ? 'btn-gas' : 'btn-brake';
        if(active) {
            $('#' + otherBtn).removeClass('on');
        } else {
            $('#' + otherBtn).addClass('on');
        }
    }
};

var isPlaying = function(media) {
    for(var i = 0; i < $(media).length; i++) {
        var thisMedia = $(media)[i];
        if(thisMedia.currentTime > 0 && !thisMedia.paused && !thisMedia.ended && thisMedia.readyState > 2) {
            return true;
        }
    }
    return false;
};

var setTimerEnabled = function(enabled) {
    timerEnabled = enabled;
    if(enabled) {
        actionProgress.show();
    } else {
        actionProgress.hide();
    }
};

var MSEpisodeEngine = function() {
    dev.log('Initializing game UI components');
    
    if(window.orientation === 0) {
        $('.warning-overlay').removeClass('hidden').find('.alert').text('Please rotate device to landscape mode!');
    }
    
    $(window).on('orientationchange', function(ev) {
        if(window.orientation === 0) {
            $('.warning-overlay').removeClass('hidden').find('.alert').text('Please rotate device to landscape mode!');
        } else {
            $('.warning-overlay').addClass('hidden');
        }
        
        if(isPlaying(currentVideo)) {
            currentVideo.pause();
            cueManager._emitImpede({
                type: 'directional',
                message: 'Device orientation change detected. Press Continue when ready.',
                header: 'Paused'
            });
        }
    });
    
    domeMat = new BABYLON.StandardMaterial("VideoMaterial", scene);

    var pointerDown = false,
        cameraHeld = false,
        downTime,
        downPoint,
        camBounceStep = .1;
    
	scene.onPointerDown = function () {
        pointerDown = true;
//        downTime = new Date();
        downPoint = [scene.pointerX, scene.pointerY];
        
        if(!cameraHeld) {
            var pickResult = scene.pick(scene.pointerX, scene.pointerY);
            if(pickResult.hit && pickResult.pickedMesh) {
                pickLocation = [scene.pointerX, scene.pointerY];
                registerInput('pick-' + pickResult.pickedMesh.name);
                dev.log('picked ' + pickResult.pickedMesh.name, pickLocation);
                selectClickable(pickResult.pickedMesh.name);
            }
        }
    };
	scene.onPointerUp = function () {
//        var upTime = new Date();
        
//        if((upTime - downTime) < 160) {
        if(cameraHeld) {
//            dev.log('Release distance: ' + Math.abs(Math.sqrt(Math.pow(scene.pointerX - downPoint[0], 2) + Math.pow(scene.pointerY - downPoint[1], 2))) + ', (alpha, beta): (' + camera.alpha + ', ' + camera.beta + ')');
            dev.log('Released camera: ' + '(alpha, beta): (' + camera.alpha + ', ' + camera.beta + ')');
        }
        
        deselectClickables();
    
        pointerDown = false;
		cameraHeld = false;
        camera.angularSensibilityX = 1000000;
        camera.angularSensibilityY = 1000000;
	};
    
    var fps,
        lastFps = -1,
        fpsArray = [],
        fpsAvg = 30,
        fpsLowerLimit = 20,
        fpsEl = $('#fps'),
        fpsSum = 0,
        fpsCount = 0,
        performanceTestComplete = false,
        watchPerformanceTesting = false,
        watchHalfPoint = false;
    
    if(setLow) {
        isLowPerformance = setLow == 'true';
        dev.log('Skipping performance test');
        
        $('#performance-test__message').text('Setting Low Performance');
        $('.performance-test__loading').show();
        
        checkEnableStart();
    } else if(setLowest) {
        isLowPerformance = isLowestPerformance = setLowest == 'true';
        dev.log('Skipping performance test');
        
        $('#performance-test__message').text('Setting Lowest Performance');
        $('.performance-test__loading').show();
        
        checkEnableStart();
    } else {
        $('.btn-test-start').show().click(function() {
            dev.log('Starting performance test...');
            
            currentVideo.muted = true;
            currentVideo.play();
            $(this).hide();
            $('#performance-test__message').text('Testing Performance');
            $('.performance-test__loading').show();
            performanceTesting = true;
            watchPerformanceTesting = true;
        });
    }
    
    initEpisode();
    
    var videoProgress = $('.video-progress');
    var videoProgressBar = $('.video-progress__bar');
    var videoProgressBuffer = $('.video-progress__bar--buffer');
    
    var cueTimeAccuracy = .1;
    
//    $('.overview-title').html(episodeOverview.title);
//    $('.overview-content').html(episodeOverview.content);
    
    actionProgress = $('#action-progress');
    actionProgressBar = actionProgress.find('.progress-radial__circle--l1');
    actionProgressBarL2 = actionProgress.find('.progress-radial__circle--l2');
    actionProgressBarL3 = actionProgress.find('.progress-radial__circle--l3');
    actionProgressText = actionProgress.find('.progress-radial__text');
    
    $('[data-dt-button-group]').addClass('disabled');
    
    for(var i = 0; i < enabledControls.length; i++) {
        $('#' + enabledControls[i]).removeClass('disabled');
    }
    
    $('#max-points').text(maxPoints);

    if(autoPilotMode) $('.control-overlay').after('<div id="auto-pilot-drive-icon" class="hidden"></div>');
    
    // IE 11 doesn't support CSS tranforms on SVG objects, so must use attribute instead
    if(isIE11) $('#action-progress .progress-radial__circle').attr('transform', 'rotate(-90,50,50)');
    
    steeringAnimation.init();
    
    var readyAndPlaying = false;
    
    dev.log('Setting custom render loop');
    
    var currentVideoTime;
    
    scene.registerBeforeRender(function() {
        currentVideoTime = currentVideo.currentTime;
        engineLoop();
        if ( singleVideo.element.readyState >= singleVideo.element.HAVE_CURRENT_DATA ) {
            if(useCanvasVideo) {
                domeCanvasContext.drawImage(singleVideo.element, 0, 0, singleVideo.width, singleVideo.height);
                domeMat.emissiveTexture.update();
            }
        }
        
        if( currentVideo.readyState >= currentVideo.HAVE_FUTURE_DATA ) {
            if(!readyAndPlaying) {
                readyAndPlaying = true;
                dev.log('Game video has loaded enough to play');
            }
            
            for(var c = 0; c < cueWatch.length; c++) {
                var cue = singleVideoPaths[cueWatch[c]];
                var isWindowPath = cueWatch[c].indexOf('w') == 0;
                if( cue && !cue.done && currentVideoTime >= cue.startTime ) {
                    if( !cue.started ) {
                        cue.started = true;
                        previousPathId = currentPathId;
                        currentPathId = cueWatch[c];
                        cueManager._cueChangeHandler(cueWatch[c]);
                    }
                    if( currentVideoTime <= cue.endTime ) {
                        if(isWindowPath && timerEnabled && mainControlsEnabled) {
                            var pathProgress = (currentVideoTime - cue.startTime) / (cue.endTime - cue.startTime);
                            actionProgressBar.css('stroke-dashoffset', -pathProgress + 'em');
                            
                            var remaining = cue.endTime - currentVideoTime;
                            actionProgressText.text(Math.ceil(remaining));
                        }
                    } else {
                        cue.done = true;
                        dev.log('Cue done: ' + cueWatch[c]);
                        cueManager._cueExitHandler(cueWatch[c]);
                    }
                }
            }

            if(!currentVideo.paused && steeringAnimation.isActive) steeringAnimation.renderLoop(currentVideoTime);
            
            if(!currentVideo.paused && dev.debugMode) {
                videoProgressBar.css('right', 100 - currentVideoTime / currentVideo.duration * 100 + '%');
                if(currentVideo.buffered.length > 0) videoProgressBuffer.css('right', 100 - currentVideo.buffered.end(0) / currentVideo.duration * 100 + '%');
            }
            
            if(watchPerformanceTesting || dev.debugMode) {
                fps = parseInt(engine.getFps().toFixed());
                if (fps != lastFps) {
                    fpsEl.text(fps + 'fps');
                    lastFps = fps;
                }
            }

            if(watchPerformanceTesting) {
                if(performanceTesting) {
                    fpsCount++;
                    if(fpsSum == 0 && fps < 100 && fpsCount > 10) {
                        fpsArray.push(fps);
                        if(fpsArray.length > 30 || fps < 10) {
                            performanceTestComplete = true;
                            performanceTesting = false;

                            for(var i = 0; i < fpsArray.length; i++) {
                                fpsSum += fpsArray[i];
                            }

                            fpsAvg = Math.floor(fpsSum / fpsArray.length);
                            dev.log('Performance Test complete: Average ' + fpsAvg + ' fps');
                            
                            
                            if(ga) {
                                ga('set', 'metric2', fpsAvg);
                                dev.log('Set GA dimension: Avg FPS = ' + fpsAvg);
                            }

                            if(fpsAvg < fpsLowerLimit) {
                                dev.log('Average fps below ' + fpsLowerLimit + '. Setting Low Performance');
                                isLowPerformance = true;
                            }

    //                        currentVideo.currentTime = 0;
                            currentVideo.pause();
                            singleVideo.element.childNodes[0].src = '';
//                            singleVideo.element.load();
                            
                            networkTest.InitiateSpeedDetection(function() {
                                if(networkTest.downloadSpeed < 6) {
                                    isLowestPerformance = true;
                                } else if(networkTest.downloadSpeed < 9) {
                                    isLowPerformance = true;
                                }
                                
                                resetEpisode();

                                checkEnableStart();
                                
                                if(ga) {
                                    ga('set', 'metric1', Math.floor(networkTest.downloadSpeed));
                                    dev.log('Set GA dimension: Download Speed = ' + Math.floor(networkTest.downloadSpeed));
                                    
                                    ga('send', 'pageview');
                                    dev.log('Sending GA pageview data');
                                }
                                
                            });
                        }
                    }
                }
                if(performanceTestComplete) {
                    watchPerformanceTesting = false;
                }
            }
        } else {
            if(readyAndPlaying) {
                readyAndPlaying = false;
                if(currentVideo.readyState === 0) {
                    if(cuesReady) {
                        dev.warn('Game video metadata is ready, but video cannot play');
                    } else {
                        dev.log('Game video cannot play yet, as metadata isn\'t ready');
                    }
                } else {
                    if(!currentVideo.paused) dev.warn('Game video has stopped playing. Ready state: ' + currentVideo.readyState);
                }
            }
        }
        
        if(pointerDown && !cameraHeld) {
            var moveDistance = Math.abs(Math.sqrt(Math.pow(scene.pointerX - downPoint[0], 2) + Math.pow(scene.pointerY - downPoint[1], 2)));
            if(moveDistance > 20) {
                cameraHeld = true;
                camera.angularSensibilityY = 2000;
                camera.angularSensibilityX = 2000;                
            }
//            var curTime = new Date();
//            if((curTime - downTime) >= 160) {
//                cameraHeld = true;
//                camera.angularSensibilityY = 2000;
//                camera.angularSensibilityX = 2000;
//            }
        }
        
        
        if(speedometerAnimation.isActive) {
            speedometerAnimation.renderLoop(currentVideoTime);
        }
        
        if(cameraAnimation.isActive) {
            cameraAnimation.renderLoop(currentVideoTime);
            if(cameraAnimation.playing) return;
        }
        
        if(cameraBlur != undefined && cameraBlur != lensEffect._dofAperture) {
            var dBlur = cameraBlur - lensEffect._dofAperture;
            var nextAperture;
            if(Math.abs(dBlur) < .07) {
                nextAperture = cameraBlur;
            } else {
                nextAperture = lensEffect._dofAperture + dBlur * camBounceStep;
            }
            lensEffect.setAperture(nextAperture);
        }
        
        if (cameraHeld) { return; }
        
        if(cameraTarget.alpha) {
            camera.alpha += (cameraTarget.alpha - camera.alpha) * camBounceStep;
            camera.beta += (cameraTarget.beta - camera.beta) * camBounceStep;
        }
        
        if(cameraTarget.radius && camera.radius != cameraTarget.radius) {
            var dRad = cameraTarget.radius - camera.radius;
            if(cameraTarget.radius < camera.radius) {
                camera.lowerRadiusLimit = cameraTarget.radius;
                camera.radius += dRad * camBounceStep;
            } else {
                camera.upperRadiusLimit = cameraTarget.radius;
                camera.radius += dRad * camBounceStep;
            }

            if(Math.abs(dRad) < .03) {
                camera.radius = cameraTarget.radius;
            }
        }
    });

//    window.requestAnimationFrame(engineLoop);
};

var startTestVideo = function() {
    var texInit = textureInitFunction('Part 1', episodeVideoPaths.part1.src);
    currentVideo = texInit[1];
    thisTex = texInit[0];
    
    if(!useCanvasVideo) {
        domeMat.emissiveTexture = thisTex;
    } else {
        domeMat.emissiveTexture = new BABYLON.DynamicTexture('VideoCanvas', { width: currentVideo.width, height: currentVideo.height }, scene, false);
        domeMat.emissiveTexture.wrapU = 1;
        domeCanvasContext = domeMat.emissiveTexture.getContext();
        domeCanvasContext.drawImage(currentVideo, 0, 0, currentVideo.width, currentVideo.height);
        domeMat.emissiveTexture.update();
    }

    domeMesh = scene.getMeshByName('VideoDome');
    domeMesh.material = domeMat;
    touchUIMesh.material = domeMat;
    
    currentVideo.muted = true;
    currentVideo.play();
};

var initEpisode = function() {
    dev.log('Initializing episode');
    
    initVideoSettings();
    makeVideoElement();
    
    currentVideo = singleVideo.element;
    
//    if(useCanvasVideo) {
//        currentVideo.addEventListener('loadedmetadata', function() {
//            var imgData = domeCanvasContext.getImageData(1,1,1,1);
//        });
//    }
    
    textureInitFunction();
    
    if(setLow || setLowest) {
        joshSetup();
    }
    
    extraCuesSetup();
    
    if(episodeInit && typeof episodeInit === 'function') {
        episodeInit();
    }
}; 

var resetEpisode = function() {
    dev.log('Episode reset');
    
    singleVideo.element.innerHTML = '';
    
    initVideoSettings();
    makeVideoElement();
    
    currentVideo = singleVideo.element;
    
    if(!useCanvasVideo) {
        domeMat.emissiveTexture.video = currentVideo;
//        thisTex.video.load();
    } else {
        domeMat.emissiveTexture._canvas.width = currentVideo.width;
        domeMat.emissiveTexture._canvas.height = currentVideo.height;
    }
    
    joshSetup();
};

var initVideoSettings = function() {
    var vidSrc = (useCanvasVideo ? singleVideo.fileBaseLocal : singleVideo.fileBase) + singleVideo.fileName,
        vidType,
        vidWidth,
        vidHeight;
    
    if(isLowestPerformance) {
        vidSrc = vidSrc + '_lowest';
        
        vidWidth = 2048;
        vidHeight = 1024;
    } else if(isLowPerformance) {
        vidSrc = vidSrc + '_halfRes';
        
        vidWidth = 2048;
        vidHeight = 1024;
    }
    else {
        vidWidth = 4096;
        vidHeight = 2048;
    }
    

    
    singleVideo.sources = {
        vp9: {
            src: vidSrc + '.webm',
            type: 'video/webm'
        },
        h264: {
            src: vidSrc + '.mp4',
            type: 'video/mp4'
        }
    };
    singleVideo.width = vidWidth;
    singleVideo.height = vidHeight;
};

var overlaysOpen = function() {
    $('.interactive-overlay').each(function(ind, elem) {
        if(!$(elem).hasClass('hidden')) return true;
    });
    if(!$('#directional-overlay').hasClass('hidden')) return true;
    if(!$('#control-map-overlay').hasClass('hidden')) return true;
    return false;
};

var gameVideoErrors = {};

var makeVideoElement = function () {
    dev.log('Creating game video element');
    
    var v = document.createElement('video'),
        s1 = document.createElement('source');
//        tr = document.createElement('track');
    
    var canPlayVP9 = v.canPlayType('video/webm; codecs="vp9"').replace(/^no$/, '') == 'probably';
    
//    dev.log('Can browser play VP9? ' + canPlayVP9);
    
    var vidSrc,
        vidType;
    
    var requiresWebm = setWebm ? (setWebm == 'true') : ((isFirefox || isAndroid) && canPlayVP9);
    
    if(requiresWebm && isLMS && !webmAvailableInLMS) {
        $('.warning-overlay')
            .removeClass('hidden')
            .find('.alert')
            .text('Simulation not supported in this browser. Please use Google Chrome, Internet Explorer 11, or Microsoft Edge instead.');
    } else {
        if(requiresWebm) {
            vidSrc = singleVideo.sources.vp9.src;
            vidType = singleVideo.sources.vp9.type;
        } else {
            vidSrc = singleVideo.sources.h264.src;
            vidType = singleVideo.sources.h264.type;
        }
    }
    
    dev.log('Setting game video source: ' + vidSrc);
    
    singleVideo.src = vidSrc;
    singleVideo.type = vidType;

    // gameVideoErrors[vidSrc] = 0;

    v.onerror = function(e) {
        var thisVid = e.currentTarget;
        var thisVidSrc = $(thisVid).find('source')[0].src;

        // gameVideoErrors[vidSrc]++;

        // // if game has started...
        // if( controlsEnabled && !(overlaysOpen()) ) {
        //     cueManager._emitImpede({
        //         type: 'directional',
        //         message: 'Game video encountered an error. Hit Continue to try continuing.',
        //         onShow: function() {
        //             $('#directional-overlay .overlay__button').addClass('hidden');
        //         }
        //     });
        //     setTimeout(function() {

        //     })
        // } else {
        //     $('#control-map-ready').hide();
        //     $('#control-map-overlay').addClass('hidden');
        // }
        if(thisVidSrc.indexOf('lowest') > 0) {
            dev.warn('Lowest quality video produced error (below), nothing else to try. Will allow game to continue at Lowest quality, just in case this is a fluke.');
            console.error(thisVid.error);
            // $('.warning-overlay')
            //     .removeClass('hidden')
            //     .find('.alert')
            //     .text('Error: The test video refused to load.');
            thisVid.onerror = null;
            
            // if(cmLoadFailures < 10) {
            //     setTimeout(function() {
            //         dev.warn('Trying to load Introduction video again...');
            //         thisVid.load();
            //     }, 500);
            // } else {
            //     dev.warn('Introduction video has failed to load too many times. SKIPPING.');
            //     $(thisVid).trigger('ended');
            // }
        } else if(thisVidSrc.indexOf('halfRes') > 0) {
            dev.warn('Low quality video produced error (below), trying Lowest quality mode instead.');
            console.error(thisVid.error);
            performanceTestComplete = true;
            performanceTesting = false;
            isLowPerformance = true;
            isLowestPerformance = true;

            thisVid.pause();
            resetEpisode();
            checkEnableStart();
            thisVid.onerror = null;
        } else {
            dev.warn('Full quality video produced error (below), trying Low quality mode instead.');
            console.error(thisVid.error);
            performanceTestComplete = true;
            performanceTesting = false;
            isLowPerformance = true;

            thisVid.pause();
            resetEpisode();
            checkEnableStart();
            thisVid.onerror = null;
        }
    };

    // v.oncanplay = function(e) {
    //     var thisVid = e.currentTarget;
    //     var thisVidSrc = $(thisVid).find('source')[0].src;

    //     if(gameVideoErrors[vidSrc] > 0) {

    //     } else {
    //         $('#control-map-ready').click(function() {
    //             $('#control-map-overlay').addClass('hidden');
    //             startGame();
    //         });
    //     }
    // };
    
    v.setAttribute('title', singleVideo.fileName);
    v.setAttribute('playsinline', true);
    // if(!useCanvasVideo) v.setAttribute('crossorigin', 'anonymous');
    v.width = singleVideo.width;
    v.height = singleVideo.height;
    
    s1.setAttribute('src', singleVideo.src);
    s1.setAttribute('type', singleVideo.type);
    v.appendChild(s1);
    
//    tr.setAttribute('kind', 'metadata');
//    tr.setAttribute('src', 'textures/' + singleVideo.fileName + '.vtt');
//    v.appendChild(tr);
    
//    MSPreloadVideo();

    dev.log('Game video loading');
    
    v.preload = 'auto';

    v.load();
    
    singleVideo.element = v;

    dev.log('Game video element: ' + singleVideo.element.outerHTML);
//    return v;
};

var textureInitFunction = function() {  
//    var thisTex;
    dev.log('Initializing video texture');
    if(!useCanvasVideo) {
        domeMat.emissiveTexture = new BABYLON.VideoTexture(
            singleVideo.fileName,
            currentVideo,
            scene,
            false,
            false,
            BABYLON.VideoTexture.TRILINEAR_SAMPLINGMODE,
            { autoUpdateTexture: true });
//        thisTex.video.load();
    } else {
        domeMat.emissiveTexture = new BABYLON.DynamicTexture('VideoCanvas', { width: currentVideo.width, height: currentVideo.height }, scene, false);
        domeMat.emissiveTexture.wrapU = 1;
        domeCanvasContext = domeMat.emissiveTexture.getContext();
        domeCanvasContext.drawImage(currentVideo, 0, 0, currentVideo.width, currentVideo.height);
        domeMat.emissiveTexture.update();
    }
    
    domeMesh = scene.getMeshByName('VideoDomeWithMirrors');
    domeMesh.material = domeMat;
    touchUIMesh.material = domeMat;
};

var checkEnableStart = function() {
    if(!performanceTesting) {
        if(cuesReady) {
            $('#performance-test-results').html('Graphics performance: ' + (isLowPerformance ? 'Low' : 'High') + ((setLow || setLowest) ? '' : '<br/>Connection speed: ' + networkTest.downloadSpeed + 'Mbps')).show();
            
//            var controlMapVidSrc = isLowPerformance ? 'textures/ControlMapVideo_halfRes.mp4' : 'textures/ControlMapVideo.mp4';
            if(setEpisode == 'Introduction') {
                stopRenderLoop();
                
                if(introVideoSrcLow && isLowPerformance) introVideoSrc = introVideoSrcLow;
                if(introVideoSrcLowest && isLowestPerformance) introVideoSrc = introVideoSrcLowest;
                $('.performance-test').hide();
                $('.start-overlay').hide();
                
                $('#control-map-overlay').addClass('video');
                $('#control-map-ready').css({ bottom: '-10em', opacity: 0 });
                
                $('.control-overlay').hide();
                $('#score').hide();
                $('canvas').hide();
                $('.intro-overlay').hide();
                $('#control-map-overlay .overlay__button').hide();
                
                $('#legal-start').show().click(function() {
                    dev.log('Playing introduction video');

                    $('#title-overlay').hide();
                    
                        var controlMapVideo = $('#control-map-overlay video')[0];

                        var cmLoadFailures = 0,
                            cmNextTryTimer,
                            waitForCMTimer = false;
                        controlMapVideo.onerror = function(e) {
                            if(waitForCMTimer) return;
                            cmLoadFailures++;
                            $('#legal-overlay .overlay__button').hide();
                            var thisVid = e.currentTarget;
                            var thisVidSrc = thisVid.src;
                            if(thisVidSrc.indexOf('lowest') > 0) {
                                dev.warn('Lowest quality Introduction video produced error (below).');
                                console.error(thisVid.error);
                            } else if(thisVidSrc.indexOf('halfRes') > 0) {
                                dev.warn('Low quality Introduction video produced error (below).');
                                console.error(thisVid.error);
                            } else {
                                dev.warn('Full quality Introduction video produced error (below).');
                                console.error(thisVid.error);
                            }
                            if(cmLoadFailures < 10) {
                                if(cmNextTryTimer) clearInterval(cmNextTryTimer);
                                waitForCMTimer = true;
                                cmNextTryTimer = setTimeout(function() {
                                    dev.warn('Trying to load Introduction video again...');
                                    thisVid.load();
                                    waitForCMTimer = false;
                                }, 500);
                            } else {
                                dev.warn('Introduction video has failed to load too many times. It will be skipped.');
                                if($('#legal-overlay').css('display') == 'none') {
                                    $(thisVid).trigger('ended');
                                } else {
                                    $('#legal-overlay .overlay__button').click(function() {
                                        $('#legal-overlay .overlay__button').click(function() {
                                            $(thisVid).trigger('ended');
                                        });
                                    });
                                }
                            }
                        };

                        controlMapVideo.oncanplay = function() {
                            if(cmLoadFailures > 0) {
                                if(cmNextTryTimer) clearInterval(cmNextTryTimer);
                                waitForCMTimer = false;
                                console.log('Introduction video has recovered from errors and loaded.');
                            } else {
                                if(controlMapVideo.error === null) dev.log('Introduction video has initially loaded.');
                            }
                            $('#legal-overlay .overlay__button').show();
                        };

                        $(controlMapVideo).attr('src', singleVideo.fileBase + introVideoSrc);
                        controlMapVideo.load();
                    $('#legal-overlay .overlay__button').click(function() {
                        dev.log('Legal disclaimer hide');
                        $('#legal-overlay').hide();
                    

                        var controlMapCues = [
                            {
                                time: 48.4333,
                                action: function() {
                                    $('#control-map-ready').css({ bottom: '', opacity: 1 });
                                }
                            },
                            {
                                time: 158.8333,
                                action: function() {
                                    $('#control-map-ready').css({ background: 'rgb(166,214,73)', color: 'white' });
                                    setTimeout(function() {
                                        $('#control-map-ready').css({ background: '', color: '' });
                                    }, 1000);
                                }
                            }
                        ];

                        controlMapVideo.muted = false;
                        controlMapVideo.play();
                        
                        if(isLMS) {
                            $(controlMapVideo).on('ended', function() {
                                window.parent.simLib.onEpisodeComplete({ score: currentPoints, scorePossible: maxPoints });
                            });
                        }

    //                    setTimeout(function(){
    //                        $('.intro-overlay').hide();
    //                        $('#control-map-overlay video').attr('loop', true)[0].play();
    //                    }, 400);
//                        $('#control-map-ready').click(function() {
//                            $('#control-map-overlay video')[0].pause();
//                            if(isLMS) {
//                                window.parent.currentEpisode = { id: 'Episode1', title: 'Episode 1' };
//                                window.parent.WBTApp.loadPage(2);
//                            } else {
//                                location.assign('index.html?ep=Episode1');
//                            }
//                        });
                    });
                });
                    
            } else {
                $('#legal-start').show().click(function() {
                    dev.log('Legal disclaimer show');

                    $('#title-overlay').hide();
                    $('#legal-overlay .overlay__button').click(function() {
                        dev.log('Legal disclaimer hide');
                        $('#legal-overlay').hide();
                    });
                });


                $('.performance-test').hide();

                if(introVideoSrc) {
                    // pause render for intro video
                    stopRenderLoop();

                    if(introVideoSrcLow && isLowPerformance) introVideoSrc = introVideoSrcLow;
                    if(introVideoSrcLowest && isLowestPerformance) introVideoSrc = introVideoSrcLowest;

                    var introVideo = $('.intro-overlay').find('video')[0];

                    var introLoadFailures = 0,
                        introVideoNextTryTimer,
                        waitForIntroVideoTimer = false;

                    introVideo.onerror = function(e) {
                        if(waitForIntroVideoTimer) return;
                        introLoadFailures++;
                        $('#intro-start').hide();
                        var thisVid = e.currentTarget;
                        var thisVidSrc = $(thisVid).find('source')[0].src;
                        if(thisVidSrc.indexOf('lowest') > 0) {
                            dev.warn('Lowest quality Intro video produced error (below).');
                            console.error(thisVid.error);
                        } else if(thisVidSrc.indexOf('halfRes') > 0) {
                            dev.warn('Low quality Intro video produced error (below).');
                            console.error(thisVid.error);
                        } else {
                            dev.warn('Full quality Intro video produced error (below).');
                            console.error(thisVid.error);
                        }
                        if(introLoadFailures < 10) {
                            if(introVideoNextTryTimer) clearInterval(introVideoNextTryTimer);
                            waitForIntroVideoTimer = true;
                            introVideoNextTryTimer = setTimeout(function() {
                                dev.warn('Trying to load Intro video again...');
                                introVideo.load();
                                waitForIntroVideoTimer = false;
                            }, 500);
                        } else {
                            dev.warn('Intro video has failed to load too many times. It will be skipped.');
                            if($('#legal-overlay').css('display') == 'none') {
                                $(introVideo).trigger('ended');
                            } else {
                                $('#legal-overlay .overlay__button').click(function() {
                                    $('#legal-overlay .overlay__button').click(function() {
                                        $(introVideo).trigger('ended');
                                    });
                                });
                            }
                        }
                    };

                    introVideo.oncanplay = function() {
                        if(introLoadFailures > 0) {
                            if(introVideoNextTryTimer) clearInterval(introVideoNextTryTimer);
                            waitForIntroVideoTimer = false;
                            console.log('Intro video has recovered from errors and loaded.');
                        } else {
                            if(introVideo.error === null) dev.log('Intro video has initially loaded.');
                        }
                        $('#intro-start').show();
                    };

                    $(introVideo).find('source').attr('src', singleVideo.fileBase + introVideoSrc);
                    introVideo.load();
                    $('.intro-overlay').removeClass('hidden');
                    $('.start-overlay').hide();
                    $('#intro-start').on('click', function() {
                        dev.log('Intro video started');

                        introVideo.play();
                        $('#intro-buttons').hide();
                    });
    //                $('#intro-skip').click(function() {
    //                    dev.log('Intro video skipped');
    //                    
    //                    //play render
    //                    startRenderLoop();
    //                    
    //                    $(introVideo).off('ended');
    //                    introVideo.pause();
    //                    $('.intro-overlay').addClass('hidden');
    //                    $('.intro-overlay video source').attr('src', '');
    //                    setTimeout(function(){ $('.intro-overlay').hide(); }, 400);
    //                    $('#control-map-ready').click(function() {
    //                        $('#control-map-overlay').addClass('hidden');
    //                        startGame();
    //                    });
    //                });
                    $(introVideo).on('ended', function() {
                        dev.log('Intro video ended');
                        //play render
                        startRenderLoop();

                        $('.intro-overlay').addClass('hidden');
                        $('.intro-overlay video source').attr('src', '');
                        setTimeout(function(){
                            $('.intro-overlay').hide();
                        }, 400);
                        $('#control-map-ready').click(function() {
                            $('#control-map-overlay').addClass('hidden');
                            startGame();
                        });
                    });
                } else {
                    dev.log('No intro video, skipping');
                    startRenderLoop();
                    $('.intro-overlay').hide();
                    $('.start-overlay').hide();
                    $('#control-map-ready').click(function() {
                        $('#control-map-overlay').addClass('hidden');
                        startGame();
                    });
                }
            }
        } else {
            $('#performance-test__message').text('Done! Initializing Episode');
        }
    }
};

var controlMapToggle = function() {
    var controlMap = $('#control-map-overlay');
    if(controlMap.hasClass('hidden')) {
        currentVideo.pause();
        controlMap.removeClass('hidden');
    } else {
        currentVideo.play();
        controlMap.addClass('hidden');
    }
};

var startGame = function() {
    dev.log('Starting game');
    controlsEnabled = true;
    if(datConfiguration && typeof datConfiguration === 'function') {
        datConfiguration();
    } else {
        currentVideo.play();
    }
};

var extraCuesSetup = function() {
    if(singleVideoExtraCues.length) {
        for(var c = 0; c < singleVideoExtraCues.length; c++) {
            extraCues[singleVideoExtraCues[c].name.toLowerCase()] = singleVideoExtraCues[c].markers;
        }
    }
};

var easeInOut = function(t) {
    return t < .5 ? (2 * t * t) : (-1 + (4 - 2 * t) * t);
};

var MSPreloadVideo = function() {
//            var vidArrayLength = episodeVideos.length;
//            
//            for(var i = 0; i < vidArrayLength; i++) {
//                episodeVideoBlobs[i] = "";
//            }
//            
//            for(var j = 0; j < vidArrayLength; j++) {
    
    

        var vidReq = new XMLHttpRequest();
        vidReq.open('GET', singleVideo.src, true);
        vidReq.responseType = 'blob';

        vidReq.onload = function() {
            // Onload is triggered even on 404
            // so we need to check the status code
            if (this.status === 200) {
                var videoBlob = this.response;
                singleVideo.blob = URL.createObjectURL(videoBlob); // IE10+
                
                
//                vidCount++;
//                if(vidCount < episodeVideos.length) {
//                    MSPreloadVideos();
//                }
                // Video is now downloaded
                // and we can set it as source on the video element
            }
        }
        vidReq.onerror = function() {
           // Error
        }

        vidReq.send();
//            }
};

var animateCamera = function(prop, val, t) {
    var frames = fps * t;
    if(prop == 'blur') {
        cameraBlur = val;
    }
};

var keyRemaps = {
    'up': 'arrowup',
    'down': 'arrowdown',
    'left': 'arrowleft',
    'right': 'arrowright',
    ' ': 'spacebar',
    'spacebar': 'spacebar',
    'space': 'space',
    'Enter': 'enter'
};

function registerInput(inputName, isReal) {
    inputName = keyRemaps[inputName.toLowerCase()] ? keyRemaps[inputName.toLowerCase()] : inputName;
    
    if(singleVideoPaths[currentPathId] && singleVideoPaths[currentPathId].mode != 'tutorial' && singleVideoPaths[currentPathId].mode != 'autopilot' && mainControlsEnabled) {
        map[inputName] = true;
        keydown.push(inputName);
        if(cueManager) cueManager.processInputs([inputName]);
        $('[data-dt-button-key="' + inputName.toLowerCase() + '"]').addClass('active');
    } else if(informationDisplay.isActive) {
        informationDisplay.onInput(inputName.toLowerCase(), isReal);
        if(inputName.indexOf('btn') == 0) {
            $('#' + inputName.toLowerCase()).addClass('active');
        } else {
            $('[data-dt-button-key="' + inputName.toLowerCase() + '"]').addClass('active');
        }
    } else if(shifterControl.isActive) {
        shifterControl.onInput(inputName.toLowerCase(), isReal);
        if(inputName.indexOf('btn') == 0) {
            $('#' + inputName.toLowerCase()).addClass('active');
        } else {
            $('[data-dt-button-key="' + inputName.toLowerCase() + '"]').addClass('active');
        }
    }
}

function unregisterInput(inputName, isReal) {
    inputName = keyRemaps[inputName.toLowerCase()] ? keyRemaps[inputName.toLowerCase()] : inputName;

    if(singleVideoPaths[currentPathId] && singleVideoPaths[currentPathId].mode != 'tutorial' && singleVideoPaths[currentPathId].mode != 'autopilot' && mainControlsEnabled) {
        map[inputName] = false;
        keyup.push(inputName);
        $('[data-dt-button-key="' + inputName.toLowerCase() + '"]').removeClass('active');
    } else if(informationDisplay.isActive) {
        if(inputName.indexOf('btn') == 0) {
            $('#' + inputName.toLowerCase()).removeClass('active');
        } else {
            $('[data-dt-button-key="' + inputName.toLowerCase() + '"]').removeClass('active');
        }
    } else if(shifterControl.isActive) {
        if(inputName.indexOf('btn') == 0) {
            $('#' + inputName.toLowerCase()).removeClass('active');
        } else {
            $('[data-dt-button-key="' + inputName.toLowerCase() + '"]').removeClass('active');
        }
    }
}

// -- BUTTON IDs AS INPUTS --
var dtButtonClass = '.decision-tree-button';
$(dtButtonClass)
.on('mousedown touchstart', function(event) {
	if(controlsEnabled) registerInput(this.id, true);
})
.on('mouseup touchend touchcancel', function(event) {
	if(controlsEnabled) unregisterInput(this.id, true);
});
// --  --

function clearInputs(){
    keydown.splice(0, keydown.length);
    keyup.splice(0, keyup.length);
}

document.addEventListener('keydown', function(event){
	if(controlsEnabled) registerInput(event.key, true);
});
document.addEventListener('keyup', function(event){
	if(controlsEnabled) unregisterInput(event.key, true);
});

var engineLoop = function(time){

    if(keydown.length > 0){
        dev.log('keydown: ' + JSON.stringify(keydown));
    }
    if(keyup.length > 0){
        dev.log('keyup: ' + JSON.stringify(keyup));
    }
    clearInputs();
};

var setShifter = function(letter) {
    $('.shifter-letter').removeClass('selected');
    $('.shifter-' + letter.toLowerCase()).addClass('selected');
};

var setDatAlert = function(alert) {
    $('.dat-alert').removeClass('disabled').find('p').text(alert.message);
};

var addPoints = function(points, location) {
    dev.log('Adding ' + points + ' points');
    if(currentPoints + points == maxPoints) {
        currentPoints += points;
        if(disableScoring) {
            $('#points-layer').append('<div class="starburst" style="left: ' + location[0] + 'px; top: ' + location[1] + 'px;"></div>');
        } else {
            $('#points-layer').append('<div class="add-point-con" style="left: ' + location[0] + 'px; top: ' + location[1] + 'px;"><div class="add-point">+' + points + '</div></div>');
        }
        setTimeout(function() {
            $('#add-point-con').html('');
        }, 1500);
        dev.log('Max points reached!');
        playAudio('audio/levelup.mp3', 1);
    } else if(currentPoints + points < maxPoints) {
        currentPoints += points;
        if(disableScoring) {
//            var starburst = $('<div class="starburst" style="left: ' + location[0] + 'px; top: ' + location[1] + 'px;"></div>').appendTo($('#points-layer'));
//            starburst.addClass('done');
            $('#points-layer').append('<div class="starburst" style="left: ' + location[0] + 'px; top: ' + location[1] + 'px;"></div>');
        } else {
            $('#points-layer').append('<div class="add-point-con" style="left: ' + location[0] + 'px; top: ' + location[1] + 'px;"><div class="add-point">+' + points + '</div></div>');
        }
        setTimeout(function() {
            $('#add-point-con').html('');
        }, 1500);
        playAudio('audio/coin.mp3', 1);
    } else {
        currentPoints = maxPoints;
        dev.log('Points exceeded max points!');
    }
    $('#current-points').text(currentPoints);
    
    if(isLMS) {
        window.parent.simLib.onEpisodeAttempted({ score: currentPoints, scorePossible: maxPoints });
    }
};

var playAudio = function(clipSrc, track) {
    dev.log('Playing audio on track ' + track + ': ' + clipSrc);
    
    var audioTrack = $('#audio-' + track);

    var audioPromise = audioTrack.attr('src', clipSrc)[0].play();
    if(audioPromise !== undefined) {
        audioPromise.catch(function(e) {
            dev.warn('Audio clip ' + clipSrc + ' was prevented from playing by the browser.');
        }).then(function() {});
    }
    
    return audioTrack;
};

var playAudioFromEpisode = function(audioIndex, track) {
    return playAudio(episodeAudioClips[audioIndex], track);
};

var pauseAudios = function() {
    $('#audio-1')[0].pause();
    $('#audio-2')[0].pause();
};

var isObject = function(val) {
    return val === Object(val) && Object.prototype.toString.call(val) !== '[object Array]';
};

// informationDisplay

// laneKeepingControl

// cruiseControl

var informationDisplayPopup = {
    make: function() {
        $('#instrument-panel').clone()
            .attr('id', 'instrument-panel-popup')
            .appendTo('#instrument-panel-popup-con');
        $('#instrument-panel-popup [data-to-page="displayMode"]').addClass('active');
    },
    show: function() {
        $('#instrument-panel-popup-con').removeClass('hidden');
    },
    hide: function() {
        $('#instrument-panel-popup-con').addClass('hidden');
    }
};

// shifterControl

var lightingControl = {
    init: function(action) {
        this.controlsEnabled = false;
        this.config = action.init;
        this.action = action;
        this.correctIndex = action.init.correctIndex;
        this.startIndex = (this.currentIndex > -1 && this.currentIndex < 4) ? this.currentIndex : 0;
//        this.setByIndex(this.startIndex, false);
        
        var _this = this;
        
        var lightingButtons = $('#lighting-control .decision-tree-button');
        for(var i = 0; i < lightingButtons.length; i++) {
            $(lightingButtons[i]).click(function() {
                if(_this.controlsEnabled) _this.animateTo(parseInt(this.id[this.id.length - 1]));
            });
        }
    },
    open: function(action) {
        currentVideo.pause();
        
        this.init(action);

        currentImpediment = action;
        mainControlsEnabled = false;
        $(dtButtonClass).removeClass('active');

        var lightingOverlay = $('#lighting-overlay');

        lightingOverlay.attr('data-overlay-type', action.type);
        lightingOverlay.find('.overlay__button').off('click').addClass('disabled');
        lightingOverlay.removeClass('hidden');
        
        this.mode = 'normal';
        this.controlsEnabled = true;
        this.isActive = true;
        this.resetTimer();
        this.setTimer();
    },
    onInput: function(inputName, isReal) {
        if((isReal && this.controlsEnabled) || (!isReal && this.mode == 'tutorial')) {
            dev.log('Lighting Control input: ' + inputName);
            switch (inputName) {
                case 'arrowleft':
                    this.set(this.currentIndex - 1, true);
                    break;
                case 'arrowright':
                    this.set(this.currentIndex + 1, true);
                    break;
            }
        }
    },
    reset: function() {
        this.set(this.startIndex, false);
    },
    set: function(ind, isFinal) {
        $('#lighting-ui').attr('data-lighting-position', ind);
        
        if(this.isActive) playAudio('audio/shifter.mp3', 2);
        
        this.currentIndex = ind;
        
        if(isFinal) {
            if(this.currentIndex === this.correctIndex) {
                switch(this.mode) {
                    case 'normal':
                        this.addPoints(defaultPoints.max, $('#btn-lighting-' + ind));
                        break;
                    case 'retry':
                        this.addPoints(defaultPoints.retry, $('#btn-lighting-' + ind));
                        break;
                    case 'hint':
                        this.addPoints(defaultPoints.min, $('#btn-lighting-' + ind));
                        break;
                }
                
                this.onSuccess();
            }
        }
    },
    showHints: function() {
        $('#btn-lighting-' + this.correctIndex).addClass('hint');
    },
    animateTo: function(ind) {
        if(this.shiftInterval) clearInterval(this.shiftInterval);
        
        var _this = this;
        if(ind != this.currentIndex && ind < 4 && ind > -1) {
//            if(ind < this.currentIndex) {
//                $('#shifter-spin').removeClass('spin left').addClass('spin left');
//            } else if(ind > this.currentIndex) {
//                $('#shifter-spin').removeClass('spin left').addClass('spin');
//            }
            
            _this.shiftInterval = setInterval(function() {
                if(ind != this.currentIndex && ind < 4 && ind > -1) {
                    if(ind < _this.currentIndex) {
                        _this.set(_this.currentIndex - 1, ind == _this.currentIndex - 1);
                    } else if(ind > _this.currentIndex) {
                        _this.set(_this.currentIndex + 1, ind == _this.currentIndex + 1);
                    } else {
                        clearInterval(_this.shiftInterval);
                    }
                } else {
                    clearInterval(_this.shiftInterval);
                }
            }, 200);
        }
    },
    startTutorial: function() {
        this.animateTo(this.correctIndex);
    },
    setTimer: function() {
        var time = Math.ceil(this.config.time);
        
        actionProgress.removeClass('failed disabled');
        actionProgressText.text(time);
        actionProgressBar.css('stroke-dashoffset', '0em');
        
        var _this = this;
        
        if(this.interval) clearInterval(this.interval);
        
        var startTime = new Date() - _this.timerElapsed;
        this.interval = setInterval(function() {
            _this.timerElapsed = (new Date()) - startTime;
            var prog = _this.timerElapsed / (time * 1000);
            
            if(prog < 1) {
                actionProgressBar.css('stroke-dashoffset', '-' + prog + 'em');
                actionProgressText.text(Math.ceil(time - (_this.timerElapsed / 1000)));
            } else {
                actionProgress.addClass('failed');
                actionProgressBar.css('stroke-dashoffset', '-1em');
                actionProgressText.text('');
                clearInterval(_this.interval);
                _this.onFailure();
            }
        }, 100);
    },
    pauseTimer: function() {
        clearInterval(this.interval);
    },
    resumeTimer: function() {
        this.setTimer();
    },
    resetTimer: function() {
        clearInterval(this.interval);
        this.timerElapsed = 0;
    },
    onClose: function() {
        var lightingButtons = $('#lighting-control .decision-tree-button');
        for(var i = 0; i < lightingButtons.length; i++) {
            $(lightingButtons[i]).removeClass('hint').off('click');
        }
        
//        $('#shifter-spin').removeClass('spin left');
        
        this.controlsEnabled = false;
        this.isActive = false;
        
        currentImpediment = {};
        controlsEnabled = true;
        mainControlsEnabled = true;
        $(dtButtonClass).removeClass('active');
        actionProgress.removeClass('failed complete').addClass('disabled');
        
        $('#lighting-overlay').addClass('hidden');
        
        if(this.action.onHide && typeof this.action.onHide === 'function') {
            this.action.onHide();
        }
        
        currentVideo.play();
    },
    onFailure: function() {
        dev.log('Shifter Control: failure during ' + this.mode);
        
        actionProgress.removeClass('failed complete').addClass('disabled');
        if(this.interval) clearInterval(this.interval);
        
        var _this = this;
        switch(this.mode) {
            case 'normal':
                this.controlsEnabled = false;
                cueManager._emitImpede({
                    type: 'custom',
                    customType: 'retry',
                    message: defaultOverlayFails[0].message,
                    audio: defaultOverlayFails[0].audio,
                    buttonText: 'Try Again',
                    onHide: function() {
                        _this.reset();
                        _this.mode = 'retry';
                        _this.controlsEnabled = true;
                        _this.resetTimer();
                        _this.setTimer();
                    }
                });
                break;
            case 'retry':
                this.controlsEnabled = false;
                cueManager._emitImpede({
                    type: 'custom',
                    customType: 'retry',
                    message: defaultOverlayFails[1].message,
                    audio: defaultOverlayFails[1].audio,
                    buttonText: 'Try Again',
                    onHide: function() {
                        _this.reset();
                        _this.mode = 'hint';
                        _this.showHints();
                        _this.controlsEnabled = true;
                        _this.resetTimer();
                        _this.setTimer();
                    }
                });
                break;
            case 'hint':
                this.controlsEnabled = false;
                var lightingButtons = $('#lighting-control .decision-tree-button');
                for(var i = 0; i < lightingButtons.length; i++) {
                    $(lightingButtons[i]).removeClass('hint').off('click');
                }
                cueManager._emitImpede({
                    type: 'custom',
                    customType: 'retry',
                    message: defaultOverlayFails[2].message,
                    audio: defaultOverlayFails[2].audio,
                    buttonText: 'Play',
                    onHide: function() {
                        _this.reset();
                        _this.mode = 'tutorial';
                        _this.startTutorial();
                    }
                });
                break;
        }
    },
    onSuccess: function() {
        dev.log('Lighting Control: success during ' + this.mode);
        
        this.controlsEnabled = false;
        
        actionProgress.removeClass('failed complete').addClass('disabled');
        if(this.interval) clearInterval(this.interval);
        
        var lightingButtons = $('#lighting-control .decision-tree-button');
        for(var i = 0; i < lightingButtons.length; i++) {
            $(lightingButtons[i]).removeClass('hint').off('click');
        }
        
        var _this = this;
        $('#lighting-overlay .overlay__button').removeClass('disabled').click(function() {
            _this.onClose();
        });
    },
    addPoints: function(points, target) {
        var targetOffset = target.offset();
        var pointLocation = [targetOffset.left + target.width() / 2, targetOffset.top];
        
        addPoints(points, pointLocation);
    }
};

var cameraAnimation = {
    init: function(config) {
        this.keys = config.keys;
        this.duration = config.duration;
        this.delay = config.delay ? config.delay : 0;
        this.onEndCustom = (config.onEnd && typeof config.onEnd === 'function') ? config.onEnd : null;
        this.keyIndex = 0;
        
        if(config.to) {
            this.keys = [
                {
                    progress: 0,
                    alpha: cameraTarget.alpha,
                    beta: cameraTarget.beta,
                    radius: cameraTarget.radius,
                    blur: lensEffect._dofAperture,
                    darkness: lensEffect._dofDarken
                },
                $.extend({ progress: 1 }, config.to)
            ];
        }
        
        if(this.keys && this.keys.length > 1) {
            this.startKey = this.keys[0];
            this.endKey = this.keys[1];
            
            for(var i = 0; i < this.keys.length; i++) {
                this.keys[i].time = this.duration * this.keys[i].progress;
                
                if(i < this.keys.length - 1) {
                    this.keys[i].duration = this.duration * (this.keys[i + 1].progress - this.keys[i].progress);
                    
                    this.keys[i].dAlpha = this.keys[i + 1].alpha - this.keys[i].alpha;
                    this.keys[i].dBeta = this.keys[i + 1].beta - this.keys[i].beta;
                    this.keys[i].dRadius = this.keys[i + 1].radius - this.keys[i].radius;
                    this.keys[i].dBlur = this.keys[i + 1].blur - this.keys[i].blur;
                    this.keys[i].dDarkness = this.keys[i + 1].darkness - this.keys[i].darkness;
                } else {
                    this.keys[i].duration = 0;
                }
            }
            
            dev.log('Camera animation init');
//            dev.log('Camera keys: ' + JSON.stringify(this.keys));
        }
    },
    start: function(config) {
        this.init(config);
        this.startTime = currentVideo.currentTime + this.delay;
        this.isActive = true;
        this.playing = false;
    },
    clear: function() {
        this.isActive = false;
        this.playing = false;
        this.keys = null;
        this.duration = null;
    },
    onEnd: function() {
        // Set camera values to last key
        cameraTarget = {
            alpha:  this.keys[this.keys.length - 1].alpha,
            beta:   this.keys[this.keys.length - 1].beta,
            radius: this.keys[this.keys.length - 1].radius,
        };
        cameraBlur = this.keys[this.keys.length - 1].blur;
        lensEffect.setDarkenOutOfFocus(this.keys[this.keys.length - 1].darkness);
        
        this.clear();
        
        if(this.onEndCustom) this.onEndCustom();
    },
    renderLoop: function(currentVideoTime) {
        var currentAnimTime = currentVideoTime - this.startTime;
        if(currentAnimTime > this.duration) {
            this.onEnd();
            this.playing = false;
            return;
        }
        if(this.startKey && this.endKey) {
            if(this.startKey.time <= currentAnimTime && currentAnimTime < this.endKey.time) {
                if(!this.startKey.played) {
                    this.startKey.played = true;
                    this.playing = true;
//                    dev.log('Camera animation startKey: ' + JSON.stringify(this.startKey));
//                    dev.log('Camera animation endKey: ' + JSON.stringify(this.endKey));
                    
//                    camera.alpha = this.startKey.alpha;
//                    camera.beta = this.startKey.beta;
//                    camera.radius = camera.lowerRadiusLimit = camera.upperRadiusLimit = this.startKey.radius;
//                    lensEffect.setAperture(this.startKey.blur);
                }
                var t = (currentAnimTime - this.startKey.time) / this.startKey.duration;
                var y = easeInOut(t);
                
                camera.alpha = this.startKey.alpha + y * this.startKey.dAlpha;
                camera.beta = this.startKey.beta + y * this.startKey.dBeta;
                
//                if(this.startKey.alpha != this.endKey.alpha) {
////                    var val = this.startKey.alpha + y * this.startKey.dAlpha;
//                    camera.alpha = this.startKey.alpha + y * this.startKey.dAlpha;
//                }
//                if(this.startKey.beta != this.endKey.beta) {
////                    var val = this.startKey.beta + y * this.startKey.dBeta;
//                    camera.beta = this.startKey.beta + y * this.startKey.dBeta;
//                }
                
                if(this.startKey.radius != this.endKey.radius) {
                    camera.radius = camera.lowerRadiusLimit = camera.upperRadiusLimit = this.startKey.radius + y * this.startKey.dRadius;
                }
                
                if(this.startKey.blur != this.endKey.blur) {
//                    var val = this.startKey.blur + y * this.startKey.dBlur;
                    lensEffect.setAperture(this.startKey.blur + y * this.startKey.dBlur);
                }
                if(this.startKey.darkness != this.endKey.darkness) {
//                    var val = this.startKey.darkness + y * this.startKey.dDarkness;
                    lensEffect.setDarkenOutOfFocus(this.startKey.darkness + y * this.startKey.dDarkness);
                }
            } else if (this.startKey.played) {
                this.keyIndex++;
                if(this.keyIndex + 1 < this.keys.length) {
                    this.startKey = this.keys[this.keyIndex];
                    this.endKey = this.keys[this.keyIndex + 1];
//                    
//                    this.startKey.time = this.duration * this.startKey.progress;
//                    this.startKey.duration = this.duration * (this.endKey.progress - this.startKey.progress);
//            
//                    this.startKey.dAlpha = this.endKey.alpha - this.startKey.alpha;
//                    this.startKey.dBeta = this.endKey.beta - this.startKey.beta;
//                    this.startKey.dRadius = this.endKey.radius - this.startKey.radius;
//                    this.startKey.dBlur = this.endKey.blur - this.startKey.blur;
                } else {
                    this.startKey = null;
                    this.endKey = null;
                }
            }
        }
    }
};

var steeringAnimation = {
    keyIndex: 0,
    thisKey: {},
    nextKey: {},
    init: function() {
        this.keyIndex = 0,
        this.thisKey,
        this.nextKey;
        if(extraCues.steering && extraCues.steering.length > 1) {
            for(var i = 0; i < extraCues.steering.length; i++) {
                if(i < extraCues.steering.length - 1) {
                    extraCues.steering[i].duration = extraCues.steering[i + 1].time - extraCues.steering[i].time;
                    extraCues.steering[i].dr = extraCues.steering[i + 1].radians - extraCues.steering[i].radians;
                }
            }
            this.thisKey = extraCues.steering[0];
            this.nextKey = extraCues.steering[1];
            this.isActive = true;
        }
    },
    renderLoop: function(currentVideoTime) {
        if(this.thisKey && this.nextKey) {
            if(this.thisKey.time <= currentVideoTime && currentVideoTime < this.nextKey.time) {
                if(!this.thisKey.played) {
                    dev.log('Steering key started, setting played to true');
                    this.thisKey.played = true;
                }
                if(this.thisKey.radians != this.nextKey.radians) {
                    var t = (currentVideoTime - this.thisKey.time) / this.thisKey.duration;
                    var y = easeInOut(t);
                    var turn = this.thisKey.radians + y * this.thisKey.dr;
                    this.setSteeringWheel(turn);
                }
            } else if (this.thisKey.played) {
                dev.log('Steering key finished, moving to next');
                this.keyIndex++;
                if(this.keyIndex + 1 < extraCues.steering.length) {
                    this.thisKey = extraCues.steering[this.keyIndex];
                    this.nextKey = extraCues.steering[this.keyIndex + 1];
                } else {
                    this.thisKey = null;
                    this.nextKey = null;
                }
            }
        } else {
            dev.log('Steering animation keys finished');
            this.isActive = false;
            this.ended = true;
            this.endedOnPath = currentPathId;
        }
    },
    seek: function(toTime) {
        // if last steering cue has been hit, but we then fail the window in which the steering completed, set steering back to its last step and reactivate. Then, search for cues as below.
        if(this.ended && this.endedOnPath == currentPathId) {
            this.keyIndex = extraCues.steering.length - 2;
            this.thisKey = extraCues.steering[this.keyIndex];
            this.nextKey = extraCues.steering[this.keyIndex + 1];
            this.thisKey.played = false;
            this.isActive = true;
        }
        // if we've seeked, and new time is outside of current steering cue bounds, search cues until finding the cues that encompass the new time
        if(this.nextKey && toTime > this.nextKey.time) {
            for(var i = this.keyIndex; i < extraCues.steering.length; i++) {
                if(extraCues.steering[i].time > toTime) {
                    this.keyIndex = i - 1;
                    this.thisKey = extraCues.steering[this.keyIndex];
                    this.nextKey = extraCues.steering[this.keyIndex + 1];
                    if(this.thisKey.dr == 0) {
                        this.setSteeringWheel(this.thisKey.radians);
                    } else {
//                        this.renderLoop(toTime);
                        var t = (toTime - this.thisKey.time) / this.thisKey.duration;
                        var y = easeInOut(t);
                        var turn = this.thisKey.radians + y * this.thisKey.dr;
                        this.setSteeringWheel(turn);
                    }
                    dev.log('Found next steering cue (forward): ' + JSON.stringify(this.thisKey));
                    break;
                }
            }
        } else if(this.thisKey && this.thisKey.played && toTime < this.thisKey.time) {
            for(var i = this.keyIndex; i < extraCues.steering.length; i--) {
                extraCues.steering[i].played = false;
                if(extraCues.steering[i].time <= toTime) {
                    this.keyIndex = i;
                    this.thisKey = extraCues.steering[this.keyIndex];
                    this.nextKey = extraCues.steering[this.keyIndex + 1];
                    this.isActive = true;
                    if(this.thisKey.dr == 0) {
                        this.setSteeringWheel(this.thisKey.radians);
                    } else {
//                        this.renderLoop(toTime);
                        var t = (toTime - this.thisKey.time) / this.thisKey.duration;
                        var y = easeInOut(t);
                        var turn = this.thisKey.radians + y * this.thisKey.dr;
                        this.setSteeringWheel(turn);
                    }
                    dev.log('Found next steering cue (backward): ' + JSON.stringify(this.thisKey));
                    break;
                }
            }
        }
    },
    setSteeringWheel: function(amount) {
        steeringWheelMesh.rotation.z = -amount;
    }
};

var steeringWheelVibration = {
    start: function() {
        if(this.interval) clearInterval(this.interval);
        wheelVibrateGraphicMat.opacityTexture = wheelVibrateFrameTextures[0];
        wheelVibrateGraphicLeft.visibility = 1;
        wheelVibrateGraphicRight.visibility = 1;
        
        var frameInd = 0;
        
        this.interval = setInterval(function() {
            frameInd++;
            if(frameInd > 2) {
                frameInd = 0;
            }
            wheelVibrateGraphicMat.opacityTexture = wheelVibrateFrameTextures[frameInd];
        }, 140);
    },
    stop: function() {
        wheelVibrateGraphicLeft.visibility = 0;
        wheelVibrateGraphicRight.visibility = 0;
        if(this.interval) clearInterval(this.interval);
    }
};

var steeringWheelAssist = {
    start: function(direction) {
        dev.log('Steer Assist arrow spin (' + direction + ')');
        
        if(this.interval) clearInterval(this.interval);
        wheelSpinGraphic.visibility = 0;
        wheelSpinGraphicParent.rotation.z = Math.PI + .5;
        wheelSpinGraphicParent.rotation.y = (direction.toLowerCase() === 'right') ? Math.PI / 2 : -Math.PI / 2;
        var time = 600;
        var intTime = 50;
        var progress = 0;
        var runCount = 0;
        var _this = this;
        this.interval = setInterval(function() {
            runCount++;
            progress = runCount * (intTime / time);
            
            if(progress <= 1) {
                wheelSpinGraphicParent.rotation.z = Math.PI + .5 - progress;
                if(progress < .5) {
                    wheelSpinGraphic.visibility = progress * 2;
                } else {
                    wheelSpinGraphic.visibility = 2 * (1 - progress);
                }
            } else {
                clearInterval(_this.interval);
            }
        }, intTime);
    }
};

//speedometer

//touchscreenUI

var overlayTimer = {
    init: function(config) {
        if(config && config.time) {
            this.config = config;
        } else {
            this.config = null;
            console.warn('Timer needs config!');
            return;
        }
        
        this.time = Math.ceil(this.config.time);
        
        actionProgress.removeClass('failed disabled');
        if(this.config.levels) {
            actionProgress.addClass('levels');
        } else {
            actionProgress.removeClass('levels');
        }
        actionProgressText.text(this.time);
        actionProgress.find('.progress-radial__circle').css('stroke-dashoffset', '');
        
        this.reset();
    },
    start: function() {
        dev.log('Setting overlay timer to ' + (this.time - this.timerElapsed) + 's');
        
        if(this.interval) clearInterval(this.interval);
        this.startTime = new Date() - this.timerElapsed;
        
        var _this = this;
        
        this.interval = setInterval(function() {
            _this.render.call(_this);
        }, 100);
    },
    render: function() {
        this.timerElapsed = (new Date()) - this.startTime;
        this.progress = this.timerElapsed / (this.time * 1000);

        if(this.progress < 1) {
            actionProgressText.text(Math.ceil(this.time - (this.timerElapsed / 1000)));
            if(this.config.levels) {
                if(this.progress >= .6667) {
                    actionProgressBarL3.css('stroke-dashoffset', '-' + this.progress + 'em');
                    if(this.level !== 3) {
                        this.level = 3;
                        actionProgress.attr('data-level', 3);
                    }
                } else if(this.progress >= .3333) {
                    actionProgressBarL2.css('stroke-dashoffset', '-' + this.progress + 'em');
                    if(this.level !== 2) {
                        this.level = 2;
                        actionProgress.attr('data-level', 2);
                    }
                } else {
                    actionProgressBar.css('stroke-dashoffset', '-' + this.progress + 'em');
                }
            } else {
                actionProgressBar.css('stroke-dashoffset', '-' + this.progress + 'em');
            }
        } else {
            this.onComplete.call(this);
        }
    },
    pause: function() {
        clearInterval(this.interval);
    },
    resume: function() {
        this.start();
    },
    reset: function() {
        clearInterval(this.interval);
        this.timerElapsed = 0;
        this.level = 1;
        actionProgress.attr('data-level', 1);
    },
    disable: function() {
        actionProgress.removeClass('failed complete levels');
        actionProgress.addClass('disabled');
        actionProgressText.text('');
        actionProgress.find('.progress-radial__circle').css('stroke-dashoffset', '');
        this.reset();
    },
    onComplete: function() {
        this.disable();
        clearInterval(this.interval);
        if(this.config.onComplete && typeof this.config.onComplete === 'function') {
            this.config.onComplete();
        }
    }
};

var titleScreen = function() {
    $('#title-screen-overlay').removeClass('hidden');
};

var autoPilotIcon = {
    show: function(btn, type) {
        var iconId = btn.split('-')[1] + '-' + (type ? type : 'manual');
        $('#auto-pilot-drive-icon').attr('data-icon', iconId).removeClass('hidden');
    },
    hide: function() {
        $('#auto-pilot-drive-icon').addClass('hidden');
    }
};

var midCueIndex = 0;

function windowOpenHandler(wID) {
    dev.log('Window open: ' + wID + ', ' + singleVideoPaths[wID].mode + ' mode');
    var thisWindow = singleVideoWindows[wID];
    var cue = singleVideoPaths[wID];
    
    if(cue.mode && cue.mode == 'autopilot') {
        $(dtButtonClass).removeClass('selected prompt').addClass('disabled');
    } else if(cue.mode && cue.mode == 'tutorial') {
        $(dtButtonClass).removeClass('selected prompt').addClass('disabled');
        $('#mode-notification').removeClass('hidden');
    } else if(thisWindow.triggers.length > 0) {
        actionProgress.removeClass('disabled');
        timerEnabled = true;
        controlsEnabled = true;
    }

    for(var t = 0; t < thisWindow.triggers.length; t++) {
        var trig = thisWindow.triggers[t];
        for(var i = 0; i < trig.inputs.length; i++) {
            var thisInput = trig.inputs[i],
                pickInput,
                btnInput;
            
            if(trig.action.type == 'seek' || trig.action.type == 'seekNow') {
                dev.log('windowOpenHandler, success trigger at ' + t + ':');
                dev.log(trig);
                if(thisInput.indexOf('btn') == 0) {
                    if(cue.mode && (cue.mode == 'tutorial' || cue.mode == 'autopilot')) {
//                        var dur = (cue.endTime - cue.startTime) / 2 * 1000;
                        var thisHoldInput = thisInput;
                        var midTime = cue.startTime + (cue.endTime - cue.startTime) / 2;
                        cueManager.addCue('ax-' + midCueIndex, {
                            startTime: midTime,
                            endTime: midTime,
                            onEnterCustom: function() {
                                if(cue.mode == 'autopilot') {
//                                    autoPilotIcon.show(thisInput, 'manual');
                                } else {
                                    $('#' + thisHoldInput).removeClass('prompt').addClass('selected');
                                }
                            }
                        }, wID);
                        midCueIndex++;
//                        setTimeout(function() {
//                            $('#' + thisHoldInput).removeClass('prompt').addClass('selected');
//                        }, dur);
                    } else {
                        $('#' + thisInput).addClass('prompt');
                    }
                    break;
                } else if(thisInput.indexOf('pick') == 0) {
                    if(cue.mode && (cue.mode == 'tutorial' || cue.mode == 'autopilot')) {
                        var midTime = cue.startTime + (cue.endTime - cue.startTime) / 2;
                        cueManager.addCue('ax-' + midCueIndex, {
                            startTime: midTime,
                            endTime: midTime,
                            onEnterCustom: function() {
                                selectClickable(getClickable(thisInput.slice(5)), true);
                            }
                        }, wID);
                        midCueIndex++;
//                        var dur = (cue.endTime - cue.startTime) / 2 * 1000;
//                        setTimeout(function() {
//                            selectClickable(getClickable(thisInput.slice(5)), true);
//                        }, dur);
                    } else {
                        blinkClickable(getClickable(thisInput.slice(5)));
                    }
                    break;
                }
            }
        }
    }
}
function triggerSetHandler(trigger) {
    dev.log('\u2192 triggerSet(' + keydown[0] + ")");
    $(dtButtonClass).removeClass('prompt');
    var passed = (trigger.action.type == 'seek' || trigger.action.type == 'seekNow');
    var progClass = passed ? 'complete' : 'failed';
    actionProgress.addClass(progClass);
    
    if(passed) {
        var pointLocation;

        for(var i = 0; i < trigger.inputs.length; i++) {
            if(trigger.inputs[i].indexOf('btn') == 0) {
                var btn = $('#' + trigger.inputs[i]);
                btn.addClass('selected');
                var btnOffset = btn.offset();
                pointLocation = [btnOffset.left + btn.width() / 2, btnOffset.top];
            } else if(trigger.inputs[i].indexOf('pick') == 0) {
                pointLocation = pickLocation;
            }
        }
        
        var points = defaultPoints.max;
        
        if(singleVideoPaths[currentPathId].mode) {
            if(singleVideoPaths[currentPathId].mode == 'retry') {
                points = defaultPoints.retry;
            } else if(singleVideoPaths[currentPathId].mode == 'tutorial' || singleVideoPaths[currentPathId].mode == 'autopilot') {
                points = 0;
            }
        }
            
        addPoints(points, pointLocation);
    }
}
function seekToTimeHandler(time) {
    dev.log('\u2192 seekToTime(' + time + ")");
    actionProgress.removeClass('complete failed').addClass('disabled');
    actionProgressText.text('');
    timerEnabled = false;
    steeringAnimation.seek(time);
    currentVideo.currentTime = time;
    $(dtButtonClass).removeClass('active selected prompt');
    deselectClickables(true);
//    if(isPlaying('audio')) {
//        $.each($('audio'), function(ind, val) {
//            val.pause();
//        });
//        $('audio').attr('src', '');
//    }
    
    if(singleVideoPaths[currentPathId].mode && singleVideoPaths[currentPathId].mode == 'autopilot') {
//        autoPilotIcon.hide();
    } else if(singleVideoPaths[currentPathId].mode && singleVideoPaths[currentPathId].mode == 'tutorial') {
        $('#mode-notification').addClass('hidden');
        
        for(var i = 0; i < enabledControls.length; i++) {
            $('#' + enabledControls[i]).removeClass('disabled');
        }
    }
//    $('[data-dt-button-group]').addClass('hidden');
}
function completionHandler(cueID) {
    dev.log('\u2192 completion(' + cueID + ")");
    currentVideo.pause();
    
    $(dtButtonClass).removeClass('active selected prompt').addClass('disabled');
//    $('[data-dt-button-group]').addClass('hidden');
    
    if(singleVideoPaths[cueID] && singleVideoPaths[cueID].message) {
        cueManager._emitImpede({
            type: 'completion',
            header: singleVideoPaths[cueID].message ? singleVideoPaths[cueID].message : 'Episode Complete'
        });
    }
    
    if(isLMS && setEpisode !== 'Episode7') {
        window.parent.simLib.onEpisodeComplete({ score: currentPoints, scorePossible: maxPoints });
    }
}

function impedeHandler(action) {
    dev.log('\u2192 impede(' + action.type + ")");
    
    switch (action.type) {
        case 'directional':
            if(action.force || !(singleVideoPaths[currentPathId].mode && singleVideoPaths[currentPathId].mode == 'tutorial')) {
                currentVideo.pause();
                
                currentImpediment = action;
                controlsEnabled = false;
                $(dtButtonClass).removeClass('active');
                
                if(action.audio) playAudio(action.audio, 1);

                directionalOverlay.attr('data-overlay-type', action.type);
                directionalOverlay.find('.overlay__message').html(action.message);
                directionalOverlay.find('.overlay__message').css('text-align', action.centerMessage ? 'center' : '');
                directionalOverlay.find('.overlay__title').text(action.header ? action.header : '');
                if(action.image) {
                    directionalOverlay.find('.overlay__image')
                        .attr('src', action.image)
                        .removeClass('hidden');
                } else {
                    directionalOverlay.find('.overlay__image').addClass('hidden');
                }
                
                if(action.hideButton) {
                    directionalOverlay.find('.overlay__button').addClass('hidden');
                } else {
                    directionalOverlay.find('.overlay__button')
                        .text('Continue')
                        .removeClass('hidden')
                        .on('click', function(){
                        directionalOverlay.addClass('hidden');

                        dev.log('Directional impediment passed');

                        if(currentImpediment.onHide && typeof currentImpediment.onHide === 'function') {
                            currentImpediment.onHide();
                        }

                        currentVideo.play();

                        directionalOverlay.find('.overlay__button').off('click');
                        controlsEnabled = true;

                        if(currentImpediment.then && typeof currentImpediment.then === 'function') {
                            currentImpediment.then();
                        }

                        currentImpediment = {};
                    });
                }
                directionalOverlay.removeClass('hidden');
            }
            
            break;
        case 'custom':
            
            if(action.onShow && typeof action.onShow === 'function') {
                action.onShow();
            }
            
            if(action.audio) playAudio(action.audio, 1);

            directionalOverlay.attr('data-overlay-type', action.customType);
            directionalOverlay.find('.overlay__message').html(action.message);
            directionalOverlay.find('.overlay__message').css('text-align', action.centerMessage ? 'center' : '');
            directionalOverlay.find('.overlay__title').text(action.header ? action.header : '');
            if(action.image) {
                directionalOverlay.find('.overlay__image')
                    .attr('src', action.image)
                    .removeClass('hidden');
            } else {
                directionalOverlay.find('.overlay__image').addClass('hidden');
            }
            
            if(action.hideButton) {
                directionalOverlay.find('.overlay__button').addClass('hidden');
            } else {
                directionalOverlay.find('.overlay__button')
                    .text(action.buttonText ? action.buttonText : 'Continue')
                    .removeClass('hidden')
                    .on('click', function() {
                    directionalOverlay.addClass('hidden');
                    if(action.audio) $('#audio-1')[0].pause();
                    if(action.onHide && typeof action.onHide === 'function') {
                        action.onHide();
                    }
                    directionalOverlay.find('.overlay__button').off('click');
                });
            }
            directionalOverlay.removeClass('hidden');
            
            break;
        case 'informationDisplay':
            informationDisplay.open(action);
            break;
        case 'shifterControl':
            shifterControl.open(action);
            break;
        case 'lightingControl':
            lightingControl.open(action);
            break;
        case 'retry':
            currentVideo.pause();
            
            currentImpediment = action;
            controlsEnabled = false;
            $(dtButtonClass).removeClass('active');
            
//            if(action.audio) playAudio(action.audio, 1);
            playAudio(defaultDrivingFails[0].audio, 1);

            directionalOverlay.attr('data-overlay-type', action.type);
            directionalOverlay.find('.overlay__message').html(action.message);
            directionalOverlay.find('.overlay__message').css('text-align', action.centerMessage ? 'center' : '');
            directionalOverlay.find('.overlay__title').text(action.header ? action.header : '');
            if(action.image) {
                directionalOverlay.find('.overlay__image')
                    .attr('src', action.image)
                    .removeClass('hidden');
            } else {
                directionalOverlay.find('.overlay__image').addClass('hidden');
            }
            if(action.hideButton) {
                directionalOverlay.find('.overlay__button').addClass('hidden');
            } else {
                directionalOverlay.find('.overlay__button')
                    .text('Try Again')
                    .removeClass('hidden')
                    .on('click', function() {
                    directionalOverlay.addClass('hidden');
                    $('#audio-1')[0].pause();

                    dev.log('Retry initiated. Returning to path ' + currentPathId);

                    if(currentImpediment.onHide && typeof currentImpediment.onHide === 'function') {
                        currentImpediment.onHide();
                    }

                    singleVideoPaths[currentPathId].done = false;
                    singleVideoPaths[currentPathId].started = false;
                    singleVideoPaths[currentPathId].mode = 'retry';
                    singleVideoPaths[currentPathId].retried = singleVideoPaths[currentPathId].retried ? (singleVideoPaths[currentPathId].retried + 1) : 1;

                    singleVideoWindows[currentPathId].inaction = $.extend(true, { type: 'tutorial' }, singleVideoWindows[currentPathId].tutorial);

                    for(var i = 0; i < singleVideoWindows[currentPathId].triggers.length; i++) {
                        if(singleVideoWindows[currentPathId].triggers[i].action.type == 'retry') {
                            singleVideoWindows[currentPathId].triggers[i].action = $.extend(true, { type: 'tutorial' }, singleVideoWindows[currentPathId].tutorial);
                        }
                    }

                    cueManager._performAction({
                        type: 'seekNow',
                        toPath: currentPathId
                    });

                    if(singleVideoPaths[previousPathId] && singleVideoPaths[previousPathId].onTriggerCustom && typeof singleVideoPaths[previousPathId].onTriggerCustom === 'function') {
                        singleVideoPaths[previousPathId].onTriggerCustom();
                    }

                    currentVideo.play();

                    directionalOverlay.find('.overlay__button').off('click');
                    currentImpediment = {};
                    controlsEnabled = true;
                });
            }
            directionalOverlay.removeClass('hidden');
            
            break;
        case 'tutorial':
            currentVideo.pause();
            
            currentImpediment = action;
            controlsEnabled = false;
            $(dtButtonClass).removeClass('active');
            
//            if(action.audio) playAudio(action.audio, 1);
            playAudio(defaultDrivingFails[1].audio, 1);
            
            directionalOverlay.attr('data-overlay-type', action.type);
            directionalOverlay.find('.overlay__message').html(action.message);
            directionalOverlay.find('.overlay__message').css('text-align', action.centerMessage ? 'center' : '');
            directionalOverlay.find('.overlay__title').text(action.header ? action.header : '');
            if(action.image) {
                directionalOverlay.find('.overlay__image')
                    .attr('src', action.image)
                    .removeClass('hidden');
            } else {
                directionalOverlay.find('.overlay__image').addClass('hidden');
            }
            if(action.hideButton) {
                directionalOverlay.find('.overlay__button').addClass('hidden');
            } else {
                directionalOverlay.find('.overlay__button')
                    .text('Play')
                    .removeClass('hidden')
                    .on('click', function() {
                    directionalOverlay.addClass('hidden');
                    $('#audio-1')[0].pause();

                    dev.log('Tutorial initiated, on path ' + currentPathId);

                    if(currentImpediment.onHide && typeof currentImpediment.onHide === 'function') {
                        currentImpediment.onHide();
                    }

                    singleVideoPaths[currentPathId].done = false;
                    singleVideoPaths[currentPathId].started = false;
                    singleVideoPaths[currentPathId].mode = 'tutorial';

                    var nextAction = $.extend(true, {}, singleVideoWindows[currentPathId].inaction);
                    nextAction.type = 'seekNow';

                    singleVideoWindows[currentPathId].inaction = nextAction;

                    cueManager._performAction({
                        type: 'seekNow',
                        toPath: currentPathId
                    });

                    currentVideo.play();
    //                currentVideo.playbackRate = .5;

                    directionalOverlay.find('.overlay__button').off('click');
                    currentImpediment = {};
                    controlsEnabled = true;
                });
            }
            directionalOverlay.removeClass('hidden');
            
            break;
        case 'audio':
            if(action.force || !(singleVideoPaths[currentPathId].mode && singleVideoPaths[currentPathId].mode == 'tutorial')) {
                currentVideo.pause();
                
                currentImpediment = action;
                controlsEnabled = false;
                $(dtButtonClass).removeClass('active');
                
                if(action.image) {
                    directionalOverlay.find('.overlay__image')
                        .attr('src', action.image)
                        .removeClass('hidden');
                } else {
                    directionalOverlay.find('.overlay__image').addClass('hidden');
                }
                
                if(action.audio) {
                    var audioTrack = $('#audio-1');
                    audioTrack.attr('src', action.audio);
                    directionalOverlay.find('.overlay__button')
                        .text('Continue')
                        .on('click', function() {
                        audioTrack[0].play();
                        directionalOverlay.find('.overlay__button')
                            .off('click').addClass('hidden');
                        audioTrack.on('ended', function() {
                            audioTrack.attr('src', '');
                            audioTrack.off('ended');
                            directionalOverlay.find('.overlay__button')
                                .text('Continue')
                                .removeClass('hidden')
                                .on('click', function(){
                                directionalOverlay.addClass('hidden');

                                dev.log('Directional impediment passed');

                                if(currentImpediment.onHide && typeof currentImpediment.onHide === 'function') {
                                    currentImpediment.onHide();
                                }

                                currentVideo.play();

                                directionalOverlay.find('.overlay__button').off('click');
                                controlsEnabled = true;

                                if(currentImpediment.then && typeof currentImpediment.then === 'function') {
                                    currentImpediment.then();
                                }

                                currentImpediment = {};
                            });
                        });
                    });
                }

                directionalOverlay.attr('data-overlay-type', action.type);
                directionalOverlay.find('.overlay__message').html(action.message);
                directionalOverlay.find('.overlay__message').css('text-align', action.centerMessage ? 'center' : '');
                directionalOverlay.find('.overlay__title').text(action.header ? action.header : '');
                
                directionalOverlay.removeClass('hidden');
            }
            
            break;
        case 'softFail':
            currentVideo.pause();
            
            currentImpediment = action;
            controlsEnabled = false;
            $(dtButtonClass).removeClass('active');
            
            if(action.audio) playAudio(action.audio, 1);
            
            directionalOverlay.attr('data-overlay-type', action.type);
            directionalOverlay.find('.overlay__message').html(action.message);
            directionalOverlay.find('.overlay__title').text(action.header ? action.header : '');
            directionalOverlay.find('.overlay__button').text('Continue').on('click', function() {
                directionalOverlay.addClass('hidden');
                $('#audio-1')[0].pause();
                
                dev.log('Impediment passed. Continuing to path ' + currentImpediment.toPath);
                
                if(currentImpediment.onHide && typeof currentImpediment.onHide === 'function') {
                    currentImpediment.onHide();
                }
                
                cueManager._performAction({
                    type: 'seekNow',
                    toPath: currentImpediment.toPath
                });
                
                currentVideo.play();
                
                directionalOverlay.find('.overlay__button').off('click');
                currentImpediment = {};
                controlsEnabled = true;
            });
            directionalOverlay.removeClass('hidden');
            
            break;
        case 'completion':
            currentVideo.pause();
            
            currentImpediment = action;
            controlsEnabled = false;
            $(dtButtonClass).removeClass('active');
            
            directionalOverlay.attr('data-overlay-type', action.type);
            directionalOverlay.find('.overlay__title').text(action.header ? action.header : '');
            if(setEpisode === 'Episode7') {
                directionalOverlay.find('.overlay__button')
                    .text('WATCH OUTRO')
                    .show()
                    .on('click', function() {
                        stopRenderLoop();
                    
                        var outroVideoSrc = isLowestPerformance ? 'Episode7_outro_lowest.mp4' : isLowPerformance ? 'Episode7_outro_halfRes.mp4' : 'Episode7_outro.mp4';
                        var outroVideo = $('.intro-overlay').find('video')[0];
                        $(outroVideo).find('source').attr('src', singleVideo.fileBase + outroVideoSrc);
                        outroVideo.load();
                        $('.intro-overlay').show().removeClass('hidden');
                        outroVideo.play();
                        $(outroVideo).off('ended').on('ended', function() {
                            dev.log('Outro video ended');
                            if(isLMS) {
                                window.parent.simLib.onEpisodeComplete({ score: currentPoints, scorePossible: maxPoints });
                            }
                        });
                    });
            } else {
                directionalOverlay.find('.overlay__button').hide();
            }
            directionalOverlay.removeClass('hidden');
            
//            if(isLMS) {
//                directionalOverlay.find('.overlay__button')
//                    .text('Episode Main Menu')
//                    .css('align-self', 'center')
//                    .click(function() {
//                        window.parent.simLib.onEpisodeComplete();
//                    })
//                    .show();
//            }
            
            break;
        default:
            controlsEnabled = false;
            
            currentVideo.pause();
            
            dev.warn('Not a valid impediment action');
            break;
    }
}

function cuesReadyHandler() {
    var onLoadedMetadata = function() {
        currentVideo.currentTime = 0;
        var videoProgress = $('.video-progress');
        var videoDuration = currentVideo.duration;
        for(var i = 0; i < singleVideoCues.length; i++) {
            var startMarker =   $('<div class="video-progress__marker video-progress__marker--start" style="left: ' + singleVideoCues[i].startTime / videoDuration * 100 + '%"><span>' + singleVideoCues[i].name + '</span></div>');
            var endMarker =     $('<div class="video-progress__marker video-progress__marker--end" style="left: ' + singleVideoCues[i].endTime / videoDuration * 100 + '%"></div>');
            videoProgress.append(startMarker);
            videoProgress.append(endMarker);
        }
        
        cuesReady = true;
        dev.log('Game video metadata ready');
        checkEnableStart();
    };
    
    if(currentVideo.readyState >= 1) {
        onLoadedMetadata();
    } else {
        currentVideo.addEventListener('loadedmetadata', onLoadedMetadata, false);
    }
}

var cueManager = null;
function joshSetup() {
    dev.log('Setting cue manager event handlers');

    cueManager = new msbranch.CueManager(currentVideo);
    cueManager.onSeekToTime = seekToTimeHandler;
    cueManager.onCompletion = completionHandler;
    cueManager.onCuesReady = cuesReadyHandler;
    cueManager.onTriggerSet = triggerSetHandler;
    cueManager.onWindowOpen = windowOpenHandler;
    cueManager.onImpede = impedeHandler;
    
    cueManager.setupCues();
}

var clickableTag = document.getElementById("clickableTag");
var controlsZone = document.getElementById("controlsZone");
var controlPanel = document.getElementById("controlPanel");
var togglePause = document.getElementById("togglePause");
var enableDebug = document.getElementById("enableDebug");
var fullscreen = document.getElementById("fullscreen");
var frameStep = document.getElementById("frameStep");
var project = document.getElementById("project");
var canvas = document.getElementById("cvs");
var divgui = document.getElementById("gui");
var divfps = document.getElementById("fps");
var divsts = document.getElementsByClassName("loading__text")[0];
var antialias = true; 
var adaptive = true; 
var title = projectTitle;
var controls = false;
var paused = false;
var loaded = false;
var scene = null;
var last = -1;
var show = true;

// Start project scene loader
dev.log('Starting Babylon engine');
BABYLON.Tools.CorsBehavior = 'use-credentials';
var engine = new BABYLON.Engine(canvas, antialias, null, adaptive);
engine.clear(new BABYLON.Color3(0.0,0.0,0.0), true, true);
var updateStatus = function (sts) {
    divsts.innerHTML = sts;
};
var loadBabylonScene = function () {
    last = -1;
    scene = null;
    loaded = false;
    paused = false;
    project.innerHTML = name;
    divgui.innerHTML = "";
    divgui.className = "hidden";
    updateStatus("Please wait...");
    showSceneLoading(title);
    executeSceneLoader();
//            setTimeout(function () { executeSceneLoader(root, name); }, 500);
};
var showSceneLoading = function (title) {
    divfps.innerHTML = "";
//            divsts.className = "";
    project.className = "hidden";
//            divfps.className = "hidden";
    controlPanel.className = "hidden";
    canvas.style.opacity = "0";
    togglePause.style.backgroundColor = "#DEDEDE";
    engine.clear(new BABYLON.Color3(0.0,0.0,0.0), true, true);
    BABYLON.SceneLoader.ShowLoadingScreen = false;
//            if (title != null) engine.loadingUIText = title;
//            engine.displayLoadingUI();
};
var removeSceneLoading = function () {
    dev.log('Hide loading screen');
//            engine.hideLoadingUI();
//            BABYLON.SceneLoader.ShowLoadingScreen = true;
    canvas.style.opacity = "1";
//            divsts.className = "hidden";
    document.getElementsByClassName('loading-overlay')[0].className += ' hidden';
    document.getElementsByClassName('loading-overlay')[0].style.pointerEvents = 'none';
    setTimeout(function() { document.getElementsByClassName('loading-overlay')[0].style.display = 'none'; }, 500);
//            project.className = "";
//            divfps.className = "";
    divgui.className = "";
//            controlPanel.className = "";
//            updateStatus("");
};
var progressSceneLoading = function () {
//            updateStatus("Loading scene...");
    if (!loaded) {
        if (engine && scene) {
            var waiting = scene.getWaitingItemsCount();
            updateStatus((waiting > 0) ? "Streaming items..." + waiting + " remaining" : "Parsing content...");
        } else {
            updateStatus("Loading scene...");
        }
        setTimeout(progressSceneLoading, 50);
    }
};
var executeSceneLoader = function () {
    dev.log('Loading Babylon manifest file into new scene');
    progressSceneLoading();
    BABYLON.SceneLoader.Load(babylonFolder, babylonFileName, engine, function (newscene) {
        scene = newscene;
        if (!scene.manager) {
            BABYLON.SceneManager.CreateManagerSession(root, scene);
            BABYLON.Tools.Warn("Toolkit: Created default session manager");
        }

        try {
            MSBeforeSceneReady();
        } catch (e) {
            console.error(e);
        }

        scene.executeWhenReady(function () {
            dev.log('Babylon scene ready');
            try {
                MSBeforeRender();
            } catch (e) {
                console.error(e);
            }

            loaded = true;
            startRenderLoop();
            if (show) removeSceneLoading();
        });
    }, null, function(scene, message, e) {
        dev.warn(scene, message, e);
    });
};
var renderFunction = function () {
//            if (engine && scene) {
//                if (controls) {
//                    // Show
//                    if (project.className === "hidden") {
//                        project.className = "";
//                    }
////                    if (divfps.className === "hidden") {
////                        divfps.className = "";
////                    }
//                    if (controlPanel.className === "hidden") {
//                        controlPanel.className = "";
//                    }
////                    var current = engine.getFps().toFixed();
////                    if (current != last) {
////                        divfps.innerHTML = ("" + current + " fps");
////                        last = current;
////                    }
//                } else {
//                    // Hide
//                    if (project.className !== "hidden") {
//                        project.className = "hidden";
//                    }
////                    if (divfps.className !== "hidden") {
////                        divfps.className = "hidden";
////                    }
//                    if (controlPanel.className !== "hidden") {
//                        controlPanel.className = "hidden";
//                    }
////                    if (divfps.innerHTML !== "") {
////                        divfps.innerHTML = "";
////                    }
//                }
//            }
};
// Start managed render loop
var startRenderLoop = function () {
    if (scene.manager) {
        scene.manager.onrender = renderFunction;
        scene.manager.start();
    } else {
        BABYLON.Tools.Warn("Toolkit: Null scene manager object detected");
    }
};
// Stop managed render loop
var stopRenderLoop = function () {
    if (scene.manager) {
        scene.manager.stop();
    } else {
        BABYLON.Tools.Warn("Toolkit: Null scene manager object detected");
    }
};
// Toggle managed render loop
var toggleRenderLoop = function () {
    if (scene.manager) {
        scene.manager.toggle();
        paused = !scene.manager.isRunning();
        if (paused) {
            togglePause.style.backgroundColor = "#800000";
        } else {
            togglePause.style.backgroundColor = "#DEDEDE";
        }
    } else {
        BABYLON.Tools.Warn("Toolkit: Null scene manager object detected");
    }
};
// Step managed render loop
var stepRenderLoop = function () {
    if (scene.manager) {
        scene.manager.stepFrame();
        var running = scene.manager.isRunning();
        if (!running) {
            frameStep.style.backgroundColor = "#008000";
            setTimeout(function () { frameStep.style.backgroundColor = "#DEDEDE"; }, 25);
        }
        if (!running) {
            togglePause.style.backgroundColor = "#800000";
        } else {
            togglePause.style.backgroundColor = "#DEDEDE";
        }

    } else {
        BABYLON.Tools.Warn("Toolkit: Null scene manager object detected");
    }
};
// Show window fullscreen
var showFullscreen = function () {
    if (scene.manager) {
        scene.manager.showFullscreen();
    } else {
        BABYLON.Tools.Warn("Toolkit: Null scene manager object detected");
    }
};
// Toggle debug layer
var toggleDebugLayer = function () {
    if (scene.manager) {
        var popup = false;
        var initialTab = 0;
        var parentElement = null;
        scene.manager.toggleDebug(popup, initialTab, parentElement);
    } else {
        BABYLON.Tools.Warn("Toolkit: Null scene manager object detected");
    }
};
// Toggle scene pause
if (togglePause) {
    togglePause.addEventListener("click", function () { toggleRenderLoop(); });
}
// Frame step scene
if (frameStep) {
    frameStep.addEventListener("click", function () { stepRenderLoop(); });
}
// Toggle control panel
var panelIsClosed = true;
if (clickableTag) {
    clickableTag.addEventListener("click", function () {
        if (panelIsClosed) {
            panelIsClosed = false;
            controlPanel.style.webkitTransform = "translateY(0px)";
            controlPanel.style.transform = "translateY(0px)";
        } else {
            panelIsClosed = true;
            controlPanel.style.webkitTransform = "translateY(100px)";
            controlPanel.style.transform = "translateY(100px)";
        }
    });
}
// Show debug layer
if (enableDebug) {
    enableDebug.addEventListener("click", function () { toggleDebugLayer(); });
}
// Switch full screen
if (fullscreen) {
    fullscreen.addEventListener("click", function () { showFullscreen(); });
}
// Resize scene manager
if (engine) {
    window.addEventListener("resize", function () { engine.resize(); });
}
// Register scene loader
if (BABYLON.SceneManager && BABYLON.SceneManager.RegisterLoader) {
    BABYLON.SceneManager.RegisterLoader(loadBabylonScene);        
    window.addEventListener("DOMContentLoaded", function () {
        loadBabylonScene();
    });
} else {
    BABYLON.Tools.Warn("Toolkit: Null scene manager object detected");
}
// Validate loader plugins
if (!BABYLON.ToolkitPlugin || !BABYLON.ToolkitPlugin.Loader ) {
    BABYLON.Tools.Warn("Toolkit: Null scene manager loader plugins detected");
}
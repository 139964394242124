var laneKeepingControl = {
    start: function(config) {
        dev.log('Lane Keeping module precedes Info Display');
        this.config = config ? config : { time: 6 };
        this.mode = 'normal';
        this.controlsEnabled = true;
        overlayTimer.init({
            time: this.config.time,
            levels: true,
            onComplete: this.onFailure.bind(this)
        });
        overlayTimer.start();
        var _this = this;
        $('#btn-lane-keeping').on('mousedown touchstart', function() {
            if(_this.controlsEnabled) {
                $(this).addClass('selected');
                _this.onSuccess();
            }
        }).on('mouseup touchend', function() {
            $(this).removeClass('selected');
        });
        $('#instrument-panel-section, #instrument-panel-right-section').addClass('disabled');
        $('#stalk-section').removeClass('disabled');
    },
    reset: function() {
        overlayTimer.reset();
        overlayTimer.start();
        $(dtButtonClass).removeClass('active selected');
    },
    onEnd: function() {
        dev.log('Lane Keeping: Completed, playing VO');
        var _this = this;
        $('#stalk-section').addClass('disabled');
        var audioTrack = playAudioFromEpisode(1, 2);
        audioTrack.on('ended', function() {
            dev.log('Lane Keeping: VO done, switching to Info Display');
            $('#stalk-section').addClass('disabled');
            $('#instrument-panel-section, #instrument-panel-right-section').removeClass('disabled');

            if(_this.onEndCustom && typeof _this.onEndCustom === 'function') {
                _this.onEndCustom();
            }
            
            audioTrack.off('ended');
        });
    },
    onSuccess: function() {
        dev.log('Lane Keeping: success during ' + this.mode);
        
        $('#btn-lane-keeping').removeClass('prompt selected').off('mousedown touchstart mouseup touchend');
        
        $('.icon-info-driving-car-upper, .icon-info-driving-car-wheel, .icon-info-driving-gap').css('opacity', 0);
        $('.icon-info-driving').css('opacity', 1);
        $('.icon-info-lks-lines-left, .icon-info-lks-lines-right').addClass('on');
        
        this.controlsEnabled = false;
        
        overlayTimer.pause();
        
        if(this.mode === 'normal') {
            switch(overlayTimer.level) {
                case 1:
                    this.addPoints(defaultPoints.max, $('#btn-lane-keeping'));
                    break;
                case 2:
                    this.addPoints(defaultPoints.retry, $('#btn-lane-keeping'));
                    break;
                case 3:
                    this.addPoints(defaultPoints.min, $('#btn-lane-keeping'));
                    break;
            }
        }
        
        overlayTimer.disable();
        
//        switch(this.mode) {
//            case 'normal':
//                this.addPoints(defaultPoints.max, $('#btn-lane-keeping'));
//                break;
//            case 'retry':
//                this.addPoints(defaultPoints.retry, $('#btn-lane-keeping'));
//                break;
//            case 'hint':
//                this.addPoints(defaultPoints.min, $('#btn-lane-keeping'));
//                break;
//            case 'tutorial':
//                
//                break;
//        }
        
        this.onEnd();
    },
    onFailure: function() {
        dev.log('Failed to click Lane Keeping button');
        $('#btn-lane-keeping').removeClass('prompt');
        var _this = this;
//        switch(this.mode) {
//            case 'normal':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[0].message,
//                    audio: defaultOverlayFails[0].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'retry';
//                        _this.controlsEnabled = true;
//                    }
//                });
//                break;
//            case 'retry':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[1].message,
//                    audio: defaultOverlayFails[1].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'hint';
//                        _this.controlsEnabled = true;
//                        $('#btn-lane-keeping').addClass('prompt');
//                    }
//                });
//                break;
//            case 'hint':
                this.controlsEnabled = false;
                var _this = this;
                cueManager._emitImpede({
                    type: 'custom',
                    customType: 'tutorial',
                    message: defaultOverlayFails[2].message,
                    audio: defaultOverlayFails[2].audio,
                    buttonText: 'Play',
                    onHide: function() {
                        overlayTimer.disable();
                        _this.mode = 'tutorial';
                        _this.startTutorial();
                    }
                });
//                break;
//        }
    },
    startTutorial: function() {
        dev.log('Lane Keeping: start tutorial');
        
        var _this = this;
        
        $('#mode-notification').removeClass('hidden');
        var intTime = 1500;
//        var intTime = this.config.time / 2 * 1000;
        var intCount = 0;
        var tutInt = setInterval(function() {
            if(intCount === 0) {
                $('#btn-lane-keeping').addClass('selected');
            } else {
                clearInterval(tutInt);
                $('#btn-lane-keeping').removeClass('selected');
                $('#mode-notification').addClass('hidden');
                _this.onSuccess();
            }
            intCount++;
        }, intTime);
    },
    addPoints: function(points, target) {
        var targetOffset = target.offset();
        var pointLocation = [targetOffset.left + target.width() / 2, targetOffset.top];
        
        addPoints(points, pointLocation);
    }
};
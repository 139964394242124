var projectTitle = 'Ford DAT';
var projectId = 'ford';

var babylonFileName = '360_FordEdge2.babylon';
var babylonFolder = 'scenes/';

var singleVideo = {
    fileBase: 'http://ford-training-cdn.mediascape.com/',
//    fileBase: 'textures/',
    fileBaseLocal: 'textures/'
};

var webmAvailableInLMS = false;

var defaultVars = [
//    ['setWebm', 'false']
];

var defaultEpisode = 'Episode1';

var defaultPoints = {
    max: 5,
    retry: 3,
    min: 1
};

var defaultInputs = {
//    brake: ['shift', 'Shift', 'btn-brake'],
//    gas: [' ', 'space', 'spacebar', 'btn-gas'],
    brake:      ['s', 'S', 'btn-brake'],
    gas:        ['w', 'W', 'btn-gas'],
    turnLeft:   ['a', 'A', 'btn-left'],
    turnRight:  ['d', 'D', 'btn-right']
};

var performanceTestText = 'Please test your system performance for the best game experience!';

var defaultDisclaimer = 'The following is a computer animated simulation set to a fictional plot designed to teach Ford retailers driver-assist technology operation. Driver-assist features are supplemental and do not replace the driver’s attention, judgment and need to control the vehicle. Please see the vehicle owner’s guide for details and limitations of specific driver-assist technologies. Driving while distracted or drowsy can result in loss of vehicle control, crash and injury.';

var modeNotificationText = {
    tutorial: 'Tutorial Mode',
    preTutorial: 'First we’ll show the correct step-by-step configuration, then you can try…',
};

var defaultPreTutorialMessage = 'Now, it\'s your turn';
var defaultPreTutorialHold = 3000;

var defaultOverlayFails = [
    { audio: 'audio/beeoooo.mp3', message: 'You took too long.' },
    { audio: 'audio/beeoooo.mp3', message: 'One more try. Watch for a hint!' },
    { audio: 'audio/beeoooo.mp3', message: 'You\'re caught! Let\'s see what should have happened.' }
];

var defaultDrivingFails = [
    { audio: 'audio/beeoooo.mp3', message: 'You took too long.' },
    { audio: 'audio/bweewoo.mp3', message: 'You\'re caught! Let\'s see what should have happened.' }
];

var autoPilotMode = false;
var disableTimer = false;
var disableScoring = false;
var overlayPreTutorialsOn = false;
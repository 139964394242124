var informationDisplay = {
    activeItem: [0, 0],
    menuPath: [],
    init: function(action) {
        
        var config = action.init ? action.init : {};
        this.config = config;
        this.mode = 'init';
        this.controlsEnabled = false;
        this.onCloseCustom = (action.onCloseCustom && typeof action.onCloseCustom === 'function') ? action.onCloseCustom : function(){};
        this.onSuccessCustom = (action.onSuccessCustom && typeof action.onSuccessCustom === 'function') ? action.onSuccessCustom : function(){};
        
        var informationPanel = $('#instrument-panel');

        var pages = informationPanel.find('.page');

        for(var i = 0; i < pages.length; i++) {
            var page = $(pages[i]);
            page.attr('data-page-index', i);
            var menuItems = page.children();
            for(var j = 0; j < menuItems.length; j++) {
                var menuItem = $(menuItems[j]);
                menuItem
                    .attr('data-item-index', j);
//                    .click(function() {
//                        informationDisplay.clickItem(this);
//                        return false;
//                    })
//                    .on('mouseenter', function() { informationDisplay.highlightItem(this); });
            }
        }
        
        informationPanel.find('input, label').off('click');
        
        if(config.startPath) {
            var finalDest = config.startPath[config.startPath.length - 1].split(':');
            var finalPage = finalDest[0];
            var finalItem = finalDest.length > 1 ? parseInt(finalDest[1]) : 0;
            
            this.menuPath = config.startPath.slice();
            this.menuPath[this.menuPath.length - 1] = finalPage;
            this.navigateToPage(finalPage);
            this.highlightItemByIndex(finalItem);
        } else {
            this.menuPath = ['main'];
            this.navigateToPage('main');
            this.highlightItemByIndex(0);
        }
        this.sequenceStep = 0;
        this.correctSequence = config.correctSequence;
        this.sequenceStatus = [];
        for(var k = 0; k < config.correctSequence.length; k++) {
            var correctStep = config.correctSequence[k];
            if(isObject(correctStep)) {
                this.sequenceStatus.push({});
                for(var key in correctStep) {
                    this.sequenceStatus[k][key] = false;
                }
            } else {
                this.sequenceStatus.push(false);
            }
        }
    },
    reset: function() {
        this.mode = 'init';
        this.menuPath = this.config.startPath ? this.config.startPath.slice() : ['main'];
        this.navigateToPage(this.config.startPath ? this.config.startPath[this.config.startPath.length - 1] : 'main');
        this.highlightItemByIndex(0);
        this.sequenceStep = 0;
        for(var i = 0; i < this.correctSequence.length; i++) {
            if(isObject(this.correctSequence[i])) {
                for(var key in this.correctSequence[i]) {
                    var target = $('#instrument-panel [data-select-id="' + key + '"]');
                    if(target.hasClass('checkbox')) {
                        if(this.correctSequence[i][key] === target.hasClass('checked')) {
                            this.onChangeItem(target);
                        }
                    }
//                    $('#' + key).prop('checked', !this.correctSequence[i][key]);
                }
            }
        }
        $(dtButtonClass).removeClass('active selected');
    },
    open: function(action) {
        currentVideo.pause();
        
        this.init(action);
        
        var _this = this;
        
        currentImpediment = action;
        mainControlsEnabled = false;
        $(dtButtonClass).removeClass('active');

        var informationOverlay = $('#instrument-overlay');

        informationOverlay.attr('data-overlay-type', action.type);
    //    informationOverlay.find('.overlay__message').html(action.message);
    //    informationOverlay.find('.overlay__title').text(action.header ? action.header : '');
        informationOverlay.find('.overlay__button').text('Continue').off('click').hide();

        var continuePromise = new Promise(function(resolve, reject) {
            if(action.laneKeeping) {
                informationOverlay.addClass('show-stalk').removeClass('hidden');
                var laneKeepingConfig = _this.config.laneKeepingConfig ? _this.config.laneKeepingConfig : null;
                laneKeepingControl.start.call(laneKeepingControl, laneKeepingConfig);
                laneKeepingControl.onEndCustom = function() {
                    resolve();
                };
            } else if(action.cruiseControl) {
                informationOverlay.addClass('show-cruise').removeClass('hidden');
                cruiseControl.start.call(cruiseControl);
                cruiseControl.onEndCustom = function() {
                    resolve();
                };
            } else {
                informationOverlay.removeClass('hidden');
                resolve();
            }
        });
        
        continuePromise.then(function() {
            if(action.cruiseControl) {
                _this.onSuccess();
            } else {
                $('#instrument-panel-section, #instrument-panel-right-section').removeClass('disabled');
                _this.mode = 'normal';
                _this.controlsEnabled = true;
                _this.isActive = true;
                overlayTimer.init({
                    time: _this.config.time,
                    levels: true,
                    onComplete: _this.onFailure.bind(_this)
                });
                overlayTimer.start();
            }
        });
    },
    onClose: function() {
        var informationPanel = $('#instrument-panel');

        var pages = informationPanel.find('.page');

        for(var i = 0; i < pages.length; i++) {
            var menuItems = $(pages[i]).children();
            for(var j = 0; j < menuItems.length; j++) {
                var menuItem = $(menuItems[j]);
//                menuItem.off('click mouseenter');
            }
        }
        
        this.controlsEnabled = false;
        this.isActive = false;
        
        currentImpediment = {};
        controlsEnabled = true;
        mainControlsEnabled = true;
        $(dtButtonClass).removeClass('active');
        overlayTimer.disable();
        
        $('#instrument-overlay').addClass('hidden').removeClass('show-stalk show-cruise');
        
        currentVideo.play();
        
        this.onCloseCustom();
        this.onCloseCustom = null;
    },
    onInput: function(inputName, isReal) {
        if((isReal && this.controlsEnabled) || (!isReal && this.mode == 'tutorial')) {
            dev.log('Info Display input: ' + inputName);
            switch (inputName) {
                case 'btn-up-arrow':
//                case 'arrowup':
                    if(this.isActive) playAudio('audio/controller.mp3', 2);
                    if(this.activeItem[1] > 0) {
                        this.highlightItemByIndex(this.activeItem[1] - 1);
                    } else {
                        this.highlightItemByIndex($('#instrument-panel [data-page-index="' + this.activeItem[0] + '"] > *').length - 1);
                    }
                    break;
                case 'btn-down-arrow':
//                case 'arrowdown':
                    if(this.isActive) playAudio('audio/controller.mp3', 2);
                    if(this.activeItem[1] < ($('#instrument-panel [data-page-index="' + this.activeItem[0] + '"] > *').length - 1)) {
                        this.highlightItemByIndex(this.activeItem[1] + 1);
                    } else {
                        this.highlightItemByIndex(0);
                    }
                    break;
                case 'btn-left-arrow':
//                case 'arrowleft':
                    if(this.isActive) playAudio('audio/controller.mp3', 2);
                    this.navigateUp();
                    break;
                case 'btn-right-arrow':
//                case 'arrowright':
                case 'btn-ok':
//                case 'enter':
                    if(this.isActive) playAudio('audio/controller.mp3', 2);
                    this.selectActiveItem();
                    break;
            }
        }
    },
    onFailure: function() {
        dev.log('Info Display: failure during ' + this.mode);
        
        var _this = this;
//        switch(this.mode) {
//            case 'normal':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[0].message,
//                    audio: defaultOverlayFails[0].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'retry';
//                        _this.controlsEnabled = true;
//                        _this.resetTimer();
//                        _this.setTimer();
//                    }
//                });
//                break;
//            case 'retry':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[1].message,
//                    audio: defaultOverlayFails[1].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'hint';
//                        _this.showHints();
//                        _this.controlsEnabled = true;
//                        _this.resetTimer();
//                        _this.setTimer();
//                    }
//                });
//                break;
//            case 'hint':
                this.controlsEnabled = false;
                var informationPanel = $('#instrument-panel');
                var pages = informationPanel.find('.page');
                for(var i = 0; i < pages.length; i++) {
                    var menuItems = $(pages[i]).children();
                    for(var j = 0; j < menuItems.length; j++) {
                        var menuItem = $(menuItems[j]);
                        menuItem.removeClass('hint');
//                            .off('click mouseenter');
                    }
                }
                cueManager._emitImpede({
                    type: 'custom',
                    customType: 'tutorial',
                    message: defaultOverlayFails[2].message,
                    audio: defaultOverlayFails[2].audio,
                    buttonText: 'Play',
                    onHide: function() {
                        _this.reset();
                        _this.mode = 'tutorial';
                        _this.startTutorial();
                    }
                });
//                break;
//        }
    },
    onSuccess: function() {
        dev.log('Info Display: success during ' + this.mode);
//        switch(this.mode) {
//            case 'normal':
//                this.controlsEnabled = false;
//                clearInterval(this.interval);
//                break;
//            case 'retry':
//                this.controlsEnabled = false;
//                clearInterval(this.interval);
//                break;
//            case 'hint':
//                this.controlsEnabled = false;
//                clearInterval(this.interval);
//                break;
//            case 'tutorial':
//                clearInterval(this.interval);
//                break;
//        }
        this.controlsEnabled = false;
        
        overlayTimer.disable();
        
        var informationPanel = $('#instrument-panel');
        var pages = informationPanel.find('.page');
        for(var i = 0; i < pages.length; i++) {
            var menuItems = $(pages[i]).children();
            for(var j = 0; j < menuItems.length; j++) {
                var menuItem = $(menuItems[j]);
                menuItem.removeClass('hint');
//                .off('click mouseenter');
            }
        }
        
        var _this = this;
        $('#instrument-overlay .overlay__button').show().click(function() {
            _this.onClose();
        });
        
        this.onSuccessCustom();
        this.onSuccessCustom = null;
        
//        if(this.mode == 'tutorial') {
//            var _this = this;
//            $('#instrument-overlay .overlay__button').show().click(function() {
//                _this.onClose();
//            });
//        } else {
//            this.onClose();
//        }
    },
    showHints: function() {
        dev.log('Info Display: show hints');
        for(var i = 0; i < this.correctSequence.length; i++) {
            if(isObject(this.correctSequence[i])) {
                for(var key in this.correctSequence[i]) {
                    $('#instrument-panel [data-select-id="' + key + '"]').addClass('hint');
                }
            } else {
                $('#instrument-panel [data-to-page="' + this.correctSequence[i] + '"]').addClass('hint');
            }
        }
    },
    startTutorial: function() {
        dev.log('Info Display: start tutorial');
        
        $('#mode-notification').removeClass('hidden');
        
        var buttonMap = {
            up: 'btn-up-arrow',
            down: 'btn-down-arrow',
            left: 'btn-left-arrow',
            right: 'btn-right-arrow',
            enter: 'btn-ok'
        };
        
        var _this = this;
        var stepInd = 0;
        var tutInterval = setInterval(function() {
            registerInput(buttonMap[_this.config.tutorialSequence[stepInd]], false);
            setTimeout(function() {
                unregisterInput(buttonMap[_this.config.tutorialSequence[stepInd - 1]], false);
            }, 200);
            
            stepInd++;
            if(stepInd >= _this.config.tutorialSequence.length) {
                clearInterval(tutInterval);
                $('#mode-notification').addClass('hidden');
                _this.onSuccess();
            }
        }, 1000);
    },
    highlightItemByIndex: function(ind) {
        var nextElem = $('#instrument-panel [data-page-index="' + this.activeItem[0] + '"] [data-item-index="' + ind + '"]');
        if(nextElem.hasClass('disabled')) {
            ind = ind > this.activeItem[1] ? ind + 1 : ind - 1;
            nextElem = $('#instrument-panel [data-page-index="' + this.activeItem[0] + '"] [data-item-index="' + ind + '"]');
        }
        this.activeItem[1] = ind;
        this.highlightElement(nextElem);
    },
    highlightItem: function(elem) {
        this.activeItem[1] = parseInt($(elem).attr('data-item-index'));
        this.highlightElement(elem);
    },
    highlightElement: function(elem) {
        $('#instrument-panel [data-item-index]').removeClass('active');
        var pagesElem = $('#instrument-panel .pages');
        var thisItem = $(elem);
        thisItem.addClass('active');
//        thisItem.addClass('active').trigger('focus');
        if(thisItem.position().top > pagesElem.height() * .8) {
            pagesElem.scrollTop(pagesElem.scrollTop() + thisItem.position().top - pagesElem.height() * .8);
//            console.log(pagesElem.scrollTop());
        } else if(thisItem.position().top < 0) {
            pagesElem.scrollTop(pagesElem.scrollTop() + thisItem.position().top);
//            console.log(pagesElem.scrollTop());
        }
    },
    selectActiveItem: function() {
        this.clickItem($('#instrument-panel [data-page-index="' + this.activeItem[0] + '"] [data-item-index="' + this.activeItem[1] + '"]')[0]);
    },
    clickItem: function(elem) {
        if(elem && elem.outerHTML) {
            dev.log('Info Display: clicked ' + elem.outerHTML);
        } else {
            dev.warn('Info Display clickItem needs element input');
        }
        var target = $(elem);
        switch(this.mode) {
            case 'tutorial':
            case 'init':
                if(target.attr('data-to-page')) {
                    this.menuPath.push(target.attr('data-to-page'));
                    this.navigateToPage(target.attr('data-to-page'));
                } else if(target.attr('data-select-id')) {
                    this.onChangeItem(target);
                }
                break;
            case 'normal':
                if(target.attr('data-to-page')) {
                    var thisStep = target.attr('data-to-page');
                    if(this.isNextStep(thisStep)) {
                        this.addPoints(target);
                        this.sequenceStatus[this.sequenceStep] = true;
                        this.sequenceStep++;
                        this.menuPath.push(thisStep);
                        this.navigateToPage(thisStep);
                        if(this.sequenceStep >= this.correctSequence.length) {
                            this.onSuccess();
                        }
                    } else {
                        this.onIncorrectItem(target);
                    }
                } else if(target.attr('data-select-id')) {
                    var stepId = target.attr('data-select-id');
                    var thisStep = {};
                    thisStep[stepId] = !target.hasClass('checked');
                    if(this.isNextStep(thisStep)) {
                        this.addPoints(target);
                        this.onChangeItem(target);
                        this.sequenceStatus[this.sequenceStep][stepId] = true;
                        if(this.isCurrentStepComplete()) {
                            this.sequenceStep++;
                            if(this.sequenceStep >= this.correctSequence.length) {
                                this.onSuccess();
                            }
                        }
                    } else {
                        this.onIncorrectItem(target);
                    }
                } else {
                    this.onIncorrectItem(target);
                }
                break;
            case 'retry':
                if(target.attr('data-to-page')) {
                    var thisStep = target.attr('data-to-page');
                    if(this.isNextStep(thisStep)) {
                        if(!this.sequenceStatus[this.sequenceStep]) {
                            this.addPoints(target);
                            this.sequenceStatus[this.sequenceStep] = true;
                        }
                        this.sequenceStep++;
                        this.menuPath.push(thisStep);
                        this.navigateToPage(thisStep);
                        if(this.sequenceStep >= this.correctSequence.length) {
                            this.onSuccess();
                        }
                    } else {
                        this.onIncorrectItem(target);
                    }
                } else if(target.attr('data-select-id')) {
                    var stepId = target.attr('data-select-id');
                    var thisStep = {};
                    thisStep[stepId] = !target.hasClass('checked');
                    if(this.isNextStep(thisStep)) {
                        if(!this.sequenceStatus[this.sequenceStep][stepId]) {
                            this.addPoints(target);
                            this.sequenceStatus[this.sequenceStep][stepId] = true;
                        }
                        this.onChangeItem(target);
                        if(this.isCurrentStepComplete()) {
                            this.sequenceStep++;
                            if(this.sequenceStep >= this.correctSequence.length) {
                                this.onSuccess();
                            }
                        }
                    } else {
                        this.onIncorrectItem(target);
                    }
                } else {
                    this.onIncorrectItem(target);
                }
                break;
            case 'hint':
                if(target.attr('data-to-page')) {
                    var thisStep = target.attr('data-to-page');
                    if(this.isNextStep(thisStep)) {
                        if(!this.sequenceStatus[this.sequenceStep]) {
                            this.addPoints(target);
                            this.sequenceStatus[this.sequenceStep] = true;
                        }
                        this.sequenceStep++;
                        this.menuPath.push(thisStep);
                        this.navigateToPage(thisStep);
                        if(this.sequenceStep >= this.correctSequence.length) {
                            this.onSuccess();
                        }
                    } else {
                        this.onIncorrectItem(target);
                    }
                } else if(target.attr('data-select-id')) {
                    var stepId = target.attr('data-select-id');
                    var thisStep = {};
                    thisStep[stepId] = !target.hasClass('checked');
                    if(this.isNextStep(thisStep)) {
                        if(!this.sequenceStatus[this.sequenceStep][stepId]) {
                            this.addPoints(target);
                            this.sequenceStatus[this.sequenceStep][stepId] = true;
                        }
                        this.onChangeItem(target);
                        if(this.isCurrentStepComplete()) {
                            this.sequenceStep++;
                            if(this.sequenceStep >= this.correctSequence.length) {
                                this.onSuccess();
                            }
                        }
                    } else {
                        this.onIncorrectItem(target);
                    }
                } else {
                    this.onIncorrectItem(target);
                }
                break;
        }
    },
    onChangeItem: function($elem) {
        var target = $elem;
        if(target.hasClass('checkbox')) {
            target.toggleClass('checked');
        } else if(target.hasClass('radio')) {
            // I can't remember why I restricted non-init/non-tutorial item changes to just the overlay info display
            // what about the info display popup?
            if(this.mode === 'init' || this.mode === 'tutorial') {
                $('[data-radio-id="' + target.attr('data-radio-id') + '"]').removeClass('checked');
            } else {
                $('#instrument-panel [data-radio-id="' + target.attr('data-radio-id') + '"]').removeClass('checked');
            }
            target.addClass('checked');
        }
        var $disabledItems = $('[data-select-disabled]');
        for(var i = 0; i < $disabledItems.length; i++) {
            var disabledOn = $($disabledItems[i]).attr('data-select-disabled').split(':');
            // ex: [ 'activeBraking', false ]
            disabledOn[1] = (disabledOn[1] && disabledOn[1] === 'true');
            var $disabledOnElem = $('[data-select-id="' + disabledOn[0] + '"]');
            if($disabledOnElem.hasClass('checked') === disabledOn[1]) {
                $($disabledItems[i]).addClass('disabled');
            } else {
                $($disabledItems[i]).removeClass('disabled');
            }
        }
    },
    onIncorrectItem: function(menuItemElem) {
        menuItemElem.addClass('warning');
        setTimeout(function() {
            $('#instrument-panel .page > *').removeClass('warning');
        }, 100);
    },
    isNextStep: function(step) {
        var correctStep = this.correctSequence[this.sequenceStep];
        if(typeof correctStep === 'string') {
            return step === correctStep;
        } else if(isObject(correctStep) && isObject(step)) {
            for(var key in step) {
                if (step.hasOwnProperty(key) && correctStep[key] && step[key] === correctStep[key]) {
                    return true;
                }
            }
        }
        return false;
    },
    isCurrentStepComplete: function() {
        var thisStepStatus = this.sequenceStatus[this.sequenceStep];
        if(isObject(thisStepStatus)) {
            for(var key in thisStepStatus) {
                if(thisStepStatus[key] === false) {
                    return false;
                }
            }
            return true;
        } else {
            return thisStepStatus !== false;
        }
    },
    navigateToPage: function(pageId) {
        dev.log('Info Display: navigate to page ' + pageId);
        
        this.activeItem[0] = $('#instrument-panel [data-page-id="' + pageId + '"]').attr('data-page-index');
        
        $('#instrument-panel .pages').find('.page').addClass('hidden');
        $('#instrument-panel [data-page-id="' + pageId + '"]').removeClass('hidden');
        
        this.highlightItemByIndex(0);
        
        
        $('#instrument-panel')
            .attr('data-current-page', pageId)
            .find('.pages-title')
                .text( $('#instrument-panel [data-page-id="' + pageId + '"]').attr('data-page-name') );
        
//        console.log(this.activeItem, this.menuPath);
    },
    navigateUp: function() {
        switch(this.mode) {
            case 'tutorial':
            case 'init':
                if(this.menuPath.length > 1) {
                    this.menuPath.pop();
                    this.navigateToPage(this.menuPath[this.menuPath.length - 1]);
                }
                break;
            case 'normal':
                if(this.isNextStep(this.menuPath[this.menuPath.length - 2])) {
                    this.sequenceStatus[this.sequenceStep] = true;
                    this.sequenceStep++;
                    this.addPoints($('#instrument-panel [data-page-index="' + this.activeItem[0] + '"] [data-item-index="' + this.activeItem[1] + '"]'));
                    if(this.menuPath.length > 1) {
                        this.menuPath.pop();
                        this.navigateToPage(this.menuPath[this.menuPath.length - 1]);
                    }
                }
                break;
            case 'retry':
                if(this.isNextStep(this.menuPath[this.menuPath.length - 2])) {
                    if(!this.sequenceStatus[this.sequenceStep]) {
                        this.addPoints($('#instrument-panel [data-page-index="' + this.activeItem[0] + '"] [data-item-index="' + this.activeItem[1] + '"]'));
                        this.sequenceStatus[this.sequenceStep] = true;
                    }
                    this.sequenceStep++;
                    if(this.menuPath.length > 1) {
                        this.menuPath.pop();
                        this.navigateToPage(this.menuPath[this.menuPath.length - 1]);
                    }
                }
                break;
            case 'hint':
                if(this.isNextStep(this.menuPath[this.menuPath.length - 2])) {
                    if(!this.sequenceStatus[this.sequenceStep]) {
                        this.addPoints($('#instrument-panel [data-page-index="' + this.activeItem[0] + '"] [data-item-index="' + this.activeItem[1] + '"]'));
                        this.sequenceStatus[this.sequenceStep] = true;
                    }
                    this.sequenceStep++;
                    if(this.menuPath.length > 1) {
                        this.menuPath.pop();
                        this.navigateToPage(this.menuPath[this.menuPath.length - 1]);
                    }
                }
                break;
        }
    },
    addPoints: function(target) {
        var points;
        
        if(this.mode === 'normal') {
            var targetOffset = target.offset();
            var pointLocation = [targetOffset.left + target.width() / 2, targetOffset.top];
            
            switch(overlayTimer.level) {
                case 1:
                    points = defaultPoints.max;
                    break;
                case 2:
                    points = defaultPoints.retry;
                    break;
                case 3:
                    points = defaultPoints.min;
                    break;
            }
            
            addPoints(points, pointLocation);
        }
    }
};
var currentGapDistance = 4;
$('.icon-info-driving').attr('data-gap', currentGapDistance);
$('.icon-info-driving-car-lower').attr('transform', 'translate(0, ' + (-8 * (4 - currentGapDistance)) + ')');

var cruiseControl = {
    start: function() {
        dev.log('Cruise Control module precedes Info Display');
        this.config = informationDisplay.config;
        this.inputCount = 0;
        this.inputsComplete = [];
        for(var i = 0; i < this.config.input.length; i++) {
            this.inputsComplete.push(false);
        }
        this.mode = 'normal';
        this.controlsEnabled = true;
        overlayTimer.init({
            time: this.config.cruiseControlTime,
            levels: true,
            onComplete: this.onFailure.bind(this)
        });
        overlayTimer.start();
        if(this.config.startingGap) {
            this.startingGap = currentGapDistance = this.config.startingGap;
        } else {
            this.startingGap = currentGapDistance;
        }
        var _this = this;
        $('#cruise-section .decision-tree-button').on('mousedown touchstart', function() {
            if(_this.controlsEnabled) {
                $(this).addClass('selected');
                _this.clicked(this.id);
//                _this.onSuccess();
            }
        }).on('mouseup touchend', function() {
            $(this).removeClass('selected');
        });
        $('#instrument-panel-section, #instrument-panel-right-section').addClass('disabled');
        $('#cruise-section').removeClass('disabled');
    },
    clicked: function(btnId) {
        if(this.mode == 'tutorial') {
            if(btnId == 'btn-cruise-gap') {
                var gap = parseInt($('.icon-info-driving').attr('data-gap'));
                var newGap = (gap > 1) ? (gap - 1) : 4;
                $('.icon-info-driving').attr('data-gap', newGap);
                $('.icon-info-driving-car-lower').attr('transform', 'translate(0, ' + (-8 * (4 - newGap)) + ')');
            }
        } else {
            if(this.config.input[this.inputCount] == btnId) {
                if(!this.inputsComplete[this.inputCount]) {
                    this.inputsComplete[this.inputCount] = true;
                    this.addPoints($('#' + this.config.input[this.inputCount]));
                }

                this.inputCount++;

                if(btnId == 'btn-cruise-gap') {
                    var gap = parseInt($('.icon-info-driving').attr('data-gap'));
                    var newGap = (gap > 1) ? (gap - 1) : 4;
                    $('.icon-info-driving').attr('data-gap', newGap);
                    $('.icon-info-driving-car-lower').attr('transform', 'translate(0, ' + (-8 * (4 - newGap)) + ')');
                }
                if(this.inputCount >= this.config.input.length) {
                    this.onSuccess();
                } else if(this.mode == 'hint') {
                    $('#' + this.config.input[this.inputCount - 1]).removeClass('prompt');
                    $('#' + this.config.input[this.inputCount]).addClass('prompt');
                }
            }
        }
    },
    reset: function() {
        $('.icon-info-driving').attr('data-gap', this.startingGap);
        $('.icon-info-driving-car-lower').attr('transform', 'translate(0, ' + (-8 * (4 - this.startingGap)) + ')');
        this.inputCount = 0;
        overlayTimer.reset();
        overlayTimer.start();
        $(dtButtonClass).removeClass('active selected');
    },
    onEnd: function() {
        dev.log('Cruise Control: Completed, playing VO');
        var _this = this;
        $('#cruise-section').addClass('disabled');
//        var audioTrack = playAudioFromEpisode(1, 2);
//        audioTrack.on('ended', function() {
//            dev.log('Lane Keeping: VO done, switching to Info Display');
//            $('#cruise-section').addClass('disabled');
//            $('#instrument-panel-section, #instrument-panel-right-section').removeClass('disabled');

            if(_this.onEndCustom && typeof _this.onEndCustom === 'function') {
                _this.onEndCustom();
            }
            
//            audioTrack.off('ended');
//        });
    },
    onSuccess: function() {
        dev.log('Cruise Control: success during ' + this.mode);
        
        currentGapDistance = parseInt($('.icon-info-driving').attr('data-gap'));
        
        $('#cruise-section .decision-tree-button').removeClass('prompt').off('mousedown touchstart mouseup touchend');
        setTimeout(function() {
            $('#cruise-section .decision-tree-button').removeClass('selected');
        }, 150);
        
        this.controlsEnabled = false;
        overlayTimer.disable();
        
        this.onEnd();
    },
    onFailure: function() {
        dev.log('Failed to click Cruise Control button');
        $('#cruise-section .decision-tree-button').removeClass('prompt');
        this.inputProgress = this.inputCount;
        this.inputCount = 0;
        var _this = this;
//        switch(this.mode) {
//            case 'normal':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[0].message,
//                    audio: defaultOverlayFails[0].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'retry';
//                        _this.controlsEnabled = true;
//                    }
//                });
//                break;
//            case 'retry':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[1].message,
//                    audio: defaultOverlayFails[1].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'hint';
//                        _this.controlsEnabled = true;
//                        $('#' + _this.config.input[0]).addClass('prompt');
//                    }
//                });
//                break;
//            case 'hint':
                this.controlsEnabled = false;
                var _this = this;
                cueManager._emitImpede({
                    type: 'custom',
                    customType: 'tutorial',
                    message: defaultOverlayFails[2].message,
                    audio: defaultOverlayFails[2].audio,
                    buttonText: 'Play',
                    onHide: function() {
                        overlayTimer.disable();
                        _this.mode = 'tutorial';
                        _this.startTutorial();
                    }
                });
//                break;
//        }
    },
    startTutorial: function() {
        dev.log('Cruise Control: start tutorial');
        
        $('.icon-info-driving').attr('data-gap', this.startingGap);
        $('.icon-info-driving-car-lower').attr('transform', 'translate(0, ' + (-8 * (4 - this.startingGap)) + ')');
        
        var _this = this;
        
        $('#mode-notification').removeClass('hidden');
        var intTime = 1000;
//        var intTime = this.config.time / 2 * 1000;
        var intCount = 0;
        var tutInt = setInterval(function() {
            if(intCount < _this.config.input.length) {
                $('#' + _this.config.input[intCount]).addClass('selected');
                setTimeout(function() {
                    $('#' + _this.config.input[intCount - 1]).removeClass('selected');
                }, 150);
                _this.clicked(_this.config.input[intCount]);
            } else {
                clearInterval(tutInt);
                $('#mode-notification').addClass('hidden');
                _this.onSuccess();
            }
            intCount++;
        }, intTime);
    },
    addPoints: function(target) {
//        switch(this.mode) {
//            case 'normal':
//                points = defaultPoints.max;
//                break;
//            case 'retry':
//                points = defaultPoints.retry;
//                break;
//            case 'hint':
//                points = defaultPoints.min;
//                break;
//            case 'tutorial':
//                return;
//        }
        var points;
        
        if(this.mode === 'normal') {
            var targetOffset = target.offset();
            var pointLocation = [targetOffset.left + target.width() / 2, targetOffset.top];
            
            switch(overlayTimer.level) {
                case 1:
                    points = defaultPoints.max;
                    break;
                case 2:
                    points = defaultPoints.retry;
                    break;
                case 3:
                    points = defaultPoints.min;
                    break;
            }
            
            addPoints(points, pointLocation);
        }
    }
};
/*global window console $ setTimeout cameraTarget cameraTargets cameraAnimation cueManager */

var episodes = {
    'Introduction': {
        firstPath: 's',
        introVideoSrc: 'Introduction.mp4',
        introVideoSrcLow: 'Introduction_halfRes.mp4',
        introVideoSrcLowest: 'Introduction_lowest.mp4',
        customDisclaimer: '',
        enabledControls: [],
        maxPoints: 10,
        init: function() {},
        audioClipNames: [],
        singleVideoPaths: {},
        singleVideoWindows: {}
    },
    'IntroductionRecord': {
        firstPath: 'p',
        introVideoSrc: '',
        introVideoSrcLow: '',
        introVideoSrcLowest: '',
        customDisclaimer: '',
        enabledControls: [],
        maxPoints: 0,
        init: function() {
            cameraBlur = 3;
            lensEffect.setDarkenOutOfFocus(.3);
            cameraTarget = {
                alpha: cameraTargets.center.alpha,
                beta: 1.1,
                radius: cameraTargets.center.radius
            };
        },
        audioClipNames: [],
        singleVideoWindows: {},
        singleVideoPaths: {
            p: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 8,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: cameraBlur,
                                darkness: .3
                            },
                            {
                                progress: .2,
                                alpha: -3.14,
                                beta: 1.5,
                                radius: 4,
                                blur: 2,
                                darkness: .2
                            },
                            {
                                progress: .4,
                                alpha: -3.7,
                                beta: 1.4,
                                radius: 3,
                                blur: 1.5,
                                darkness: .1
                            },
                            {
                                progress: .6,
                                alpha: -3,
                                beta: 1.3,
                                radius: 2,
                                blur: 2,
                                darkness: .2
                            },
                            {
                                progress: .8,
                                alpha: -2,
                                beta: 1.5,
                                radius: 3,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: .85,
                                alpha: -2,
                                beta: 1.5,
                                radius: 3,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            },
                        ],
                        onEnd: function() {
                            cameraAnimation.start({
                                duration: .5,
                                delay: 2,
                                keys: [
                                    {
                                        progress: 0,
                                        alpha: cameraTarget.alpha,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: 1,
                                        alpha: -4.0133,
                                        beta: 1.5292,
                                        radius: 4,
                                        blur: 0,
                                        darkness: 0
                                    }
                                ],
                                onEnd: function() {
                                    cameraAnimation.start({
                                        duration: .5,
                                        delay: 12,
                                        keys: [
                                            {
                                                progress: 0,
                                                alpha: cameraTarget.alpha,
                                                beta: cameraTarget.beta,
                                                radius: cameraTarget.radius,
                                                blur: 0,
                                                darkness: 0
                                            },
                                            {
                                                progress: 1,
                                                alpha: cameraTargets.center.alpha,
                                                beta: cameraTargets.center.beta,
                                                radius: cameraTargets.center.radius,
                                                blur: 0,
                                                darkness: 0
                                            }
                                        ]
                                    });
                                }
                            });
                        }
                    });
                    
//                    setTimeout(function() {
//                        cameraBlur = 0;
//                    }, 500);
//                    
//                    setTimeout(function() {
//                        cameraTarget = { alpha: -4.013294634442454, beta: 1.529197117911092, radius: 4 };
//                    }, 9700);
//                    
//                    setTimeout(function() {
//                        cameraTarget = cameraTargets.center;
//                    }, 21000);
                },
            },
        }
    },
    'Episode1': {
        firstPath: 'p0',
        introVideoSrc: 'Episode1_intro.mp4',
        introVideoSrcLow: 'Episode1_intro_halfRes.mp4',
        introVideoSrcLowest: 'Episode1_intro_lowest.mp4',
        enabledControls: ['btn-left', 'btn-right', 'btn-brake', 'btn-gas'],
        maxPoints: 95,
        overview: {
            title: 'Objective:',
            content: 'Use the assistance of your <span class="highlight">Rear View Camera</span> and <span class="highlight">Cross-Traffic Alerts</span> to escape this situation'
        },
        audioClipNames: [
            'intro',
        ],
        init: function() {
            shifterControl.set('p', false);
            
            informationDisplayPopup.make();
        },
        singleVideoPaths: {
            'p0': {
                onEnterCustom: function() {
                    cueManager._updateCueWatch('p0');
                },
                cueWatch: [ 'w0' ]
            },
            'w0': {
                onEnterCustom: function() {
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Before backing up, make sure <span class="highlight">Cross-Traffic Alert</span> is set to <span class="highlight">On</span>',
//                        header: 'Information'
                    });
                    cameraTarget = cameraTargets.steering;
                }
            },
            'p1': {
                onEnterCustom: function() {
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        init: {
                            startPath: [ 'main', 'displayMode' ],
                            // correctSequence = sequence of navigations and toggles required for this settings interaction
                            correctSequence: [ 'main', 'driverAssist', { 'crossTrafficAlert': true } ],
                            tutorialSequence: [ 'left', 'down', 'down', 'down', 'right', 'down', 'enter' ],
                            time: 60
                        },
                        header: 'Information Display'
                    });
                    cameraTarget = cameraTargets.center;
                },
                cueWatch: ['w1']
            },
            'w1': {
            },
            'p1-1': {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: ['w1-1']
            },
            'w1-1': {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.shifter.alpha,
                                beta: cameraTargets.shifter.beta,
                                radius: cameraTargets.shifter.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            'p2': {
                onEnterCustom: function() {
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'r',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').show();
                        }
                    });
                    cameraTarget = cameraTargets.center;
                },
                cueWatch: [ 'w2' ]
            },
            'w2': {
                onEnterCustom: function() {
                    var touchVideoName = isLowPerformance ? 'Episode1_touchscreen_halfRes.mp4' : 'Episode1_touchscreen.mp4';
                    $('#touchscreen-video source').attr('src', singleVideo.fileBase + touchVideoName);
                    
                    window.backupVideo = $('#touchscreen-video')[0];
                    window.backupVideo.load();
                    
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.touchscreen.alpha,
                                beta: cameraTargets.touchscreen.beta,
                                radius: cameraTargets.touchscreen.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                onTriggerCustom: function() {
                    currentVideo.pause();
                    
                    cameraBlur = 3;
                    
                    window.backupVideo.play();
                    currentVideo = window.backupVideo;
                    
                    singleVideoPaths['p3'].started = false;
                    singleVideoPaths['p3'].endTime      -= singleVideoPaths['p3'].startTime;
                    singleVideoPaths['w3'].startTime    -= singleVideoPaths['p3'].startTime;
                    singleVideoPaths['w3'].endTime      -= singleVideoPaths['p3'].startTime;
                    singleVideoPaths['p4'].startTime    -= singleVideoPaths['p3'].startTime;
                    singleVideoPaths['p4'].endTime      -= singleVideoPaths['p3'].startTime;
                    singleVideoPaths['w4'].startTime    -= singleVideoPaths['p3'].startTime;
                    singleVideoPaths['w4'].endTime      -= singleVideoPaths['p3'].startTime;
                    
                    singleVideoPaths['p3'].startTime = 0;
                    
                    $('#touchscreen-overlay').removeClass('hidden');
                }
            },
            'p3': {
                cueWatch: ['w3']
            },
            'w3': {
                //zoom
            },
            'p4': {
                onEnterCustom: function() {
//                    $('#touchscreen-inner').addClass('zoom');
                    $('#btn-zoom').css('background-image', 'url(\'images/zoom-out.png\')');
                },
                cueWatch: ['w4']
            },
            'w4': {
                //zoom
                onTriggerCustom: function() {
                    cameraBlur = 0;
                    
                    currentVideo.pause();
                    
                    currentVideo = singleVideo.element;
                    currentVideo.play();
                    
                    $('#touchscreen-overlay').addClass('hidden');
                }
            },
            'p5-0': {
                cueWatch: ['w5-0']
            },
            'p5': {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: ['w5']
            },
            'w5': {
                //left
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.touchscreenRight.alpha,
                                beta: cameraTargets.touchscreenRight.beta,
                                radius: cameraTargets.touchscreenRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    setDatAlert({message: 'VEHICLE on your RIGHT'});
                    informationDisplayPopup.show();
                    $('.info-alert-cross-right').addClass('on');
                    
                    setMeshVisibility('MirrorBlinkRight', 1);
                }
            },
            'p6': {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.touchscreen.alpha,
                                beta: cameraTargets.touchscreen.beta,
                                radius: cameraTargets.touchscreen.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    $('.dat-alert').addClass('disabled');
                    informationDisplayPopup.hide();
                    $('.info-alert-cross-right').removeClass('on');
                    setMeshVisibility('MirrorBlinkRight', 0);
                },
                cueWatch: ['w6']
            },
            'w6': {
                //right
            },
            'p7': {
                cueWatch: [ 'a1a', 'w7', 'a1b' ]
            },
            'a1a': {
                onEnterCustom: function() {
//                    setDatAlert({message: 'VEHICLE on your LEFT'});
                    informationDisplayPopup.show();
                    $('.info-alert-cross-left').addClass('on');
                    setMeshVisibility('MirrorBlinkLeft', 1);
                }
            },
            'w7': {
                //left
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.touchscreenLeft.alpha,
                                beta: cameraTargets.touchscreenLeft.beta,
                                radius: cameraTargets.touchscreenLeft.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            'a1b': {
                onEnterCustom: function() {
//                    $('.dat-alert').addClass('disabled');
                    informationDisplayPopup.hide();
                    setTimeout(function() {
                        $('.info-alert-cross-left').removeClass('on');
                    }, 400);
                    setMeshVisibility('MirrorBlinkLeft', 0);
                }
            },
            'p8': {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.touchscreen.alpha,
                                beta: cameraTargets.touchscreen.beta,
                                radius: cameraTargets.touchscreen.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                cueWatch: ['w8']
            },
            'p9': {
                cueWatch: ['w9']
            },
            'p10': {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: ['w10']
            },
            'w10': {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.shifter.alpha,
                                beta: cameraTargets.shifter.beta,
                                radius: cameraTargets.shifter.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            'p11': {
                onEnterCustom: function() {
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'd',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            cameraAnimation.start({
                                duration: .4,
                                keys: [
                                    {
                                        progress: 0,
                                        alpha: cameraTarget.alpha,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: 1,
                                        alpha: cameraTargets.center.alpha,
                                        beta: cameraTargets.center.beta,
                                        radius: cameraTargets.center.radius,
                                        blur: 0,
                                        darkness: 0
                                    }
                                ]
                            });
                            
                            $('.gas-reverse-indicator').hide();
                        }
                    });
                },
                cueWatch: ['w11']
            },
            's1': {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                message: 'Episode 1 Complete'
            }
        },
        singleVideoWindows: {
            'w0': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotemboss_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p1',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-FiveWayLeft'],
                        action: {
                            type: 'seekNow',
                            toPath: 'p1'
                        }
                    }
                ]
            },
            'w1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p1-1',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seekNow',
                            toPath: 'p1-1'
                        }
                    }
                ]
            },
            'w1-1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p2',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Shifter'],
                        action: {
                            type: 'seekNow',
                            toPath: 'p2'
                        }
                    }
                ]
            },
            'w2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotemboss_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p3',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Touchscreen'],
                        action: {
                            type: 'seekNow',
                            toPath: 'p3'
                        }
                    }
                ]
            },
            'w3': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotemboss_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p4',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: ['btn-zoom'],
                        action: {
                            type: 'seekNow',
                            toPath: 'p4'
                        }
                    }
                ]
            },
            'w4': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotemboss_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p5-0',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: ['btn-zoom'],
                        action: {
                            type: 'seekNow',
                            toPath: 'p5-0'
                        }
                    }
                ]
            },
            'w5-0': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotemboss_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p5',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p5'
                        }
                    }
                ]
            },
            'w5': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1_wevegotyounow.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p6',
                    audio: 'audio/crash1_wevegotyounow.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p6'
                        }
                    }
                ]
            },
            'w6': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1_wevegotemboss.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p7',
                    audio: 'audio/crash1_wevegotyounow.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p7'
                        }
                    }
                ]
            },
            'w7': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1_wevegotemboss.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p8',
                    audio: 'audio/crash1_wevegotyounow.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p8'
                        }
                    }
                ]
            },
            'w8': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1_wevegotemboss.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p9',
                    audio: 'audio/crash1_wevegotyounow.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p9'
                        }
                    }
                ]
            },
            'w9': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotemboss_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p10',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p10'
                        }
                    }
                ]
            },
            'w10': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotemboss_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p11',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Shifter'],
                        action: {
                            type: 'seekNow',
                            toPath: 'p11'
                        }
                    }
                ]
            },
            'w11': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/wevegotemboss_v2.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 's1',
                    audio: 'audio/wevegotyounow_v2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 's1'
                        }
                    }
                ]
            },
        }
    },
    'Episode2': {
        firstPath: 'p1',
        introVideoSrc: 'Episode2_intro.mp4',
        introVideoSrcLow: 'Episode2_intro_halfRes.mp4',
        introVideoSrcLowest: 'Episode2_intro_lowest.mp4',
        customDisclaimer: 'Evasive Steering Assist does not control steering.',
        enabledControls: ['btn-left', 'btn-right', 'btn-brake'],
        maxPoints: 125,
        overview: {
            title: 'Objective:',
            content: 'Watch for <span class="highlight">Evasive Steering Assist</span> alerts, and steer appropriately to avoid obstacles while driving'
        },
        init: function() {
            shifterControl.set('d', false);
            lightingControl.set(0, true);
            setDrive('gas', true, true);
            informationDisplayPopup.make();
            $('[data-select-id="crossTrafficAlert"]').addClass('checked');
        },
        audioClipNames: [],
        singleVideoPaths: {
            p1: {
                onEnterCustom: function() {
                    
                },
                cueWatch: [ 'w1' ]
            },
            w1: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p2: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        init: {
                            startPath: [ 'main' ],
                            // correctSequence = sequence of navigations and toggles required for this settings interaction
                            correctSequence: [ 'settings', 'vehicle', 'lighting', { 'autoHighbeam': true } ],
                            tutorialSequence: [ 'down', 'down', 'down', 'down', 'right', 'right', 'down', 'down', 'down', 'down', 'right', 'down', 'enter' ],
                            time: 60
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'w2-1' ]
            },
            'w2-1': {
                //brake
                onTriggerCustom: function() {
                    setDrive('brake', true, true);
                }
            },
            'w2-2': {
                //right
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                }
            },
            p3: {
                onEnterCustom: function() {
                    $('.info-alert-pre-collision').removeClass('on');
                    $('.info-alert-pre-collision-2').removeClass('on blink');
                    informationDisplayPopup.hide();
                    
                    setTimeout(function() { setDrive('gas', true, true); }, 300);
                },
                cueWatch: [ 'w3' ]
            },
            w3: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p4: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    
                    cueManager._emitImpede({
                        type: 'custom',
                        customType: 'directional',
                        message: 'To activate <span class="highlight">Evasive Steering Assist</span>, first turn on <span class="highlight">Active Braking</span>, then turn on <span class="highlight">Evasive Steering Assist</span>.',
                        buttonText: 'Continue',
                        onShow: function() {
                            currentVideo.pause();
                        },
                        onHide: function() {
                            cueManager._emitImpede({
                                type: 'informationDisplay',
                                message: 'Placeholder',
                                init: {
                                    startPath: [ 'main' ],
                                    // correctSequence = sequence of navigations and toggles required for this settings interaction
                                    correctSequence: [ 'driverAssist', 'preCollision', { 'activeBraking': true, 'evasiveSteering': true } ],
                                    tutorialSequence: [ 'down', 'down', 'down', 'right', 'down', 'down', 'down', 'down', 'down', 'right', 'down', 'enter', 'up', 'enter' ],
                                    time: 60
                                },
                                header: 'Information Display'
                            });
                        }
//                        header: 'Information'
                    });
                },
                cueWatch: [ 'w4-1' ]
            },
            
            'w4-1': {
                onTriggerCustom: function() {
                    setDrive('brake', true, true);
                }
            },
            'w4-2': {
                cueWatch: [ 'a2' ]
            },
            a2: {
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                    $('#btn-brake').addClass('assist');
                }
            },
            p5: {
                onEnterCustom: function() {
                    $('.info-alert-pre-collision').removeClass('on');
                    $('.info-alert-pre-collision-2').removeClass('on blink');
                    informationDisplayPopup.hide();
                    
                    setTimeout(function() {
                        setDrive('gas', true, true);
                        $('#btn-brake').removeClass('assist');
                    }, 300);
                    steeringWheelAssist.start('left');
                },
                cueWatch: [ 'w5' ]
            },
            w5: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p6: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        init: {
                            startPath: [ 'main' ],
                            // correctSequence = sequence of navigations and toggles required for this settings interaction
                            correctSequence: [ 'driverAssist', 'preCollision', 'preCollisionSensitivity', { 'preCollisionSensitivityHigh': true } ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right', 'down', 'down', 'down', 'down', 'down', 'right', 'right', 'enter' ],
                            time: 60
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'w6-1' ]
            },
            'w6-1': {
                onTriggerCustom: function() {
                    setDrive('brake', true, true);
                }
            },
            'w6-2': {
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                    $('#btn-brake').addClass('assist');
                }
            },
            p7: {
                onEnterCustom: function() {
                    $('.info-alert-pre-collision').removeClass('on');
                    $('.info-alert-pre-collision-2').removeClass('on blink');
                    informationDisplayPopup.hide();
                    
                    setTimeout(function() {
                        setDrive('gas', true, true);
                        $('#btn-brake').removeClass('assist');
                    }, 300);
                    steeringWheelAssist.start('right');
                },
                cueWatch: [ 'w7-1' ]
            },
            'w7-1': {
                onTriggerCustom: function() {
                    setDrive('brake', true, true);
                }
            },
            'w7-2': {
                cueWatch: [ 'a4' ]
            },
            a4: {
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                    $('#btn-brake').addClass('assist');
                }
            },
            p8: {
                onEnterCustom: function() {
                    $('.info-alert-pre-collision').removeClass('on');
                    $('.info-alert-pre-collision-2').removeClass('on blink');
                    informationDisplayPopup.hide();
                    
                    setTimeout(function() {
                        setDrive('gas', true, true);
                        $('#btn-brake').removeClass('assist');
                    }, 300);
                    steeringWheelAssist.start('right');
                },
                cueWatch: [ 'a5', 'w8-1' ]
            },
            'w8-1': {
                onTriggerCustom: function() {
                    setDrive('brake', true, true);
                }
            },
            'w8-2': {
                cueWatch: [ 'a5' ]
            },
            a5: {
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                    $('#btn-brake').addClass('assist');
                }
            },
            s: {
                onEnterCustom: function() {
                    $('.info-alert-pre-collision').removeClass('on');
                    $('.info-alert-pre-collision-2').removeClass('on blink');
                    informationDisplayPopup.hide();
                    
                    setTimeout(function() {
                        setDrive('gas', true, true);
                        $('#btn-brake').removeClass('assist');
                    }, 300);
                    steeringWheelAssist.start('left');
                },
                message: 'Episode 2 Complete'
            }
        },
        singleVideoWindows: {
            w1: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p2',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-FiveWayLeft'],
                        action: {
                            type: 'seek',
                            toPath: 'p2'
                        }
                    }
                ]
            },
            'w2-1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'w2-2',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'w2-2'
                        }
                    }
                ]
            },
            'w2-2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p3',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p3'
                        }
                    }
                ]
            },
            w3: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p4',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-FiveWayLeft'],
                        action: {
                            type: 'seek',
                            toPath: 'p4'
                        }
                    }
                ]
            },
            'w4-1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'w4-2',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'w4-2'
                        }
                    }
                ]
            },
            'w4-2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p5',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p5'
                        }
                    }
                ]
            },
            w5: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p6',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-FiveWayLeft'],
                        action: {
                            type: 'seek',
                            toPath: 'p6'
                        }
                    }
                ]
            },
            'w6-1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'w6-2',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'w6-2'
                        }
                    }
                ]
            },
            'w6-2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p7',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p7'
                        }
                    }
                ]
            },
            'w7-1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'w7-2',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'w7-2'
                        }
                    }
                ]
            },
            'w7-2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p8',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p8'
                        }
                    }
                ]
            },
            'w8-1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'w8-2',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'w8-2'
                        }
                    }
                ]
            },
            'w8-2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 's',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 's'
                        }
                    }
                ]
            },
        }
    },
    'Episode3': {
        firstPath: 'p1',
        introVideoSrc: 'Episode3_intro.mp4',
        introVideoSrcLow: 'Episode3_intro_halfRes.mp4',
        introVideoSrcLowest: 'Episode3_intro_lowest.mp4',
        customDisclaimer: 'Lane-Keeping System does not control steering.',
        enabledControls: ['btn-left', 'btn-right'],
        maxPoints: 95,
        overview: {
            title: 'Objective:',
            content: 'Watch for <span class="highlight">Evasive Steering Assist</span> alerts, and steer appropriately to avoid obstacles while driving'
        },
        init: function() {
            shifterControl.set('d', false);
            setDrive('gas', true, true);
            $('[data-select-id="crossTrafficAlert"]').addClass('checked');
            informationDisplayPopup.make();
        },
        audioClipNames: [ 'thatsit', 'thatsitsystemisactivated', 'thatshoulddoit' ],
        singleVideoPaths: {
            p1: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 8,
                        delay: 7,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: .125,
                                alpha: cameraTargets.mirrorRear.alpha,
                                beta: cameraTargets.mirrorRear.beta,
                                radius: cameraTargets.mirrorRear.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: .625,
                                alpha: cameraTargets.mirrorRear.alpha,
                                beta: cameraTargets.mirrorRear.beta,
                                radius: cameraTargets.mirrorRear.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: .92,
                                alpha: cameraTargets.mirrorRear.alpha,
                                beta: cameraTargets.mirrorRear.beta,
                                radius: cameraTargets.mirrorRear.radius,
                                blur: 2,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                cueWatch: [ 'w1' ]
            },
            p2: {
                cueWatch: [ 'a1', 'w2' ]
            },
            a1: {
                //nothing!
            },
            w2: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p3: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        laneKeeping: true,
                        init: {
                            startPath: [ 'main' ],
                            // correctSequence = sequence of navigations and toggles required for this settings interaction
                            correctSequence: [ 'driverAssist', 'laneKeeping', 'laneKeepingMode', { 'laneKeepingModeAlert': true } ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right', 'down', 'down', 'down', 'down', 'right', 'right', 'enter' ],
                            time: 60,
                            laneKeepingConfig: {
                                time: 60
                            },
                        },
                        onSuccessCustom: function() {
                            $('.icon-info-lks-lines-left, .icon-info-lks-lines-right').addClass('good');
                            $('.icon-info-tray-lks').addClass('on good');
                            playAudioFromEpisode(0, 2);
                        },
                        onCloseCustom: function() {
                            informationDisplayPopup.show();
                            cameraAnimation.start({
                                duration: 3,
                                delay: .667,
                                keys: [
                                    {
                                        progress: 0,
                                        alpha: cameraTarget.alpha,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: .333,
                                        alpha: -2.18,
                                        beta: 1.63,
                                        radius: 3,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: .667,
                                        alpha: -2.18,
                                        beta: 1.63,
                                        radius: 3,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: 1,
                                        alpha: cameraTarget.alpha,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    }
                                ]
                            });
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'w3' ]
            },
            w3: {
                onEnterCustom: function() {
                    steeringWheelVibration.start();
                    $('.icon-info-lks-lines-right').addClass('bad');
                    $('.icon-info-tray-lks').addClass('bad');
                }
            },
            p4: {
                onEnterCustom: function() {
                    
                },
                cueWatch: [ 'a2', 'w4' ]
            },
            a2: {
                onEnterCustom: function() {
                    steeringWheelVibration.stop();
                    $('.icon-info-lks-lines-right').removeClass('bad');
                    $('.icon-info-tray-lks').removeClass('bad');
                    setTimeout(function() {
                        informationDisplayPopup.hide();
                    }, 2000);
                }
            },
            w4: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p5: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        init: {
                            startPath: [ 'main' ],
                            // correctSequence = sequence of navigations and toggles required for this settings interaction
                            correctSequence: [ 'driverAssist', 'laneKeeping', 'laneKeepingSensitivity', { 'laneKeepingSensitivityHigh': true } ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right', 'down', 'down', 'down', 'down', 'right', 'down', 'right', 'enter' ],
                            time: 60
                        },
                        onSuccessCustom: function() {
                            playAudioFromEpisode(2, 2);
                        },
                        onCloseCustom: function() {
                            informationDisplayPopup.show();
                            cameraAnimation.start({
                                duration: 3.5,
                                delay: 0,
                                keys: [
                                    {
                                        progress: 0,
                                        alpha: cameraTarget.alpha,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: .29,
                                        alpha: cameraTargets.mirrorRear.alpha,
                                        beta: cameraTargets.mirrorRear.beta,
                                        radius: cameraTargets.mirrorRear.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: .83,
                                        alpha: cameraTargets.mirrorRear.alpha,
                                        beta: cameraTargets.mirrorRear.beta,
                                        radius: cameraTargets.mirrorRear.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: 1,
                                        alpha: cameraTargets.center.alpha,
                                        beta: cameraTargets.center.beta,
                                        radius: cameraTargets.center.radius,
                                        blur: 0,
                                        darkness: 0
                                    }
                                ]
                            });
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'w5' ]
            },
            w5: {
                onEnterCustom: function() {
                    steeringWheelVibration.start();
                    $('.icon-info-lks-lines-left').addClass('bad');
                    $('.icon-info-tray-lks').addClass('bad');
                }
            },
            p6: {
                onEnterCustom: function() {
                    
                },
                cueWatch: [ 'a3', 'w6' ]
            },
            a3: {
                onEnterCustom: function() {
                    steeringWheelVibration.stop();
                    steeringWheelVibration.stop();
                    $('.icon-info-lks-lines-left').removeClass('bad');
                    $('.icon-info-tray-lks').removeClass('bad');
                    setTimeout(function() {
                        informationDisplayPopup.hide();
                    }, 1700);
                }
            },
            w6: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            s: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        init: {
                            startPath: [ 'main' ],
                            // correctSequence = sequence of navigations and toggles required for this settings interaction
                            correctSequence: [ 'driverAssist', 'laneKeeping', 'laneKeepingMode', { 'laneKeepingModeBoth': true } ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right', 'down', 'down', 'down', 'down', 'right', 'right', 'down', 'down', 'enter' ],
                            time: 60
                        },
                        onSuccessCustom: function() {
                            $('.icon-info-lks-lines polygon').css('opacity', 1);
                        },
                        header: 'Information Display'
                    });
                },
                message: 'Episode 3 Complete'
            }
        },
        singleVideoWindows: {
            w1: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p2',
                    audio: 'audio/crash1_squeal_wevefoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p2'
                        }
                    }
                ]
            },
            'w2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p3',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-StalkLeft'],
                        action: {
                            type: 'seek',
                            toPath: 'p3'
                        }
                    }
                ]
            },
            w3: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p4',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p4'
                        }
                    }
                ]
            },
            'w4': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p5',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-FiveWayLeft'],
                        action: {
                            type: 'seek',
                            toPath: 'p5'
                        }
                    }
                ]
            },
            w5: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p6',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p6'
                        }
                    }
                ]
            },
            'w6': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 's',
                    audio: 'audio/crash1_wevegotem_team2.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-FiveWayLeft'],
                        action: {
                            type: 'seek',
                            toPath: 's'
                        }
                    }
                ]
            },
        }
    },
    'Episode4': {
        firstPath: 'p1',
        introVideoSrc: 'Episode4_intro.mp4',
        introVideoSrcLow: 'Episode4_intro_halfRes.mp4',
        introVideoSrcLowest: 'Episode4_intro_lowest.mp4',
        customDisclaimer: '',
        enabledControls: ['btn-left', 'btn-right', 'btn-brake', 'btn-gas'],
        maxPoints: 140,
        overview: {
            title: 'Objective:',
            content: 'Watch for <span class="highlight">Evasive Steer Assist</span> alerts, and steer appropriately to avoid obstacles while driving'
        },
        init: function() {
            informationDisplayPopup.make();
            
            shifterControl.set('d', false);
            setDrive('gas', true, true);
            speedometerAnimation.setSpeed(45);
//            $('.icon-info-driving').show();
//            $('.icon-info-driving-lane-left, .icon-info-driving-lane-right').addClass('on good');
            
//            currentGapDistance = 2;
//            $('#adaptive-cruise-icon').attr('data-gap', currentGapDistance);
            
            $('[data-select-id="crossTrafficAlert"]').addClass('checked');
        },
        audioClipNames: [ 'ahhthatsit', 'therewego', 'perfectadjustgap' ],
        singleVideoPaths: {
            p1: {
                onEnterCustom: function() {
                    
                },
                cueWatch: [ 'w1' ]
            },
            w1: {
                onEnterCustom: function() {
                    //pick-Cruise
                    cameraTarget = cameraTargets.steering;
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Turn on <span class="highlight">Cruise Control<span> & set your speed at 65 mph.'
                    });
                }
            },
            p2: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        cruiseControl: true,
                        init: {
                            input: ['btn-cruise-toggle'],
                            inputCount: 1,
                            startingGap: 4,
                            // the rest is unused but if blank will break the code...
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist' ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right' ],
                            time: 60,
                            cruiseControlTime: 60
                        },
                        onSuccessCustom: function() {
                            $('.instrument-panel-speed-unit').text('SET');
                            $('.instrument-panel-speed-group').show();
                            
                            $('.icon-info-tray-cruise').addClass('on');
                        },
                        onCloseCustom: function() {
                            $('#speedometer').removeClass('hidden');
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'w2' ]
            },
            w2: {
                onEnterCustom: function() {
                    //gas on
                }
            },
            p3: {
                onEnterCustom: function() {
                    speedometerAnimation.start({
                        duration: 6.9,
                        keys: [
                            {
                                progress: 0,
                                mph: 45
                            },
                            {
                                progress: 1,
                                mph: 65
                            }
                        ]
                    });
                },
                cueWatch: [ 'w3' ]
            },
            w3: {
                onEnterCustom: function() {
                    //pick-Cruise
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p4: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        cruiseControl: true,
                        init: {
                            input: ['btn-cruise-setminus'],
                            inputCount: 1,
                            // the rest is unused but if blank will break the code...
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist' ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right' ],
                            time: 60,
                            cruiseControlTime: 60
                        },
                        onSuccessCustom: function() {
                            playAudioFromEpisode(0, 2);
                            $('.icon-info-tray-cruise').addClass('set');
                            $('.instrument-panel-speed-unit').text('MPH');
                            $('.instrument-panel-speed').css('opacity', 1).text('65');
                        },
                        onCloseCustom: function() {
                            speedometerAnimation.start({
                                duration: 1.5,
                                keys: [
                                    {
                                        progress: 0,
                                        mph: 65
                                    },
                                    {
                                        progress: 1,
                                        mph: 68
                                    }
                                ]
                            });
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'w4' ]
            },
            w4: {
                onEnterCustom: function() {
                    //gas off - REMOVED
                }
            },
            p5: {
                onEnterCustom: function() {
                    setDrive('gas', false, false);
                    speedometerAnimation.start({
                        duration: 1.5,
                        keys: [
                            {
                                progress: 0,
                                mph: 68
                            },
                            {
                                progress: 1,
                                mph: 65
                            }
                        ]
                    });
                    $('#speedometer').addClass('hidden');
                },
                cueWatch: [ 'w5' ]
            },
            w5: {
                onEnterCustom: function() {
                    //brake on
                }
            },
            p6: {
                onEnterCustom: function() {
                    $('.icon-info-tray-cruise').removeClass('set');
                    $('.instrument-panel-speed').addClass('cancel');
                    setDrive('brake', true, false);
                    setTimeout(function() {
                        setDrive('brake', false, false);
                    }, 500);
                },
                cueWatch: [ 'w6' ]
            },
            w6: {
                onEnterCustom: function() {
                    //pick-Cruise
                    cameraTarget = cameraTargets.steering;
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Use the easiest method to resume cruising at your set speed.'
                    });
                }
            },
            p7: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        cruiseControl: true,
                        init: {
                            input: ['btn-cruise-resplus'],
                            inputCount: 1,
                            // the rest is unused but if blank will break the code...
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist' ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right' ],
                            time: 60,
                            cruiseControlTime: 60
                        },
                        onSuccessCustom: function() {
                            playAudioFromEpisode(1, 2);
                            $('.icon-info-tray-cruise').addClass('set');
                            $('.instrument-panel-speed').removeClass('cancel');
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'w7' ]
            },
            w7: {
                //brake
            },
            p8: {
                onEnterCustom: function() {
                    $('.icon-info-tray-cruise').removeClass('set');
                    $('.instrument-panel-speed').addClass('cancel');
                    setDrive('brake', true, false);
                    setTimeout(function() {
                        setDrive('brake', false, false);
                    }, 500);
                },
                cueWatch: [ 'w8' ]
            },
            w8: {
                onEnterCustom: function() {
                    //pick-FiveWayLeft
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p9: {
                onEnterCustom: function() {
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        init: {
                            startPath: [ 'main' ],
                            // correctSequence = sequence of navigations and toggles required for this settings interaction
                            correctSequence: [ 'driverAssist', 'cruiseControl', { 'cruiseControlModeAdaptive': true } ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right', 'down', 'down', 'right', 'enter' ],
                            time: 60
                        },
                        header: 'Information Display',
                        onSuccessCustom: function() {
                            playAudioFromEpisode(2, 2);

                            $('.icon-info-driving').css('opacity', 1);
                            
                            $('.icon-info-tray-cruise').removeClass('on');
                            $('.icon-info-tray-acc').addClass('on');
                        },
                        onCloseCustom: function() {
                            cueManager._emitImpede({
                                type: 'directional',
                                message: 'Adjust your gap distance to 3. Then, resume cruising.'
                            });
                        }
                    });
                    
                },
                cueWatch: [ 'w9' ]
            },
            w9: {
                onEnterCustom: function() {
                    //pick-Cruise
                }
            },
            p10: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        cruiseControl: true,
                        init: {
                            input: ['btn-cruise-gap', 'btn-cruise-resplus'],
                            inputCount: 1,
                            startingGap: 4,
                            // the rest is unused but if blank will break the code...
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist' ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right' ],
                            time: 60,
                            cruiseControlTime: 60
                        },
                        header: 'Information Display',
                        onSuccessCustom: function() {
                            $('.icon-info-tray-acc').addClass('set');
                            $('.instrument-panel-speed').removeClass('cancel');
                        },
                    });
                },
                cueWatch: [ 'w10' ]
            },
            w10: {
                //brake
            },
            p11: {
                onEnterCustom: function() {
                    $('.icon-info-tray-acc').removeClass('set');
                    $('.instrument-panel-speed').addClass('cancel');
                    setDrive('brake', true, false);
                    setTimeout(function() {
                        setDrive('brake', false, false);
                    }, 500);
                },
                cueWatch: [ 'w11' ]
            },
            w11: {
                onEnterCustom: function() {
                    //pick-Cruise
                    cameraTarget = cameraTargets.steering;
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Adjust your gap to the closest distance between you and the vehicle ahead. Then, resume cruising.'
                    });
                }
            },
            p12: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        cruiseControl: true,
                        init: {
                            input: ['btn-cruise-gap', 'btn-cruise-gap', 'btn-cruise-resplus'],
                            inputCount: 2,
                            startingGap: 3,
                            // the rest is unused but if blank will break the code...
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist' ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right' ],
                            time: 60,
                            cruiseControlTime: 60
                        },
                        onSuccessCustom: function() {
                            $('.icon-info-tray-acc').addClass('set');
                            $('.instrument-panel-speed').removeClass('cancel');
                        },
                        onCloseCustom: function() {
                            cameraAnimation.start({
                                duration: 6,
                                delay: 23.1,
                                keys: [
                                    {
                                        progress: 0,
                                        alpha: cameraTarget.alpha,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: .167,
                                        alpha: -3.416,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: .333,
                                        alpha: -3.416,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: .833,
                                        alpha: -3.649,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    },
                                    {
                                        progress: 1,
                                        alpha: cameraTarget.alpha,
                                        beta: cameraTarget.beta,
                                        radius: cameraTarget.radius,
                                        blur: 0,
                                        darkness: 0
                                    }
                                ]
                            });
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'w12' ]
            },
            w12: {
                //left
            },
            p13: {
                cueWatch: [ 'w13' ]
            },
            w13: {
                onEnterCustom: function() {
                    //pick-Cruise
                    cameraTarget = cameraTargets.steering;
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Turn Lane Centering on.'
                    });
                }
            },
            p14: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        cruiseControl: true,
                        init: {
                            input: ['btn-cruise-centering'],
                            inputCount: 1,
                            // the rest is unused but if blank will break the code...
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist' ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right' ],
                            time: 60,
                            cruiseControlTime: 60
                        },
                        onSuccessCustom: function() {
                            playAudioFromEpisode(1, 2);
                            
                            $('.icon-info-driving-lane-right, .icon-info-driving-lane-left, .icon-info-driving-wheel').addClass('on good');
                            $('.icon-info-driving-car-lower').css('opacity', 0);
                            
//                            $('.icon-lane-centering').addClass('on').show();
                        },
                        header: 'Information Display'
                    });
                },
                cueWatch: [ 'a1', 'w14' ]
            },
            a1: {
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    setTimeout(function() {
                        $('.icon-info-driving-lane-right, .icon-info-driving-lane-left, .icon-info-driving-wheel').removeClass('good');
                    }, 2000);
                }
            },
            w14: {
                onEnterCustom: function() {
                    
                }
                //right
            },
            p15: {
                cueWatch: [ 'w15' ]
            },
            w15: {
                //left
            },
            p16: {
                cueWatch: [ 'w16' ]
            },
            w16: {
                onEnterCustom: function() {
                    //pick-Cruise
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                    
                    informationDisplayPopup.hide();
                }
            },
            s: {
                onEnterCustom: function() {
                    $('.icon-lane-centering').removeClass('on');
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        message: 'Placeholder',
                        cruiseControl: true,
                        init: {
                            input: ['btn-cruise-centering'],
                            inputCount: 1,
                            // the rest is unused but if blank will break the code...
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist' ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right' ],
                            time: 60,
                            cruiseControlTime: 60
                        },
                        onSuccessCustom: function() {
                            $('.icon-info-driving-lane-right, .icon-info-driving-lane-left, .icon-info-driving-wheel').removeClass('on');
                            $('.icon-info-driving-car-lower').css('opacity', 1);
                            
//                            $('.icon-lane-centering').hide();
                        },
                        header: 'Information Display'
                    });
                },
                message: 'Episode 4 Complete'
            }
        },
        singleVideoWindows: {
            w1: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p2',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Cruise'],
                        action: {
                            type: 'seek',
                            toPath: 'p2'
                        }
                    }
                ]
            },
            'w2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p3',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p3'
                        }
                    }
                ]
            },
            w3: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p4',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Cruise'],
                        action: {
                            type: 'seek',
                            toPath: 'p4'
                        }
                    }
                ]
            },
            'w4': {
                inaction: {
                    type: 'seek',
                    toPath: 'p5'
//                    message: 'You took too long',
//                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p5',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
//                    {
//                        inputs: defaultInputs.gas,
//                        action: {
//                            type: 'seek',
//                            toPath: 'p5'
//                        }
//                    }
                ]
            },
            w5: {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p6',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p6'
                        }
                    }
                ]
            },
            'w6': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p7',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Cruise'],
                        action: {
                            type: 'seek',
                            toPath: 'p7'
                        }
                    }
                ]
            },
            'w7': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p8',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p8'
                        }
                    }
                ]
            },
            'w8': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p9',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-FiveWayLeft'],
                        action: {
                            type: 'seek',
                            toPath: 'p9'
                        }
                    }
                ]
            },
            'w9': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p10',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Cruise'],
                        action: {
                            type: 'seek',
                            toPath: 'p10'
                        }
                    }
                ]
            },
            'w10': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p11',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p11'
                        }
                    }
                ]
            },
            'w11': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p12',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Cruise'],
                        action: {
                            type: 'seek',
                            toPath: 'p12'
                        }
                    }
                ]
            },
            'w12': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p13',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p13'
                        }
                    }
                ]
            },
            'w13': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p14',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Cruise'],
                        action: {
                            type: 'seek',
                            toPath: 'p14'
                        }
                    }
                ]
            },
            'w14': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p15',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p15'
                        }
                    }
                ]
            },
            'w15': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p16',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p16'
                        }
                    }
                ]
            },
            'w16': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 's',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: ['pick-Cruise'],
                        action: {
                            type: 'seek',
                            toPath: 's'
                        }
                    }
                ]
            },
        }
    },
    'Episode5': {
        firstPath: 'p1',
        introVideoSrc: 'Episode5_intro.mp4',
        introVideoSrcLow: 'Episode5_intro_halfRes.mp4',
        introVideoSrcLowest: 'Episode5_intro_lowest.mp4',
        customDisclaimer: '',
        enabledControls: ['btn-left', 'btn-right', 'btn-brake', 'btn-gas'],
        maxPoints: 135,
        init: function() {
            $('.icon-info-tray-acc').addClass('on');
            $('.icon-info-tray-blis-off').addClass('on');
            setDrive('gas', true, true);
            
            $('[data-select-id="crossTrafficAlert"]').addClass('checked');
        },
        onConfigDone: function() {
            var blisCues = {
                left: {
                    on: {
                        onEnterCustom: function() {
                            setMeshVisibility('MirrorBlinkLeft', 1);
                        }
                    },
                    off: {
                        onEnterCustom: function() {
                            setMeshVisibility('MirrorBlinkLeft', 0);
                        }
                    }
                },
                right: {
                    on: {
                        onEnterCustom: function() {
                            setMeshVisibility('MirrorBlinkRight', 1);
                        }
                    },
                    off: {
                        onEnterCustom: function() {
                            setMeshVisibility('MirrorBlinkRight', 0);
                        }
                    }
                }
            };
            
            var leftBLIS = [ 2, 3, 6, 7, 9, 10, 12, 13, 16, 18 ];
            var rightBLIS = [ 1, 4, 5, 8, 11, 14, 15, 17, 19 ];
            
            for(var ib = 0; ib < leftBLIS.length; ib++) {
                singleVideoPaths['a' + leftBLIS[ib] + '-1'] = $.extend({}, blisCues.left.on);
                singleVideoPaths['a' + leftBLIS[ib] + '-2'] = $.extend({}, blisCues.left.off);
            }
            for(var ibb = 0; ibb < rightBLIS.length; ibb++) {
                singleVideoPaths['a' + rightBLIS[ibb] + '-1'] = $.extend({}, blisCues.right.on);
                singleVideoPaths['a' + rightBLIS[ibb] + '-2'] = $.extend({}, blisCues.right.off);
            }
        },
        audioClipNames: [ 'thatsit', 'thatsitsystemisactivated' ],
        singleVideoPaths: {
            p1: {
                cueWatch: [ 'w1' ]
            },
            w1: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.steering;
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Watch out for vehicles in your blind spots! Turn <span class="highlight">BLIS</span> on and watch your side mirrors to prevent unwanted blind spot surprises.'
                    });
                    //fiveway
                }
            },
            p2: {
                cueWatch: [ 'w2', 'a1-1' ],
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        init: {
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist', { 'blindSpot': true } ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right', 'enter' ],
                            time: 60
                        },
                        onSuccessCustom: function() {
                            $('.icon-info-tray-blis-off').removeClass('on');
                        },
                        onCloseCustom: function() {
                            cueManager._emitImpede({
                                type: 'directional',
                                message: 'Accelerate and change lanes as needed to keep vehicles out of your blind spots.'
                            });
                        }
                    });
                }
            },
            w2: {
                onEnterCustom: function() {
                    //gas
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    setDatAlert({message: 'VEHICLE on your RIGHT'});
//                    setMeshVisibility('MirrorBlinkRight', 1);
                }
            },
            p3: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    $('.dat-alert').addClass('disabled');
//                    setMeshVisibility('MirrorBlinkRight', 0);
                },
                cueWatch: [ 'w3', 'a1-2' ]
            },
            w3: {
                //right
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p4: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 1,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                cueWatch: [ 'w4', 'a2-1' ]
            },
            w4: {
                onEnterCustom: function() {
                    //gas
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorLeft.alpha,
                                beta: cameraTargets.mirrorLeft.beta,
                                radius: cameraTargets.mirrorLeft.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    setDatAlert({message: 'VEHICLE on your RIGHT'});
//                    setMeshVisibility('MirrorBlinkLeft', 1);
                }
            },
            p5: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    $('.dat-alert').addClass('disabled');
//                    setMeshVisibility('MirrorBlinkLeft', 0);
                },
                cueWatch: [ 'w5', 'a2-2' ]
            },
            w5: {
                //gas
            },
            p6: {
//                onEnterCustom: function() {
//                    setTimeout(function() {
//                        setMeshVisibility('MirrorBlinkLeft', 1);
//                        setTimeout(function() {
//                            setMeshVisibility('MirrorBlinkLeft', 0);
//                        }, 1000);
//                    }, 2000);
//                },
                cueWatch: [ 'w6', 'a3-1', 'a3-2' ]
            },
            w6: {
                //left
            },
            p7: {
                cueWatch: [ 'w7' ]
            },
            w7: {
                //left
            },
            p8: {
                onEnterCustom: function() {
//                    setTimeout(function() {
//                        setMeshVisibility('MirrorBlinkRight', 1);
//                        setTimeout(function() {
//                            setMeshVisibility('MirrorBlinkRight', 0);
//                        }, 750);
//                    }, 2000);
                    
                    setTimeout(function() {
                        cueManager._emitImpede({
                            type: 'directional',
                            message: 'Be careful changing lanes. Obey all traffic laws!'
                        });
                    }, 8000);
                },
                cueWatch: [ 'w8', 'a4-1', 'a4-2', 'a5-1' ]
            },
            w8: {
                onEnterCustom: function() {
                    //gas
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    setDatAlert({message: 'VEHICLE on your RIGHT'});
//                    setMeshVisibility('MirrorBlinkRight', 1);
                }
            },
            p9: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    $('.dat-alert').addClass('disabled');
//                    setMeshVisibility('MirrorBlinkRight', 0);
                },
                cueWatch: [ 'w9' ]
            },
            w9: {
                //right
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p10: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 1,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                cueWatch: [ 'w10', 'a5-2', 'a6-1' ]
            },
            w10: {
                //right
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p11: {
//                onEnterCustom: function() {
//                    setTimeout(function() {
//                        setMeshVisibility('MirrorBlinkRight', 1);
//                        setMeshVisibility('MirrorBlinkLeft', 1);
//                        setTimeout(function() {
//                            setMeshVisibility('MirrorBlinkRight', 0);
//                            setMeshVisibility('MirrorBlinkLeft', 0);
//                        }, 750);
//                    }, 3500);
//                },
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 1,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                cueWatch: [ 'w11', 'a6-2', 'a7-1', 'a8-1', 'a7-2', 'a8-2', 'a9-1' ]
            },
            w11: {
                onEnterCustom: function() {
                    //gas
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorLeft.alpha,
                                beta: cameraTargets.mirrorLeft.beta,
                                radius: cameraTargets.mirrorLeft.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    setDatAlert({message: 'VEHICLE on your RIGHT'});
//                    setMeshVisibility('MirrorBlinkLeft', 1);
                }
            },
            p12: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    $('.dat-alert').addClass('disabled');
//                    setMeshVisibility('MirrorBlinkLeft', 0);
                },
                cueWatch: [ 'w12' ]
            },
            w12: {
                //left
            },
            p13: {
                cueWatch: [ 'w13', 'a9-2', 'a10-1' ]
            },
            w13: {
                onEnterCustom: function() {
                    //right
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    setDatAlert({message: 'VEHICLE on your RIGHT'});
//                    setMeshVisibility('MirrorBlinkLeft', 1);
                }
            },
            p14: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 1,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
//                    $('.dat-alert').addClass('disabled');
//                    setMeshVisibility('MirrorBlinkLeft', 0);
                },
                cueWatch: [ 'w14', 'a10-2' ]
            },
            w14: {
                //gas
            },
            p15: {
//                onEnterCustom: function() {
//                    setTimeout(function() {
//                        setMeshVisibility('MirrorBlinkRight', 1);
//                        setTimeout(function() {
//                            setMeshVisibility('MirrorBlinkRight', 0);
//                        }, 750);
//                    }, 3000);
//                },
                cueWatch: [ 'w15', 'a11-1', 'a12-1', 'a11-2' ]
            },
            w15: {
                //left
//                onEnterCustom: function() {
//                    setMeshVisibility('MirrorBlinkLeft', 1);
//                    setTimeout(function() {
//                        setMeshVisibility('MirrorBlinkLeft', 0);
//                    }, 750);
//                },
            },
            p16: {
                cueWatch: [ 'w16', 'a12-2', 'a13-1', 'a14-1', 'a13-2', 'a14-2' ]
            },
            w16: {
                //left
            },
            p17: {
//                onEnterCustom: function() {
//                    setTimeout(function() {
//                        setMeshVisibility('MirrorBlinkRight', 1);
//                        setTimeout(function() {
//                            setMeshVisibility('MirrorBlinkRight', 0);
//                        }, 750);
//                    }, 3500);
//                },
                cueWatch: [ 'w17', 'a15-1', 'a15-2' ]
            },
            w17: {
                //right
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p18: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 1,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                cueWatch: [ 'w18', 'a16-1', 'a16-2', 'a17-1' ]
            },
            w18: {
                //right
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p19: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 1,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                cueWatch: [ 'w19' ]
            },
            w19: {
                //brake
            },
            p20: {
                cueWatch: [ 'w20', 'a18-1', 'a18-2' ]
            },
            w20: {
                //left
            },
            p21: {
                cueWatch: [ 'w21', 'a17-2' ]
            },
            w21: {
                //gas
            },
            p22: {
                cueWatch: [ 'w22' ]
            },
            w22: {
                //right
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.mirrorRight.alpha,
                                beta: cameraTargets.mirrorRight.beta,
                                radius: cameraTargets.mirrorRight.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p23: {
                cueWatch: [ 'w23', 'a19-1', 'a19-2' ]
            },
            w23: {
                //right
            },
            p24: {
                cueWatch: [ 'w24' ]
            },
            w24: {
                //right
            },
            p25: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 1,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                },
                cueWatch: [ 'w25' ]
            },
            w25: {
                //right
            },
            s: {
                message: 'Episode 5 Complete'
            }
        },
        singleVideoWindows: {
            'w1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p2',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-FiveWayLeft' ],
                        action: {
                            type: 'seek',
                            toPath: 'p2'
                        }
                    }
                ]
            },
            'w2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p3',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p3'
                        }
                    }
                ]
            },
            'w3': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p4',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p4'
                        }
                    }
                ]
            },
            'w4': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p5',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p5'
                        }
                    }
                ]
            },
            'w5': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p6',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p6'
                        }
                    }
                ]
            },
            'w6': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p7',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p7'
                        }
                    }
                ]
            },
            'w7': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p8',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p8'
                        }
                    }
                ]
            },
            'w8': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p9',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p9'
                        }
                    }
                ]
            },
            'w9': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p10',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p10'
                        }
                    }
                ]
            },
            'w10': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p11',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p11'
                        }
                    }
                ]
            },
            'w11': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p12',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p12'
                        }
                    }
                ]
            },
            'w12': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p13',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p13'
                        }
                    }
                ]
            },
            'w13': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p14',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p14'
                        }
                    }
                ]
            },
            'w14': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p15',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p15'
                        }
                    }
                ]
            },
            'w15': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p16',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p16'
                        }
                    }
                ]
            },
            'w16': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p17',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p17'
                        }
                    }
                ]
            },
            'w17': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p18',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p18'
                        }
                    }
                ]
            },
            'w18': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p19',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p19'
                        }
                    }
                ]
            },
            'w19': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p20',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p20'
                        }
                    }
                ]
            },
            'w20': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p21',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p21'
                        }
                    }
                ]
            },
            'w21': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p22',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p22'
                        }
                    }
                ]
            },
            'w22': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p23',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p23'
                        }
                    }
                ]
            },
            'w23': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p24',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p24'
                        }
                    }
                ]
            },
            'w24': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p25',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p25'
                        }
                    }
                ]
            },
            'w25': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 's',
                    audio: 'episodes/Episode4/audio/crashsquealgotem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 's'
                        }
                    }
                ]
            },
        }
    },
    'Episode6': {
        firstPath: 'p1',
        introVideoSrc: 'Episode6_intro.mp4',
        introVideoSrcLow: 'Episode6_intro_halfRes.mp4',
        introVideoSrcLowest: 'Episode6_intro_lowest.mp4',
        customDisclaimer: 'Pre-Collision Assist with Automatic Emergency Braking can detect pedestrians, but not in all conditions and does not replace safe driving.',
        enabledControls: ['btn-left', 'btn-right', 'btn-brake', 'btn-gas'],
        maxPoints: 90,
        init: function() {
            setDrive('brake', true, true);
            
            $('.icon-info-tray-acc').addClass('on');
            
            $('[data-select-id="crossTrafficAlert"]').addClass('checked');
            $('[data-select-id="blindSpot"]').addClass('checked');
//            $('[data-select-id="activeBraking"]').addClass('checked');
            informationDisplay.onChangeItem($('[data-select-id="activeBraking"]'));
            informationDisplay.onChangeItem($('[data-select-id="preCollisionSensitivityHigh"]'));
            $('[data-select-id="evasiveSteering"]').addClass('checked');
            
            informationDisplayPopup.make();
        },
        audioClipNames: [ 'thatsit', 'thatsitsystemisactivated', 'ohforgot' ],
        singleVideoPaths: {
            p1: {
                cueWatch: [ 'w1' ]
            },
            w1: {
                //gas
            },
            p2: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w2', 'a1' ]
            },
            a1: {
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                }
            },
            w2: {
                //brake
            },
            p3: {
                onEnterCustom: function() {
                    $('.info-alert-pre-collision').removeClass('on');
                    $('.info-alert-pre-collision-2').removeClass('on blink');
                    informationDisplayPopup.hide();
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w3' ]
            },
            w3: {
                //gas
            },
            p4: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w4' ]
            },
            w4: {
                //right
            },
            p5: {
                cueWatch: [ 'w5' ]
            },
            w5: {
                //brake
            },
            p6: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w6' ]
            },
            w6: {
                //five-way
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.steering.alpha,
                                beta: cameraTargets.steering.beta,
                                radius: cameraTargets.steering.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            p7: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'informationDisplay',
                        init: {
                            startPath: [ 'main' ],
                            correctSequence: [ 'driverAssist', 'preCollision', 'preCollisionSensitivity' ],
                            tutorialSequence: [ 'down', 'down', 'down', 'right', 'down', 'down', 'down', 'down', 'down', 'right', 'right' ],
                            time: 60
                        },
                        onSuccessCustom: function() {
                            $('#directional-overlay .overlay__button').hide();
                            var aud = playAudioFromEpisode(2, 2);
                            $(aud).on('ended', function() {
                                $('#directional-overlay .overlay__button').show();
                            });
                        }
                    });
                },
                cueWatch: [ 'w7' ]
            },
            w7: {
                //gas
            },
            p8: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w8', 'a2' ]
            },
            a2: {
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                }
            },
            w8: {
                //brake
            },
            p9: {
                onEnterCustom: function() {
                    $('.info-alert-pre-collision').removeClass('on');
                    $('.info-alert-pre-collision-2').removeClass('on blink');
                    informationDisplayPopup.hide();
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w9' ]
            },
            w9: {
                //gas
            },
            p10: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w10' ]
            },
            w10: {
                //left
            },
            p11: {
                cueWatch: [ 'w11' ]
            },
            w11: {
                //brake
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                }
            },
            p12: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                    setTimeout(function() {
                        $('.info-alert-pre-collision').removeClass('on');
                        $('.info-alert-pre-collision-2').removeClass('on blink');
                        informationDisplayPopup.hide();
                    }, 800);
                },
                onExitCustom: function() {
                    cueManager._performAction({ type: 'seek', toPath: 'p13' });
                }
//                cueWatch: [ 'w12' ]
            },
            w12: {
                //five-way
//                onEnterCustom: function() {
//                    cameraAnimation.start({
//                        duration: .4,
//                        keys: [
//                            {
//                                progress: 0,
//                                alpha: cameraTarget.alpha,
//                                beta: cameraTarget.beta,
//                                radius: cameraTarget.radius,
//                                blur: 0,
//                                darkness: 0
//                            },
//                            {
//                                progress: 1,
//                                alpha: cameraTargets.steering.alpha,
//                                beta: cameraTargets.steering.beta,
//                                radius: cameraTargets.steering.radius,
//                                blur: 0,
//                                darkness: 0
//                            }
//                        ]
//                    });
//                }
            },
            p13: {
                onEnterCustom: function() {
//                    cameraTarget = cameraTargets.center;
//                    cueManager._emitImpede({
//                        type: 'informationDisplay',
//                        init: {
//                            startPath: [ 'main' ],
//                            correctSequence: [ 'driverAssist', 'preCollision', { 'activeBraking': true } ],
//                            tutorialSequence: [ 'down', 'down', 'down', 'right', 'down', 'down', 'down', 'down', 'down', 'right', 'down', 'down', 'enter' ],
//                            time: 15
//                        }
//                    });
                },
                cueWatch: [ 'w13' ]
            },
            w13: {
                //left
            },
            p14: {
                cueWatch: [ 'w14' ]
            },
            w14: {
                //gas
            },
            p15: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'a3', 'a4', 'w15' ]
            },
            a3: {
                onEnterCustom: function() {
                    informationDisplayPopup.show();
                    $('.info-alert-pre-collision').addClass('on');
                    $('.info-alert-pre-collision-2').addClass('on blink');
                }
            },
            a4: {
                onEnterCustom: function() {
                    $('#btn-brake').addClass('assist');
                    setDrive('brake', true, true);
                },
                onExitCustom: function() {
                    $('#btn-brake').removeClass('assist');
                    $('.info-alert-pre-collision').removeClass('on');
                    $('.info-alert-pre-collision-2').removeClass('on blink');
                    informationDisplayPopup.hide();
                }
            },
            w15: {
                //gas
            },
            p16: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w16' ]
            },
            w16: {
                //right
            },
            s: {
                message: 'Episode 6 Complete'
            }
        },
        singleVideoWindows: {
            'w1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p2',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p2'
                        }
                    }
                ]
            },
            'w2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p3',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p3'
                        }
                    }
                ]
            },
            'w3': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p4',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p4'
                        }
                    }
                ]
            },
            'w4': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p5',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p5'
                        }
                    }
                ]
            },
            'w5': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p6',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p6'
                        }
                    }
                ]
            },
            'w6': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p7',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-FiveWayLeft' ],
                        action: {
                            type: 'seek',
                            toPath: 'p7'
                        }
                    }
                ]
            },
            'w7': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p8',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p8'
                        }
                    }
                ]
            },
            'w8': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p9',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p9'
                        }
                    }
                ]
            },
            'w9': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p10',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p10'
                        }
                    }
                ]
            },
            'w10': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p11',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p11'
                        }
                    }
                ]
            },
            'w11': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p12',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p12'
                        }
                    }
                ]
            },
            'w12': {
                inaction: {
                    type: 'seek',
                    toPath: 'p13'
//                    message: 'You took too long',
//                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p13',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
//                    {
//                        inputs: [ 'pick-FiveWayLeft' ],
//                        action: {
//                            type: 'seek',
//                            toPath: 'p13'
//                        }
//                    }
                ]
            },
            'w13': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p14',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p14'
                        }
                    }
                ]
            },
            'w14': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p15',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p15'
                        }
                    }
                ]
            },
            'w15': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p16',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p16'
                        }
                    }
                ]
            },
            'w16': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crash1.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 's',
                    audio: 'episodes/Episode6/audio/crashsquealfoundem.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 's'
                        }
                    }
                ]
            },
        }
    },
    'Episode6v2': {
        firstPath: 's',
//        introVideoSrc: 'Episode4_intro.mp4',
//        introVideoSrcLow: 'Episode4_intro_halfRes.mp4',
        customDisclaimer: 'Pre-Collision Assist with Pedestrian Detection can detect pedestrians, but not in all conditions and does not replace safe driving. See owner’s manual for system limitations.',
        enabledControls: ['btn-left', 'btn-right', 'btn-brake', 'btn-gas'],
        maxPoints: 10,
        init: function() {
            
        },
        audioClipNames: [ 'thatsit', 'thatsitsystemisactivated' ],
        singleVideoPaths: {
            s: {
                message: 'Episode 6 Complete'
            }
        },
        singleVideoWindows: {
            
        }
    },
    'Episode7': {
        firstPath: 'p1',
        introVideoSrc: 'Episode7_intro.mp4',
        introVideoSrcLow: 'Episode7_intro_halfRes.mp4',
        introVideoSrcLowest: 'Episode7_intro_lowest.mp4',
        customDisclaimer: '',
        enabledControls: ['btn-left', 'btn-right', 'btn-brake', 'btn-gas'],
        maxPoints: 385,
        init: function() {
            shifterControl.set('d', false);
            setDrive('gas', true, true);
        },
        audioClipNames: [ 'shouldalwaysbeeasy' ],
        singleVideoPaths: {
            p1: {
                onEnterCustom: function() {},
                cueWatch: [ 'w1' ]
            },
            w1: {
                //d,D,btn-right
            },
            p2: {
                onEnterCustom: function() {},
                cueWatch: [ 'w2' ]
            },
            w2: {
                //s,S,btn-brake
            },
            p3: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w3' ]
            },
            w3: {
                //pick-Shifter
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.shifter;
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Shift between <span class="highlight">Drive</span> and <span class="highlight">Reverse</span> to steer the car into position.'
                    });
                }
            },
            p4: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    
                    touchUIMesh.actionManager.actions[0].execute();
                    setTimeout(function() { 
                        touchUIBGMesh.setEnabled(true);
                    }, 400);
                    
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'r',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').show();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w4' ]
            },
            w4: {
                //d,D,btn-right
            },
            p5: {
                onEnterCustom: function() {},
                cueWatch: [ 'w5' ]
            },
            w5: {
                //w,W,btn-gas
            },
            p6: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w6' ]
            },
            w6: {
                //s,S,btn-brake
            },
            p7: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w7' ]
            },
            w7: {
                //pick-Shifter
                onEnterCustom: function() {
//                    cameraTarget = cameraTargets.shifter;
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Shift into <span class="highlight">Drive</span> to access and activate the <span class="highlight">Enhanced Park Aid</span> function'
                    });
                    touchScreenUI.hideMesh();
                    cameraTarget = cameraTargets.shifter;
                }
            },
            p8: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'd',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() { $('.gas-reverse-indicator').hide(); }
                    });
                },
                cueWatch: [ 'w8' ]
            },
            w8: {
                //pick-Touchscreen
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.touchscreen.alpha,
                            beta: cameraTargets.touchscreen.beta,
                            radius: cameraTargets.touchscreen.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p9: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    currentVideo.pause();
                    touchScreenUI.showOverlay({
                        startScreen: 'settings1',
                        time: 60,
                        totalSteps: 4,
                        onHide: function() {
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w9' ]
            },
            w9: {
                //d,D,btn-right
            },
            p10: {
                onEnterCustom: function() {},
                cueWatch: [ 'w10' ]
            },
            w10: {
                //w,W,btn-gas
            },
            p11: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w11' ]
            },
            w11: {
                //s,S,btn-brake
            },
            p12: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w12' ]
            },
            w12: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.hideMesh();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p13: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'r',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').show();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w13' ]
            },
            w13: {
                //a,A,btn-left
            },
            p14: {
                onEnterCustom: function() {},
                cueWatch: [ 'w14' ]
            },
            w14: {
                //w,W,btn-gas
            },
            p15: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w15' ]
            },
            w15: {
                //s,S,btn-brake
            },
            p16: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w16' ]
            },
            w16: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.hideMesh();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p17: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'd',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() { $('.gas-reverse-indicator').hide(); }
                    });
                },
                cueWatch: [ 'w17' ]
            },
            w17: {
                //a,A,btn-left
            },
            p18: {
                onEnterCustom: function() {},
                cueWatch: [ 'w18' ]
            },
            w18: {
                //w,W,btn-gas
            },
            p19: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w19' ]
            },
            w19: {
                //s,S,btn-brake
            },
            p20: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w20' ]
            },
            w20: {
                //pick-ParkAssist
                onEnterCustom: function() {
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Turn on <span class="highlight">Enhanced Active Park Assist</span> to help you maneuver into the parking space.'
                    });
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p21: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    currentVideo.pause();
                    touchScreenUI.parkAssist.start({
                        only: true,
                        time: 60,
                        onHide: function() {
                            touchScreenUI.showMesh();
                        }
                    });
//                    touchScreenUI.showOverlay({
//                        startScreen: 'settings1',
//                        time: 10,
//                        totalSteps: 4,
//                        onHide: function() {
//                            touchScreenUI.showMesh();
//                        }
//                    });
                },
                cueWatch: [ 'w21' ]
            },
            w21: {
                //w,W,btn-gas
            },
            p22: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w22' ]
            },
            w22: {
                //s,S,btn-brake
            },
            p23: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w23' ]
            },
            w23: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.showMeshCompact();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p24: {
                onEnterCustom: function() {
                    touchScreenUI.hideMeshCompact();
                    setTimeout(function() { 
                        touchScreenUI.setMeshNormal();
                    }, 500);
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'r',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').show();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w24' ]
            },
            w24: {
                //w,W,btn-gas
            },
            p25: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w25' ]
            },
            w25: {
                //s,S,btn-brake
            },
            p26: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w26' ]
            },
            w26: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.showMeshCompact();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p27: {
                onEnterCustom: function() {
                    touchScreenUI.hideMeshCompact();
                    setTimeout(function() { 
                        touchScreenUI.setMeshNormal();
                    }, 500);
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'd',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').hide();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w27' ]
            },
            w27: {
                //w,W,btn-gas
            },
            p28: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w28' ]
            },
            w28: {
                //s,S,btn-brake
            },
            p29: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w29' ]
            },
            w29: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.hideMesh();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p30: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'p',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onSuccess: function() {
                            playAudioFromEpisode(0, 2);
                        },
                        onHide: function() {
                            $('.gas-reverse-indicator').hide();
                            setTimeout(function() {
                                touchScreenUI.hideMesh();
                            }, 400);
                        }
                    });
                },
                cueWatch: [ 'w30' ]
            },
            w30: {
                //pick-ParkAssist
                onEnterCustom: function() {
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Use <span class="highlight">Enhanced Active Park Assist</span> to <span class="highlight">park out</span> and continue toward the police station.'
                    });
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p31: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    currentVideo.pause();
                    touchScreenUI.parkAssist.start({
                        only: true,
                        time: 60,
                        onHide: function() {
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w31' ]
            },
            w31: {
                //pick-StalkLeft
                onEnterCustom: function() {
                    touchScreenUI.showMeshCompact();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.steering.alpha,
                            beta: cameraTargets.steering.beta,
                            radius: cameraTargets.steering.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p32: {
                onEnterCustom: function() {
                    currentVideo.pause();
                    touchScreenUI.hideMeshCompact();
                    setTimeout(function() { 
                        touchScreenUI.setMeshNormal();
                    }, 500);
                    cameraTarget = cameraTargets.center;
                    touchScreenUI.turnSignal().start({
                        only: true,
                        time: 60,
                        direction: 'left',
                        showScreens: true,
                        screenOrder: [ 'parallelOutSelectSide', 'parallelOutLeft' ],
                        onSuccess: function() {
                            playAudio('audio/chime_11.mp3', 2);
                        },
                        onHide: function() {
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w32' ]
            },
            w32: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.showMeshCompact();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p33: {
                onEnterCustom: function() {
                    touchScreenUI.hideMeshCompact();
                    setTimeout(function() { 
                        touchScreenUI.setMeshNormal();
                    }, 500);
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'r',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() { 
                            $('.gas-reverse-indicator').show();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w33' ]
            },
            w33: {
                //w,W,btn-gas
            },
            p34: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w34' ]
            },
            w34: {
                //s,S,btn-brake
            },
            p35: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w35' ]
            },
            w35: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.showMeshCompact();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p36: {
                onEnterCustom: function() {
                    touchScreenUI.hideMeshCompact();
                    setTimeout(function() { 
                        touchScreenUI.setMeshNormal();
                    }, 500);
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'd',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').hide();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w36' ]
            },
            w36: {
                //w,W,btn-gas
            },
            p37: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w37' ]
            },
            w37: {
                //d,D,btn-right
            },
            p38: {
                onEnterCustom: function() {
                    touchScreenUI.hideMesh();
                },
                cueWatch: [ 'w38' ]
            },
            w38: {
                //a,A,btn-left
            },
            p39: {
                onEnterCustom: function() {},
                cueWatch: [ 'a2', 'w39' ]
            },
            a2: {
                onEnterCustom: function() {
                    cameraAnimation.start({
                        duration: 3,
                        keys: [
                            {
                                progress: 0,
                                alpha: cameraTarget.alpha,
                                beta: cameraTarget.beta,
                                radius: cameraTarget.radius,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: .4,
                                alpha: -3.4,
                                beta: 1.62,
                                radius: 2,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: .7,
                                alpha: -3.4,
                                beta: 1.62,
                                radius: 2,
                                blur: 0,
                                darkness: 0
                            },
                            {
                                progress: 1,
                                alpha: cameraTargets.center.alpha,
                                beta: cameraTargets.center.beta,
                                radius: cameraTargets.center.radius,
                                blur: 0,
                                darkness: 0
                            }
                        ]
                    });
                }
            },
            w39: {
                //d,D,btn-right
            },
            p40: {
                onEnterCustom: function() {},
                cueWatch: [ 'w40' ]
            },
            w40: {
                //d,D,btn-right
            },
            p41: {
                onEnterCustom: function() {},
                cueWatch: [ 'w41' ]
            },
            w41: {
                //a,A,btn-left
            },
            p42: {
                onEnterCustom: function() {},
                cueWatch: [ 'w42' ]
            },
            w42: {
                //s,S,btn-brake
            },
            p43: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w43' ]
            },
            w43: {
                //pick-ParkAssist
                onEnterCustom: function() {
                    cueManager._emitImpede({
                        type: 'directional',
                        message: 'Use <span class="highlight">Enhanced Active Park Assist</span> to safely park in a <span class="highlight">perpendicular</span> parking spot.'
                    });
                    touchScreenUI.hideMesh();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p44: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    currentVideo.pause();
                    touchScreenUI.parkAssist.start({
                        only: true,
                        time: 60,
                        clicks: 2,
                        showScreens: true,
                        screenOrder: [ 'settingsVehicleCameraEPAOn', 'parallelStart', 'perpendicularStart' ],
                        onHide: function() {
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w44' ]
            },
            w44: {
                //w,W,btn-gas
            },
            p45: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w45' ]
            },
            w45: {
                //s,S,btn-brake
            },
            p46: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w46' ]
            },
            w46: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.showMeshCompact();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p47: {
                onEnterCustom: function() {
                    touchScreenUI.hideMeshCompact();
                    setTimeout(function() { 
                        touchScreenUI.setMeshNormal();
                    }, 500);
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'r',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').show();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w47' ]
            },
            w47: {
                //w,W,btn-gas
            },
            p48: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w48' ]
            },
            w48: {
                //s,S,btn-brake
            },
            p49: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w49' ]
            },
            w49: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.showMeshCompact();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p50: {
                onEnterCustom: function() {
                    touchScreenUI.hideMeshCompact();
                    setTimeout(function() { 
                        touchScreenUI.setMeshNormal();
                    }, 500);
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'd',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').hide();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w50' ]
            },
            w50: {
                //w,W,btn-gas
            },
            p51: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w51' ]
            },
            w51: {
                //s,S,btn-brake
            },
            p52: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w52' ]
            },
            w52: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.showMeshCompact();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            p53: {
                onEnterCustom: function() {
                    touchScreenUI.hideMeshCompact();
                    setTimeout(function() { 
                        touchScreenUI.setMeshNormal();
                    }, 500);
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'r',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() {
                            $('.gas-reverse-indicator').show();
                            touchScreenUI.showMesh();
                        }
                    });
                },
                cueWatch: [ 'w53' ]
            },
            w53: {
                //w,W,btn-gas
            },
            p54: {
                onEnterCustom: function() {
                    setDrive('gas', true, true);
                },
                cueWatch: [ 'w54' ]
            },
            w54: {
                //s,S,btn-brake
            },
            p55: {
                onEnterCustom: function() {
                    setDrive('brake', true, true);
                },
                cueWatch: [ 'w55' ]
            },
            w55: {
                //pick-Shifter
                onEnterCustom: function() {
                    touchScreenUI.hideMesh();
                    cameraAnimation.start({
                        duration: .4,
                        to: {
                            alpha: cameraTargets.shifter.alpha,
                            beta: cameraTargets.shifter.beta,
                            radius: cameraTargets.shifter.radius,
                            blur: 0,
                            darkness: 0
                        }
                    });
                }
            },
            s: {
                onEnterCustom: function() {
                    cameraTarget = cameraTargets.center;
                    cueManager._emitImpede({
                        type: 'shifterControl',
                        message: 'Placeholder',
                        init: {
                            correctLetter: 'p',
                            time: 60
                        },
                        header: 'Shifter Control',
                        onHide: function() { $('.gas-reverse-indicator').hide(); }
                    });
                },
                message: 'Episode 7 Complete'
            },
        },
        singleVideoWindows: {
            'w1': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p2',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p2'
                        }
                    }
                ]
            },
            'w2': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p3',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p3'
                        }
                    }
                ]
            },
            'w3': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p4',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p4'
                        }
                    }
                ]
            },
            'w4': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p5',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p5'
                        }
                    }
                ]
            },
            'w5': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p6',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p6'
                        }
                    }
                ]
            },
            'w6': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p7',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p7'
                        }
                    }
                ]
            },
            'w7': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p8',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p8'
                        }
                    }
                ]
            },
            'w8': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p9',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Touchscreen' ],
                        action: {
                            type: 'seek',
                            toPath: 'p9'
                        }
                    }
                ]
            },
            'w9': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p10',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p10'
                        }
                    }
                ]
            },
            'w10': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p11',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p11'
                        }
                    }
                ]
            },
            'w11': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p12',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p12'
                        }
                    }
                ]
            },
            'w12': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p13',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p13'
                        }
                    }
                ]
            },
            'w13': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p14',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p14'
                        }
                    }
                ]
            },
            'w14': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p15',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p15'
                        }
                    }
                ]
            },
            'w15': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p16',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p16'
                        }
                    }
                ]
            },
            'w16': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p17',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p17'
                        }
                    }
                ]
            },
            'w17': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p18',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p18'
                        }
                    }
                ]
            },
            'w18': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p19',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p19'
                        }
                    }
                ]
            },
            'w19': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p20',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p20'
                        }
                    }
                ]
            },
            'w20': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p21',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-ParkAssist' ],
                        action: {
                            type: 'seek',
                            toPath: 'p21'
                        }
                    }
                ]
            },
            'w21': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p22',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p22'
                        }
                    }
                ]
            },
            'w22': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p23',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p23'
                        }
                    }
                ]
            },
            'w23': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p24',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p24'
                        }
                    }
                ]
            },
            'w24': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p25',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p25'
                        }
                    }
                ]
            },
            'w25': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p26',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p26'
                        }
                    }
                ]
            },
            'w26': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p27',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p27'
                        }
                    }
                ]
            },
            'w27': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p28',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p28'
                        }
                    }
                ]
            },
            'w28': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p29',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p29'
                        }
                    }
                ]
            },
            'w29': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p30',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p30'
                        }
                    }
                ]
            },
            'w30': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p31',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-ParkAssist' ],
                        action: {
                            type: 'seek',
                            toPath: 'p31'
                        }
                    }
                ]
            },
            'w31': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p32',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-StalkLeft' ],
                        action: {
                            type: 'seek',
                            toPath: 'p32'
                        }
                    }
                ]
            },
            'w32': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p33',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p33'
                        }
                    }
                ]
            },
            'w33': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p34',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p34'
                        }
                    }
                ]
            },
            'w34': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p35',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p35'
                        }
                    }
                ]
            },
            'w35': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p36',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p36'
                        }
                    }
                ]
            },
            'w36': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p37',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p37'
                        }
                    }
                ]
            },
            'w37': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p38',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p38'
                        }
                    }
                ]
            },
            'w38': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p39',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p39'
                        }
                    }
                ]
            },
            'w39': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p40',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p40'
                        }
                    }
                ]
            },
            'w40': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p41',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnRight,
                        action: {
                            type: 'seek',
                            toPath: 'p41'
                        }
                    }
                ]
            },
            'w41': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p42',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.turnLeft,
                        action: {
                            type: 'seek',
                            toPath: 'p42'
                        }
                    }
                ]
            },
            'w42': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p43',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p43'
                        }
                    }
                ]
            },
            'w43': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p44',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-ParkAssist' ],
                        action: {
                            type: 'seek',
                            toPath: 'p44'
                        }
                    }
                ]
            },
            'w44': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p45',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p45'
                        }
                    }
                ]
            },
            'w45': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p46',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p46'
                        }
                    }
                ]
            },
            'w46': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p47',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p47'
                        }
                    }
                ]
            },
            'w47': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p48',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p48'
                        }
                    }
                ]
            },
            'w48': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p49',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p49'
                        }
                    }
                ]
            },
            'w49': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p50',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p50'
                        }
                    }
                ]
            },
            'w50': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p51',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p51'
                        }
                    }
                ]
            },
            'w51': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p52',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p52'
                        }
                    }
                ]
            },
            'w52': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p53',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 'p53'
                        }
                    }
                ]
            },
            'w53': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p54',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.gas,
                        action: {
                            type: 'seek',
                            toPath: 'p54'
                        }
                    }
                ]
            },
            'w54': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 'p55',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: defaultInputs.brake,
                        action: {
                            type: 'seek',
                            toPath: 'p55'
                        }
                    }
                ]
            },
            'w55': {
                inaction: {
                    type: 'retry',
                    message: 'You took too long',
                    audio: 'audio/crashsmall_squeal_theretheyare.mp3'
                },
                tutorial: {
                    message: 'You\'re caught! Let\'s see what should have happened.',
                    toPath: 's',
                    audio: 'audio/crashsmall_squeal_theretheyare_gameover.mp3'
                },
                triggers: [
                    {
                        inputs: [ 'pick-Shifter' ],
                        action: {
                            type: 'seek',
                            toPath: 's'
                        }
                    }
                ]
            },
        }
    }
};

var episodeName,
    firstPath,
    singleVideoPaths = {},
    singleVideoWindows = {},
    singleVideoExtraCues = [],
    singleVideoCues = [],
    cueWatch = [],
    introVideoSrc,
    introVideoSrcLow,
    introVideoSrcLowest,
    datConfiguration,
    episodeOverview,
    episodeAudioClips = [],
    enabledControls = [],
    maxPoints,
    episodeInit,
    customDisclaimer;

var setEpisodeConfig = function(ep) {
    dev.log('Setting episode configuration');
    
    for(var j = 0; j < defaultVars.length; j++) {
        if(window[defaultVars[j][0]] !== undefined) {
            window[defaultVars[j][0]] = defaultVars[j][1];
        }
    }
    
    if(episodes[ep] && episodeCues[ep]) {
        singleVideo.fileName = episodeName = ep;
        
        var episode = episodes[singleVideo.fileName];
        
        firstPath =             episode.firstPath;
        singleVideoPaths =      episode.singleVideoPaths;
        singleVideoWindows =    episode.singleVideoWindows;
        singleVideoExtraCues =  episodeCues[singleVideo.fileName].singleVideoExtraCues;
        singleVideoCues =       episodeCues[singleVideo.fileName].singleVideoCues;
        introVideoSrc =         episode.introVideoSrc;
        introVideoSrcLow =      episode.introVideoSrcLow;
        introVideoSrcLowest =   episode.introVideoSrcLowest;
        datConfiguration =      episode.datConfiguration;
        episodeOverview =       episode.overview;
        enabledControls =       episode.enabledControls;
        maxPoints =             episode.maxPoints;
        episodeInit =           episode.init;
        customDisclaimer =      episode.customDisclaimer;
        
        $('#title-overlay').attr('style', 'background: url(episodes/' + episodeName + '/images/title-screen.jpg), black;background-repeat: no-repeat;background-size: contain;background-position: center;');
        
        var fullDisclaimer = defaultDisclaimer + (customDisclaimer ? ('<br><br>' + customDisclaimer) : '');
        $('#legal-overlay .overlay__message').html(fullDisclaimer);
        
        for(var i = 0; i < episode.audioClipNames.length; i++) {
            episodeAudioClips.push('episodes/' + episodeName + '/audio/' + episode.audioClipNames[i] + '.mp3');
        }
        
        if(autoPilotMode) {
            $('body').addClass('autopilot');
            var windowPointCount = 0;
            for(var j = 0; j < singleVideoCues.length; j++) {
                if(singleVideoCues[j].name.indexOf('w') === 0) {
                    if(singleVideoPaths[singleVideoCues[j].name]) {
                        singleVideoPaths[singleVideoCues[j].name].mode = 'autopilot';
                    }
                    if(singleVideoWindows[singleVideoCues[j].name]) {
                        singleVideoWindows[singleVideoCues[j].name].inaction = {
                            type: 'seekNow',
                            toPath: singleVideoWindows[singleVideoCues[j].name].tutorial.toPath
                        }
                        if(singleVideoWindows[singleVideoCues[j].name].triggers.length) {
                            windowPointCount += 1;
                        }
                    }
                }
            }
            maxPoints -= (windowPointCount * 5);
        }
        
        if(disableScoring) {
            $('body').addClass('no-score');
        }
        
        if(disableTimer) {
            $('body').addClass('no-timer');
        }
        
        if(episode.onConfigDone && typeof episode.onConfigDone === 'function') {
            episode.onConfigDone();
        }
        
    } else {
        console.error('Invalid episode name: ' + ep);
    }
};

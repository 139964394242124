var fileHandler = {
    /**
     * Loads a file with progress callback.
     *
     * The `onprogress` callback will be called by XMLHttpRequest's onprogress
     * event, and will receive the loading progress ratio as an whole number.
     * However, if it's not possible to compute the progress ratio, `onprogress`
     * will be called only once passing -1 as progress value. This is useful to,
     * for example, change the progress animation to an undefined animation.
     *
     * @param  {string}   imageUrl   The image to load
     * @param  {Function} onprogress
     * @return {Promise}
     */
    load: function(url, onprogress) {
        var _this = this;
        return new Promise(function(resolve, reject) {
            _this.xhr = new XMLHttpRequest();
            var notifiedNotComputable = false;

            _this.xhr.open('GET', url, true);
            _this.xhr.responseType = 'arraybuffer';

            _this.xhr.onprogress = function(ev) {
                if (ev.lengthComputable) {
                  onprogress(ev.loaded, ev.total);
                } else {
                  if (!notifiedNotComputable) {
                    notifiedNotComputable = true;
                    onprogress(-1);
                  }
                }
            }

            _this.xhr.onloadend = function() {
              if (!_this.xhr.status.toString().match(/^2/)) {
                reject(_this.xhr);
              } else {
                if (!notifiedNotComputable) {
                  onprogress(100);
                }

                var options = {}
                var headers = _this.xhr.getAllResponseHeaders();
                var m = headers.match(/^Content-Type\:\s*(.*?)$/mi);

                if (m && m[1]) {
                  options.type = m[1];
                }

                var blob = new Blob([this.response], options);

                resolve(window.URL.createObjectURL(blob));
              }
            }

            _this.xhr.send();
        });
    },
    done: function() {
        this.xhr = null;
    },
    abort: function() {
        if(this.xhr) {
            this.xhr.abort();
            this.xhr = null;
        }
    }
};

var networkTest = {
    img4M: 'images/network-test-4M.jpg',
    downloadSize4M: 4412130,
    img8M: 'images/network-test-8M.jpg',
    downloadSize8M: 8100102,
    repeatSizeIndex: 1,
    repeatCount: 1,
    sizes: [
        {
            imgSrc: 'images/network-test-4M.jpg',
            downloadSize: 4412130
        },
        {
            imgSrc: 'images/network-test-8M.jpg',
            downloadSize: 8100102
        },
        {
            imgSrc: 'images/network-test-16M.jpg',
            downloadSize: 16356739
        }
    ],
    ShowProgressMessage: function(msg) {
        if (console) {
            if (typeof msg == "string") {
                if(dev) {
                    dev.log(msg);
                } else {
                    console.log(msg);
                }
            } else {
                for (var i = 0; i < msg.length; i++) {
                    console.log(msg[i]);
                    if(dev) {
                        dev.log(msg[i]);
                    } else {
                        console.log(msg[i]);
                    }
                }
            }
        }

        var oProgress = $("#performance-test__message");
        if (oProgress) {
            var actualHTML = (typeof msg == "string") ? msg : msg.join("<br />");
            oProgress.html(actualHTML);
        }
    },
    InitiateSpeedDetection: function(onEnd) {
        this.ShowProgressMessage("Running connection speed test...");
        this.runCount = 0;
        this.onEnd = (onEnd && typeof onEnd === 'function') ? onEnd : function() {};
        window.setTimeout(function() {
            if(networkTest.repeatSizeIndex !== undefined) {
                networkTest.MeasureConnectionSpeed(networkTest.repeatSizeIndex);
            } else {
                networkTest.MeasureConnectionSpeed(0);
            }
        }, 1);
    },
    MeasureConnectionSpeed: function(sizeIndex) {
        var _this = this;
        
        var startTime, endTime;
        var imgSrc = this.sizes[sizeIndex].imgSrc;
        var downloadSize = this.sizes[sizeIndex].downloadSize;
        
        var abortDownload = false;
        var downloadAborted = false;
        
        startTime = (new Date()).getTime();
        var cacheBuster = "?nnn=" + startTime;
        
        var downloadTimeout = setTimeout(function() {
            abortDownload = true;
        }, 5000);
        
        fileHandler.load(imgSrc + cacheBuster, function(loadedBytes, totalBytes) {
            // progress
            if (loadedBytes == -1) {
                // loadedBytes not computable
            } else {
                if(abortDownload && !downloadAborted) {
                    downloadAborted = true;
                    dev.log("Network test timed out (5s)");
                    fileHandler.abort();
                    downloadSize = loadedBytes;
                    endTime = (new Date()).getTime();
                    showResults();
                }
            }
        }).then(function(src) {
            // Loading successfuly complete
            clearTimeout(downloadTimeout);
            endTime = (new Date()).getTime();
            showResults();
        }, function(xhr) {
          // An error occured. We have the XHR object to see what happened.
            if(!downloadAborted) _this.ShowProgressMessage("Invalid image, or error downloading");
        });

        function showResults() {
            var duration = (endTime - startTime) / 1000;
            var bitsLoaded = downloadSize * 8;
            var speedBps = (bitsLoaded / duration).toFixed(2);
            var speedKbps = (speedBps / 1024).toFixed(2);
            var speedMbps = (speedKbps / 1024).toFixed(2);
            
//            _this.ShowProgressMessage('Downloaded ' + downloadSize + ' bytes in ' + duration + 's');
            dev.log("Connection speed: " + speedMbps + 'Mbps');
            
            _this.runCount++;
            
            if(_this.repeatSizeIndex !== undefined && _this.runCount <= _this.repeatCount - 1) {
                _this.MeasureConnectionSpeed(_this.repeatSizeIndex);
            } else if(_this.runCount < _this.sizes.length && speedMbps > 200) {
                _this.MeasureConnectionSpeed(_this.runCount);
            } else {
                _this.downloadSpeed = speedMbps;
                _this.onEnd();
            }
//            _this.ShowProgressMessage([
//                "Your connection speed is:", 
//                speedBps + " bps", 
//                speedKbps + " kbps", 
//                speedMbps + " Mbps"
//            ]);
        }
    },
    init: function() {
        if (window.addEventListener) {
            window.addEventListener('load', function() { networkTest.InitiateSpeedDetection(); }, false);
        } else if (window.attachEvent) {
            window.attachEvent('onload', function() { networkTest.InitiateSpeedDetection(); });
        }
    }
};

//networkTest.init();

var speedometerAnimation = {
    setSpeed: function(mph) {
        this.currentMph = mph;
        $('#speedometer-dial').css('transform', 'rotate(' + mph * 1.5 + 'deg)');
    },
    init: function(config) {
        this.keys = config.keys;
        this.duration = config.duration;
        this.delay = config.delay ? config.delay : 0;
        this.onEndCustom = (config.onEnd && typeof config.onEnd === 'function') ? config.onEnd : null;
        this.keyIndex = 0;

        if(this.keys && this.keys.length > 1) {
            this.startKey = this.keys[0];
            this.endKey = this.keys[1];

            for(var i = 0; i < this.keys.length; i++) {
                this.keys[i].time = this.duration * this.keys[i].progress;

                if(i < this.keys.length - 1) {
                    this.keys[i].duration = this.duration * (this.keys[i + 1].progress - this.keys[i].progress);

                    this.keys[i].dMph = this.keys[i + 1].mph - this.keys[i].mph;
                } else {
                    this.keys[i].duration = 0;
                }
            }

            dev.log('Speedometer animation init');
            dev.log('Camera keys: ' + JSON.stringify(this.keys));
        }
    },
    start: function(config) {
        this.init(config);
        this.startTime = currentVideo.currentTime + this.delay;
        this.isActive = true;
        this.playing = false;
    },
    clear: function() {
        this.isActive = false;
        this.playing = false;
        this.keys = null;
        this.duration = null;
    },
    onEnd: function() {
        // Set values to last key
        this.setSpeed(this.keys[this.keys.length - 1].mph);
        
        this.clear();

        if(this.onEndCustom) this.onEndCustom();
    },
    renderLoop: function(currentVideoTime) {
        var currentAnimTime = currentVideoTime - this.startTime;
        if(currentAnimTime > this.duration) {
            this.onEnd();
            this.playing = false;
            return;
        }
        if(this.startKey && this.endKey) {
            if(this.startKey.time <= currentAnimTime && currentAnimTime < this.endKey.time) {
                if(!this.startKey.played) {
                    this.startKey.played = true;
                    this.playing = true;
                }
                var t = (currentAnimTime - this.startKey.time) / this.startKey.duration;
                var y = easeInOut(t);

                this.setSpeed(this.startKey.mph + y * this.startKey.dMph);
            } else if (this.startKey.played) {
                this.keyIndex++;
                if(this.keyIndex + 1 < this.keys.length) {
                    this.startKey = this.keys[this.keyIndex];
                    this.endKey = this.keys[this.keyIndex + 1];
                } else {
                    this.startKey = null;
                    this.endKey = null;
                }
            }
        }
    }
};
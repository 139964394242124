var touchScreenUI = {
    screens: {
        settings1: {
            image: 'images/touchscreen/Settings-01.jpg',
            showArrows: true,
            rightArrowEnable: true,
            rightArrowClick: function(ev) { touchScreenUI.toScreen('settings2', ev.target); }
        },
        settings2: {
            image: 'images/touchscreen/Settings-02.jpg',
            hits: [
                {
                    x: 599, y: 75,
                    w: 156, h: 130,
                    click: function(ev) { touchScreenUI.toScreen('settingsVehicle', ev.target); }
                }
            ],
            showArrows: true,
            leftArrowEnable: true
        },
        settingsVehicle: {
            image: 'images/touchscreen/Settings_Vehicle.jpg',
            hits: [
                {
                    x: 79, y: 139,
                    w: 576, h: 70,
                    click: function(ev) { touchScreenUI.toScreen('settingsVehicleCamera', ev.target); }
                }
            ]
        },
        settingsVehicleCamera: {
            image: 'images/touchscreen/Settings_Vehicle_Camera.jpg',
            hits: [
                {
                    x: 79, y: 179,
                    w: 576, h: 70,
                    click: function(ev) { touchScreenUI.toScreen('settingsVehicleCameraEPAOn', ev.target); }
                }
            ]
        },
        settingsVehicleCameraEPAOn: {
            image: 'images/touchscreen/Settings_Vehicle_Camera-EPAOn.jpg'
        },
        parallelStart: {
            image: 'images/touchscreen/Parallel_Start.jpg'
        },
        perpendicularStart: {
            image: 'images/touchscreen/Perpendicular_Start.jpg'
        },
        parallelOutSelectSide: {
            image: 'images/touchscreen/Parallel_Out_SelectSide.jpg'
        },
        parallelOutLeft: {
            image: 'images/touchscreen/Parallel_Out_Left.jpg'
        }
    },
    setMeshNormal: function() {
        touchUIBGMesh.scaling.x = 1;
        touchUIBGMesh.scaling.y = 1;
    },
    showMesh: function() {
        touchUIBGMesh.setEnabled(true);
        touchUIMesh.actionManager.actions[1].execute();
    },
    hideMesh: function() {
        touchUIMesh.actionManager.actions[0].execute();
        setTimeout(function() { 
            touchUIBGMesh.setEnabled(false);
        }, 400);
    },
    setMeshCompact: function() {
        touchUIBGMesh.scaling.x = .8;
        touchUIBGMesh.scaling.y = .8;
    },
    showMeshCompact: function() {
        touchUIBGMesh.setEnabled(true);
        touchUIMesh.actionManager.actions[3].execute();
    },
    hideMeshCompact: function() {
        touchUIMesh.actionManager.actions[2].execute();
        setTimeout(function() { 
            touchUIBGMesh.setEnabled(false);
        }, 400);
    },
    showOverlay: function(config) {
        this.mode = 'init';
        this.stepCount = 0;
        this.stepBookmark = 0;
        this.config = {
            startScreen: config.startScreen,
            time: config.time,
            totalSteps: config.totalSteps,
            onHide: config.onHide ? config.onHide : null,
            onSuccess: config.onSuccess ? config.onSuccess : null
        };
        this.toScreen(this.config.startScreen);
        $('#touchscreen-ui-overlay .overlay__button').addClass('disabled');
        $('#touchscreen-ui-overlay').removeClass('show-park show-park-only show-stalk show-stalk-only hidden');
        this.mode = 'normal';
        this.controlsEnabled = true;
        overlayTimer.init({
            time: this.config.time,
            levels: true,
            onComplete: this.onFailure.bind(this)
        });
        overlayTimer.start();
        this.parkAssistOnly = false;
        this.stalkOnly = false;
    },
    parkAssist: {
        start: function(config) {
            var _this = this;
            var _parent = this.parent;
            _parent.mode = 'init';
            _parent.config = {
                time: config.time,
                clicks: config.clicks ? config.clicks : 1,
                parkAssistOnly: config.only ? true : false,
                showScreens: config.showScreens ? true : false,
                screenOrder: config.screenOrder,
                onHide: config.onHide ? config.onHide : function() {},
                onSuccess: config.onSuccess ? config.onSuccess : function() {}
            };
            _this.clickBookmark = 0;
            _this.clicksDone = 0;
            $('#touchscreen-ui-overlay').removeClass('show-park show-park-only show-stalk show-stalk-only');
            if(config.only && !config.showScreens) {
                $('#touchscreen-ui-overlay').addClass('show-park-only');
            } else {
                $('#touchscreen-ui-overlay').addClass('show-park');
            }

            if(_parent.config.showScreens) _parent.toScreen(_parent.config.screenOrder[0]);

            $('#btn-park-cluster-parkAssist').click(function() {
                if(_parent.controlsEnabled) {
                    _this.parkClick($('#btn-park-cluster-parkAssist'));
                }
            });
            $('#touchscreen-ui-overlay .overlay__button').addClass('disabled');
            $('#touchscreen-ui-overlay').removeClass('hidden');
            _parent.mode = 'normal';
            _parent.controlsEnabled = true;
            overlayTimer.init({
                time: _parent.config.time,
                levels: true,
                onComplete: _parent.onFailure.bind(_parent)
            });
            overlayTimer.start();
        },
        parkClick: function(target) {
            var _this = this;
            var _parent = this.parent;
            _this.clicksDone++;
            if(_parent.config.showScreens) _parent.toScreen(_parent.config.screenOrder[_this.clicksDone]);

            target.addClass('selected');
            setTimeout(function() {
                target.removeClass('selected');
            }, 200);

            if((_this.clicksDone > _this.clickBookmark) && 
               _parent.mode !== 'init' &&
               _parent.mode != 'tutorial' &&
               _parent.controlsEnabled) {
                _parent.scored(target);
                _this.clickBookmark = _this.clicksDone;
            }
            if(_this.clicksDone === _parent.config.clicks) {
                $('#btn-park-cluster-parkAssist')
                    .off('click')
                    .removeClass('prompt');
                _parent.onSuccess();
            }
        }
    },
    turnSignal: function() {
        var _this = this;
        return {
            start: function(config) {
                var __this = this;
                _this.mode = 'init';
                _this.config = {
                    direction: config.direction,
                    time: config.time,
                    stalkOnly: config.only ? true : false,
                    showScreens: config.showScreens ? true : false,
                    screenOrder: config.screenOrder,
                    onHide: config.onHide ? config.onHide : function() {},
                    onSuccess: config.onSuccess ? config.onSuccess : function() {}
                };
                $('#touchscreen-ui-overlay').removeClass('show-park show-park-only show-stalk show-stalk-only');
                if(config.only && !config.showScreens) {
                    $('#touchscreen-ui-overlay').addClass('show-stalk-only');
                } else {
                    $('#touchscreen-ui-overlay').addClass('show-stalk');
                }
                
                if(_this.config.showScreens) _this.toScreen(_this.config.screenOrder[0]);
                
                $('#btn-turn-signal-' + _this.config.direction).click(function() {
                    if(_this.controlsEnabled) {
                        __this.correctClick($('#btn-turn-signal-' + _this.config.direction));
                    }
                });
                $('#touchscreen-ui-overlay .overlay__button').addClass('disabled');
                $('#touchscreen-ui-overlay').removeClass('hidden');
                _this.mode = 'normal';
                _this.controlsEnabled = true;
                overlayTimer.init({
                    time: _this.config.time,
                    levels: true,
                    onComplete: _this.onFailure.bind(_this)
                });
                overlayTimer.start();
            },
            correctClick: function(target) {
                if(_this.mode !== 'init' && _this.mode !== 'tutorial' && _this.controlsEnabled) {
                    _this.scored(target);
                }
                if(_this.config.showScreens) _this.toScreen(_this.config.screenOrder[1]);
                $('#stalk-signal-wrapper .decision-tree-button')
                    .off('click')
                    .removeClass('prompt');
                
                target.addClass('selected');
                setTimeout(function() {
                    target.removeClass('selected');
                }, 200);
                
                _this.onSuccess();
            }
        };
    },
    initSubObjects: function() {
        this.parkAssist.parent = this;
        return this;
    },
    reset: function() {
        this.mode = 'init';
        if(this.config.parkAssistOnly) {
            this.parkAssist.clicksDone = 0;
            $('#btn-park-cluster-parkAssist').removeClass('prompt selected');
            if(this.config.showScreens) {
                this.toScreen(this.config.screenOrder[0]);
            }
            return;
        }
        if(this.config.stalkOnly) {
            $('#stalk-signal-wrapper .decision-tree-button').removeClass('prompt selected');
            if(this.config.showScreens) {
                this.toScreen(this.config.screenOrder[0]);
            }
            return;
        }
        this.toScreen(this.config.startScreen);
        this.stepCount = 0;
    },
    toScreen: function(screenID, fromElem) {
        dev.log('toScreen: ' + screenID);
        $('.touchscreen-ui-screen-hits > div, .touchscreen-ui-nav-right, .touchscreen-ui-nav-left').removeClass('selected prompt');
        var _this = this;
        if(this.screens[screenID] && (this.controlsEnabled || this.mode == 'init' || this.mode == 'tutorial')) {
            if(this.mode !== 'init' && !this.config.parkAssistOnly && !this.config.stalkOnly) {
                this.stepCount++;
                if(this.mode !== 'tutorial') {
                    if(this.stepCount > this.stepBookmark && this.controlsEnabled) {
                        this.scored($(fromElem));
                        this.stepBookmark = this.stepCount;
                    }
                }
                if(this.stepCount == this.config.totalSteps) this.onSuccess();
            }
            
            var screen = this.screens[screenID];
            this.currentScreen = screen;
            
            $('.touchscreen-ui-screen-l1').css('background-image', $('.touchscreen-ui-screen-l2').css('background-image'));
            
            var imgSrc = this.screens[screenID].image;

            if(this.controlsEnabled) {
                //if the image takes a bit to load, we can ensure it won't affect time
                overlayTimer.pause();
            }
            $('.touchscreen-ui-nav-right, .touchscreen-ui-nav-left').off('click');
            $('.touchscreen-ui-screen-hits').html('');
            var imgLoadStart = new Date();
            var img = new Image();
            img.onload = function() {
                dev.log('toScreen: Next screen loaded in ' + (new Date() - imgLoadStart) + 'ms');
                $('.touchscreen-ui-screen-l2').css('background-image', 'url(' + imgSrc + ')');

                if(screen.hits) {
                    for(var h = 0; h < screen.hits.length; h++) {
                        var hitClick = screen.hits[h].click ? screen.hits[h].click : function(){};
                        var hitElem = $('<div>')
                            .css({
                                width:  screen.hits[h].w / 480 + 'em',
                                height: screen.hits[h].h / 480 + 'em',
                                left:   screen.hits[h].x / 480 + 'em',
                                top:    screen.hits[h].y / 480 + 'em'
                            })
                            .addClass('btn-template')
                            .click(hitClick);

                        if(this.mode === 'hint') hitElem.addClass('prompt');

                        $('.touchscreen-ui-screen-hits').append(hitElem);

                    }
                }

                if(screen.showArrows) {
                    $('.touchscreen-ui-nav-right, .touchscreen-ui-nav-left').show();
                    if(screen.leftArrowEnable) {
                        $('.touchscreen-ui-nav-left')
                            .removeClass('disabled')
                            .click(screen.leftArrowClick ? screen.leftArrowClick : function(){});

                        if(this.mode === 'hint' && screen.leftArrowClick) $('.touchscreen-ui-nav-left').addClass('prompt');
                    } else {
                        $('.touchscreen-ui-nav-left').addClass('disabled').off('click');
                    }
                    if(screen.rightArrowEnable) {
                        $('.touchscreen-ui-nav-right')
                            .removeClass('disabled')
                            .click(screen.rightArrowClick ? screen.rightArrowClick : function(){});

                        if(this.mode === 'hint' && screen.rightArrowClick) $('.touchscreen-ui-nav-right').addClass('prompt');
                    } else {
                        $('.touchscreen-ui-nav-right').addClass('disabled').off('click');
                    }
                } else {
                    $('.touchscreen-ui-nav-right, .touchscreen-ui-nav-left').hide();
                }
                
                if(this.controlsEnabled) overlayTimer.resume();
            }.bind(this);
            
            img.src = imgSrc;
        } else {
            dev.warn('touchScreenUI: Cannot perform toScreen');
        }
    },
    showHints: function() {
        if(this.config.parkAssistOnly) {
            $('#btn-park-cluster-parkAssist').addClass('prompt');
            return;
        }
        if(this.config.stalkOnly) {
            $('#btn-turn-signal-' + this.config.direction).addClass('prompt');
            return;
        }
        
        $('.touchscreen-ui-screen-hits > div').addClass('prompt');
        var screen = this.currentScreen;
        if(screen.leftArrowClick) {
            $('.touchscreen-ui-nav-left').addClass('prompt');
        }
        if(screen.rightArrowClick) {
            $('.touchscreen-ui-nav-right').addClass('prompt');
        }
    },
    startTutorial: function() {
        var _this = this;
        
        if(this.config.parkAssistOnly) {
            dev.log('Starting Park Assist tutorial');
            var clickCount = 0;
            var intFunc = function() {
                $('#btn-park-cluster-parkAssist').addClass('selected');
                _this.parkAssist.parkClick($('#btn-park-cluster-parkAssist'));
                setTimeout(function() {
                    $('#btn-park-cluster-parkAssist').removeClass('selected');
                    if(_this.parkAssist.clicksDone < _this.config.clicks) {
                        clickCount++;
                        setTimeout(intFunc, 1000);
                    }
                }, 200);
            };
            setTimeout(intFunc, 1000);
            return;
        }
        
        if(this.config.stalkOnly) {
            dev.log('Starting Turn Signal tutorial');
            setTimeout(function() {
                $('#btn-turn-signal-' + _this.config.direction).addClass('selected');
                _this.turnSignal().correctClick($('#btn-turn-signal-' + _this.config.direction));
                setTimeout(function() {
                    $($('#btn-turn-signal-' + _this.config.direction)).removeClass('selected');
                }, 200);
            }, 1000);
            return;
        }
        
        dev.log('Starting Touchscreen UI tutorial');
        var intFunc = function() {
            var screen = _this.currentScreen;
            if(screen.leftArrowClick) {
                $('.touchscreen-ui-nav-left').addClass('selected');
            }
            if(screen.rightArrowClick) {
                $('.touchscreen-ui-nav-right').addClass('selected');
            }
            $('.touchscreen-ui-screen-hits > div').addClass('selected');
            setTimeout(function() {
                $('.touchscreen-ui-screen-hits > div, .touchscreen-ui-nav-right, .touchscreen-ui-nav-left').removeClass('selected');
                if(screen.hits) screen.hits[0].click({ target: $('.touchscreen-ui-screen-hits > div')});
                else if(screen.leftArrowClick) screen.leftArrowClick({ target: $('.touchscreen-ui-nav-left')});
                else if(screen.rightArrowClick) screen.rightArrowClick({ target: $('.touchscreen-ui-nav-right')});
                
                if(_this.stepCount < _this.config.totalSteps) setTimeout(intFunc, 1000);
            }, 200);
        };
        setTimeout(intFunc, 1000);
    },
    onClose: function() {
        this.controlsEnabled = false;
        this.isActive = false;
        
        currentImpediment = {};
        controlsEnabled = true;
        mainControlsEnabled = true;
        $(dtButtonClass).removeClass('active');
        overlayTimer.disable();
        
        $('#touchscreen-ui-overlay').addClass('hidden');
        
        if(this.config.onHide && typeof this.config.onHide === 'function') {
            this.config.onHide();
        }
        
        currentVideo.play();
    },
    onFailure: function() {
        dev.log('Touchscreen UI: failure during ' + this.mode);
        
        var _this = this;
//        switch(this.mode) {
//            case 'normal':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[0].message,
//                    audio: defaultOverlayFails[0].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'retry';
//                        _this.controlsEnabled = true;
//                        _this.resetTimer();
//                        _this.setTimer();
//                    }
//                });
//                break;
//            case 'retry':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[1].message,
//                    audio: defaultOverlayFails[1].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'hint';
//                        _this.showHints();
//                        _this.controlsEnabled = true;
//                        _this.resetTimer();
//                        _this.setTimer();
//                    }
//                });
//                break;
//            case 'hint':
                this.controlsEnabled = false;
                cueManager._emitImpede({
                    type: 'custom',
                    customType: 'tutorial',
                    message: defaultOverlayFails[2].message,
                    audio: defaultOverlayFails[2].audio,
                    buttonText: 'Play',
                    onHide: function() {
                        _this.reset();
                        _this.mode = 'tutorial';
                        _this.startTutorial();
                    }
                });
//                break;
//        }
    },
    onSuccess: function() {
        dev.log('Touchscreen UI: success during ' + this.mode);
        
        this.controlsEnabled = false;
        
        overlayTimer.disable();
        
        if(this.config.onSuccess && typeof this.config.onSuccess === 'function') {
            this.config.onSuccess();
        }
        
        var _this = this;
        $('#touchscreen-ui-overlay .overlay__button').removeClass('disabled').click(function() {
            _this.onClose();
        });
    },
    scored: function(elem) {
        if(this.mode === 'normal') {
            switch(overlayTimer.level) {
                case 1:
                    this.addPoints(defaultPoints.max, $(elem));
                    break;
                case 2:
                    this.addPoints(defaultPoints.retry, $(elem));
                    break;
                case 3:
                    this.addPoints(defaultPoints.min, $(elem));
                    break;
            }
        }
        
//        switch (this.mode) {
//            case 'init':
//            case 'tutorial':
//                break;
//            case 'normal':
//                this.addPoints(defaultPoints.max, $(elem));
//                break;
//            case 'retry':
//                this.addPoints(defaultPoints.retry, $(elem));
//                break;
//            case 'hint':
//                this.addPoints(defaultPoints.min, $(elem));
//                break;
//        }
    },
    addPoints: function(points, target) {
        var targetOffset = target.offset();
        var pointLocation = [targetOffset.left + target.width() / 2, targetOffset.top];
        
        addPoints(points, pointLocation);
    }
}.initSubObjects();
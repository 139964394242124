/*global $ */
var shifterControl = {
    init: function(action) {
        this.controlsEnabled = false;
        this.config = action.init;
        this.action = action;
        this.correctIndex = parseInt($('[data-shifter-letter="' + action.init.correctLetter + '"]').attr('data-shifter-index'));
        this.startIndex = (typeof this.currentIndex === 'number' && this.currentIndex > -1 && this.currentIndex < 4) ? this.currentIndex : 0;
//        this.setByIndex(this.startIndex, false);
        
        var _this = this;
        
        this.clickTimeouts = [null,null,null,null];
        
        var shifterButtons = $('.shifter-control__button');
        for(var i = 0; i < shifterButtons.length; i++) {
            $(shifterButtons[i]).on('mousedown touchstart', _this.click.bind(_this));
        }
    },
    open: function(action) {
        currentVideo.pause();
        
        this.init(action);

        currentImpediment = action;
        mainControlsEnabled = false;
        $(dtButtonClass).removeClass('active');

        var shifterOverlay = $('#shifter-overlay');

        shifterOverlay.attr('data-overlay-type', action.type);
    //    informationOverlay.find('.overlay__message').html(action.message);
    //    informationOverlay.find('.overlay__title').text(action.header ? action.header : '');
        shifterOverlay.find('.overlay__button').text('Continue').off('click').addClass('disabled');
        shifterOverlay.removeClass('hidden');
        
        this.mode = 'normal';
        this.controlsEnabled = true;
        this.isActive = true;
        overlayTimer.init({
            time: this.config.time,
            levels: true,
            onComplete: this.onFailure.bind(this)
        });
        overlayTimer.start();
    },
    onInput: function(inputName, isReal) {
//        if((isReal && this.controlsEnabled) || (!isReal && this.mode == 'tutorial')) {
//            dev.log('Shifter Control input: ' + inputName);
//            switch (inputName) {
//                case 'arrowleft':
//                    this.setByIndex(this.currentIndex - 1, true);
//                    break;
//                case 'arrowright':
//                    this.setByIndex(this.currentIndex + 1, true);
//                    break;
//            }
//        }
    },
    click: function(e) {
        var $target = $(e.target);
        var _this = this;
        if(this.controlsEnabled || this.mode == 'tutorial') {
            var nextIndex = parseInt($target.attr('data-shifter-index'));
            this.animateTo(nextIndex);
            this.setByIndex(nextIndex, true);
            $target.addClass('active');
            if(this.clickTimeouts[nextIndex]) clearTimeout(this.clickTimeouts[nextIndex]);
            this.clickTimeouts[nextIndex] = setTimeout(function() {
                $target.removeClass('active');
            }, 400);
        }
    },
    reset: function() {
        this.mode = 'init';
        this.setByIndex(this.startIndex, false);
        this.clickTimeouts = [null,null,null,null];
        $(dtButtonClass).removeClass('active selected');
    },
    set: function(letter, isFinal) {
        dev.log('Shifter Control: set ' + letter);
        
        var nextIndex = parseInt($('[data-shifter-letter="' + letter + '"]').attr('data-shifter-index'));
                
        this.currentIndex = nextIndex;
        
        if(isFinal) {
            if(this.currentIndex === this.correctIndex) {
                switch(this.mode) {
                    case 'normal':
                        this.addPoints($('[data-shifter-letter="' + letter + '"]'));
                        break;
                    case 'retry':
                        this.addPoints($('[data-shifter-letter="' + letter + '"]'));
                        break;
                    case 'hint':
                        this.addPoints($('[data-shifter-letter="' + letter + '"]'));
                        break;
                }
                this.onSuccess();
            }
        } else {
            this.highlightLetter(letter);
        }
    },
    setByIndex: function(ind, isFinal) {
        dev.log('Shifter Control: set by index ' + ind);
        if(ind != this.currentIndex && ind < 4 && ind > -1) {
            this.set(this.getLetter(ind), isFinal);
        }
    },
    getLetter: function(ind) {
        var shifterButton = $('[data-shifter-index="' + ind + '"]');
        return shifterButton.attr('data-shifter-letter');
    },
    highlightLetter: function(letter) {
        dev.log('Shifter Control: Highlighting letter ' + letter);
        $('#shifter-control-bg').css('background-image', 'url(\'images/shifter-' + letter + '.jpg\')');
    },
    showHints: function() {
        $('[data-shifter-index="' + this.correctIndex + '"]').addClass('hint');
    },
    animateTo: function(toIndex) {
        if(this.shiftInterval) clearInterval(this.shiftInterval);
        
        var _this = this;
        var animIndex = this.currentIndex;
        
        if(toIndex != animIndex && toIndex < 4 && toIndex > -1) {
            if(toIndex < animIndex) {
                $('#shifter-spin').removeClass('spin left').addClass('spin left');
            } else if(toIndex > animIndex) {
                $('#shifter-spin').removeClass('spin left').addClass('spin');
            }
            
            _this.shiftInterval = setInterval(function() {
                dev.log('Shifter Control: animateTo: interval, current ' + animIndex + ' to ' + toIndex);
                if(toIndex != animIndex && toIndex < 4 && toIndex > -1) {
                    if(toIndex < animIndex) {
//                        _this.setByIndex(_this.currentIndex - 1, ind == _this.currentIndex - 1);
                        animIndex--;
                        _this.highlightLetter(_this.getLetter(animIndex));
                        playAudio('audio/shifter.mp3', 2);
                    } else if(toIndex > animIndex) {
//                        _this.setByIndex(_this.currentIndex + 1, ind == _this.currentIndex + 1);
                        animIndex++;
                        _this.highlightLetter(_this.getLetter(animIndex));
                        playAudio('audio/shifter.mp3', 2);
                    } else {
                        clearInterval(_this.shiftInterval);
                    }
                } else {
                    clearInterval(_this.shiftInterval);
                }
            }, 200);
        }
    },
    startTutorial: function() {
        var _this = this;
        setTimeout(function() {
            _this.click({target: $('[data-shifter-index="' + _this.correctIndex + '"]')[0]});
        }, 500);
    },
    onClose: function() {
        var shifterButtons = $('.shifter-control__button');
        for(var i = 0; i < shifterButtons.length; i++) {
            $(shifterButtons[i]).removeClass('hint').off('mousedown touchstart');
        }
        
        $('#shifter-spin').removeClass('spin left');
        
        this.controlsEnabled = false;
        this.isActive = false;
        
        currentImpediment = {};
        controlsEnabled = true;
        mainControlsEnabled = true;
        $(dtButtonClass).removeClass('active');
        overlayTimer.disable();
        
        $('#shifter-overlay').addClass('hidden');
        
        if(this.action.onHide && typeof this.action.onHide === 'function') {
            this.action.onHide();
        }
        
        currentVideo.play();
    },
    onFailure: function() {
        dev.log('Shifter Control: failure during ' + this.mode);
        
        var _this = this;
//        switch(this.mode) {
//            case 'normal':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[0].message,
//                    audio: defaultOverlayFails[0].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'retry';
//                        _this.controlsEnabled = true;
//                        _this.resetTimer();
//                        _this.setTimer();
//                    }
//                });
//                break;
//            case 'retry':
//                this.controlsEnabled = false;
//                cueManager._emitImpede({
//                    type: 'custom',
//                    customType: 'retry',
//                    message: defaultOverlayFails[1].message,
//                    audio: defaultOverlayFails[1].audio,
//                    buttonText: 'Try Again',
//                    onHide: function() {
//                        _this.reset();
//                        _this.mode = 'hint';
//                        _this.showHints();
//                        _this.controlsEnabled = true;
//                        _this.resetTimer();
//                        _this.setTimer();
//                    }
//                });
//                break;
//            case 'hint':
                this.controlsEnabled = false;
                var shifterButtons = $('.shifter-control__button');
                for(var i = 0; i < shifterButtons.length; i++) {
                    $(shifterButtons[i]).removeClass('hint').off('mousedown touchstart');
                }
                cueManager._emitImpede({
                    type: 'custom',
                    customType: 'tutorial',
                    message: defaultOverlayFails[2].message,
                    audio: defaultOverlayFails[2].audio,
                    buttonText: 'Play',
                    onHide: function() {
                        _this.reset();
                        _this.mode = 'tutorial';
                        _this.startTutorial();
                    }
                });
//                break;
//        }
    },
    onSuccess: function() {
        dev.log('Shifter Control: success during ' + this.mode);
        
        this.controlsEnabled = false;
        
        overlayTimer.disable();
        
        var shifterButtons = $('.shifter-control__button');
        for(var i = 0; i < shifterButtons.length; i++) {
            $(shifterButtons[i]).removeClass('hint').off('mousedown touchstart');
        }
        
        if(this.action.onSuccess && typeof this.action.onSuccess === 'function') {
            this.action.onSuccess();
        }
        
        var _this = this;
        $('#shifter-overlay .overlay__button').removeClass('disabled').click(function() {
            _this.onClose();
        });
    },
    addPoints: function(target) {
        if(this.mode === 'normal') {
            var targetOffset = target.offset();
            var pointLocation = [targetOffset.left + target.width() / 2, targetOffset.top];
            var points;
            
            switch(overlayTimer.level) {
                case 1:
                    points = defaultPoints.max;
                    break;
                case 2:
                    points = defaultPoints.retry;
                    break;
                case 3:
                    points = defaultPoints.min;
                    break;
            }
            
            addPoints(points, pointLocation);
        }
    }
};